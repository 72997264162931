import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UserContext } from '../components/UserProvider';


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
  },
  pageHeader: {
    display: 'flex',
    marginTop: '80px',
    marginBottom: '20px',
    justifyContent: 'space-between'
  },
  paathCardContainer: {
    marginBottom: theme.spacing(9),
  },
  participantList:{
    padding: theme.spacing(1),
  },
  participant:{
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  paathReader:{
      marginBottom: theme.spacing(2),
  },
  reader:{
    padding: 0,
  },
  actionButtonContainer:{
    marginLeft: theme.spacing(7),
  },
  actionButtons: {
    marginBottom: theme.spacing(4),
  },
  passButton: {
    width: '45%'
  },
  passButtonAdmin: {
    marginRight: 5,
    //width: '49%',
    backgroundColor: theme.palette.info.main,
  },
  readButton: {
    width: '45%',
    marginRight: 5,
  },
}));



const PaathReaderCurrent = ({ displayName, userName, userId, createdBy, confirmedParticipants, declinedParticipants, progress, status, passPaath, passPaathAdmin, readPaath }) => {

    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext);
    const [currentUserId, setCurrentUserId] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(user){
            setCurrentUserId(user.uid);
        }
    })

    const participantConfirmed = () => {
        if(confirmedParticipants.includes(userId)){
            return true;
        }
        else return false;
    }

    const participantDeclined = () => {
        if(declinedParticipants.includes(userId)){
            return true;
        }
        else return false;
    }

   const renderReadButtons = (progress, status) => {

        // Returns nothing if there is no one reading for the paath
        // and the paath has not yet started
        if(progress.currentReader === ""){
            return "";
        }
        // Renders the read of pass buttons if the current logged in user is also the one
        // that is supposed to be currently reading for the sehaj paath
        else if(progress.currentReader === currentUserId && userId === currentUserId && status === "started" && participantConfirmed()){
            return(
                <div className={classes.actionButtons}>
                    <Button size="small" className={classes.readButton}  color="secondary" variant="contained" onClick={readPaath}> Read </Button>
                    <Button size="small" className={classes.passButton} color="info" variant="contained" onClick={passPaath} endIcon={<ArrowForwardIcon/>}> Pass </Button>
                </div>
            )
        }
        else if(progress.currentReader === currentUserId && userId === currentUserId && status === "paused" && participantConfirmed()){
            return(
                <div className={classes.actionButtons}>
                    <Button size="small" className={classes.passButton} color="primary" variant="outlined" disabled> Waiting For Paath Restart </Button>
                </div>
            )
        }
        //Returns a simple reading message to let other users know who is currently reading
        // For the Sehaj paath.
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy !== currentUserId && progress.readingStatus === "readingStarted" && status === "started" && participantConfirmed()){
            return(
                "Reading"
            )
        }
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy !== currentUserId && progress.readingStatus === "toStartRead" && status === "started" && participantConfirmed()){
            return(
                "Waiting for Reader to Start"
            )
        }
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy !== currentUserId && progress.readingStatus === "toStartRead" && status === "paused" && participantConfirmed()){
            return(
                "Waiting for Paath to Restart"
            )
        }
        // Lets an Admin Pass the Paath to the next reader incase the current reader is not reading.
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy === currentUserId && progress.readingStatus === "readingStarted" && status === "started" && participantConfirmed()){
            return(
                <div className={classes.actionButtons}>
                    <p>Reading</p>
                    <Button size="small" className={classes.passButtonAdmin} variant="outlined" onClick={passPaathAdmin} endIcon={<ArrowForwardIcon/>}> Pass To Next Reader </Button>
                </div>
            )
        }
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy === currentUserId && progress.readingStatus === "toStartRead" && status === "started" && participantConfirmed()){
            return(
                <div className={classes.actionButtons}>
                    <p>Waiting for Reader to Start</p>
                    <Button size="small" className={classes.passButtonAdmin} variant="outlined" onClick={passPaathAdmin} endIcon={<ArrowForwardIcon/>}> Pass To Next Reader</Button>
                </div>
            )
        }
        else if(progress.currentReader !== currentUserId && userId === progress.currentReader && createdBy === currentUserId && progress.readingStatus === "toStartRead" && status === "paused" && participantConfirmed()){
            return(
                <div className={classes.actionButtons}>
                    <p>Waiting for Paath to Restart</p>
                    <Button size="small" className={classes.passButtonAdmin} variant="outlined" onClick={passPaathAdmin} endIcon={<ArrowForwardIcon/>}> Pass To Next Reader </Button>
            </div>
            )
        }
    }

    const renderPaathReader = () => {
        if(participantConfirmed() && !participantDeclined()){
            return(
                <div className={classes.paathReader}>
                    <ListItem className={classes.reader}>
                        <ListItemAvatar>
                        <Avatar>
                            {/* Add user profile picture*/}
                        </Avatar>
                        </ListItemAvatar>
                       
                        <ListItemText primary={displayName} secondary={'@'+userName}/>
                        
                    </ListItem> 
                
                    <div className={classes.actionButtonContainer}>
                        {renderReadButtons(progress, status)}
                    </div>
                </div>
            )
        }
        else if(!participantDeclined() && !participantConfirmed()){
            return(
                <div className={classes.paathReader}>
                    <ListItem className={classes.reader}>
                        <ListItemAvatar>
                        <Avatar>
                            {/* Add user profile picture*/}
                        </Avatar>
                        </ListItemAvatar>
                      
                        <ListItemText primary={displayName} secondary={'@'+userName}/>
                        
                    </ListItem> 
                
                    <div className={classes.actionButtonContainer}>
                        Invite Pending
                    </div>
                </div>
            );
        }
        else if(participantDeclined() && !participantConfirmed()){
            return "";
        }
    }

    return(

        <div>
       
        { renderPaathReader() }

       </div>

    )

}

export default PaathReaderCurrent;