import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import firebase from "firebase/app";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CreateGroupForm from '../components/CreateGroupForm';
import GroupIcon from '@mui/icons-material/Group';
import MainHomeHeader from '../images/main-home-header.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
    paddingBottom: theme.spacing(8),
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  mainHeaderImage:{
    height: 100,
    width: 'auto',
  },
  forms: {
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  subTitle:{
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.dark,
  },
  listItem: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    fontSize: '17px',
    lineHeight: 1.5,
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReadingGuidelines = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext)
    const [userId, setUserId] = useState("");
    const [savedGroups, setSavedGroups] = useState([]);
    const [createGroupView, setCreateGroupView] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
     
      window.scrollTo(0,0);

    }, [])


    return(
        <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h5" component="h1"> Reading Guidlines </Typography>
                </div>
            <Layout>
            
                <Card className={classes.forms} variant="outlined">
                  <CardMedia className={classes.mainHeaderImage} image={MainHomeHeader} alt=""/>
                  <CardContent>
                      <Typography className={classes.subTitle} gutterBottom variant="h5" component="h3"> Personal Preperation </Typography>
                          <ul className={classes.readingList}>
                              <li className={classes.listItem}>
                                  Consciously understand that you are about to read teachings, writings, poetry, and ode’s written 
                                  by 36 authors that include 6 of the Sikh Gurus. The teachings speak to our interaction with daily life, 
                                  conduct, stories, relationship to the Creator and much more. 
                              </li>
                              <li className={classes.listItem}>
                                  Connect with the themes and messages to reflect on how they connect with our own daily life and where 
                                  we can apply them. This is the true purpose of the teachings, to be applied in our daily life. 
                              </li>
                              <li className={classes.listItem}>
                                  Commit to take something away for reflection, personal discussion, and further exploration. 
                              </li>
                          </ul>
                      <Typography className={classes.subTitle} gutterBottom variant="h5" component="h3"> Physical Preperation </Typography>
                          <ul>
                              <li className={classes.listItem}>
                                  Find a quiet place where you will not be distracted or disturbed so that you can receive the gift of 
                                  the teachings and connect deeply with the reading. 
                              </li>
                              <li className={classes.listItem}>
                                  Please cover your head prior to reading Guru’s Bani. This is more for our own preparation and engaging in the 
                                  reading of Bani with reverence and devotion. 
                              </li>
                          </ul>
                  </CardContent>     
                </Card>
                       
            </Layout>
        </div>
    )
}

export default ReadingGuidelines;