import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import UserProvider, { UserContext } from '../components/UserProvider';
import { firestore } from '../config/firebase-config';
import { getDividerUtilityClass } from '@mui/material';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  text: {
      color: theme.palette.primary.darkText,
  },
  noPaathText: {
    fontSize: '20px',
    color: theme.palette.primary.darkText,
  },
  paathTileContainer:{
    display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#F1F5F5',
      width: '100%',
      borderRadius: '10px',
      marginTop: 5,
      alignItems: 'stretch',
  },
  paathTitle: {
    lineHeight: 1.2,
  },
  paathTile: {
      padding: theme.spacing(1),
      backgroundColor: '#F1F5F5',
  },
  paathTilePercent: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1),
      borderRadius: '0px 10px 10px 0px',
      minWidth: '50px',
      height: 'auto',
  },
  percentText: {
    color: theme.palette.secondary.dark,
    alignSelf: 'center',
  }
}));

const PaathListHome = () => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {user} = useContext(UserContext);
    const [currentUser, setCurrentUser] = useState({});
    const [paathList, setPaathList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if(user){
            setCurrentUser(user);
            fetchUserPaaths();
        }


    }, [user])

    const fetchUserPaaths = async () => {
        const fetchedPaath = []; 
        if(user?.paaths?.length > 0){
            await user.paaths.forEach((id) =>
                 firestore.collection("paaths").doc(id).get().then((doc) => {
                    if(doc.exists){
                        setLoading(true);
                        const paath = doc.data();
                        paath.id = doc.id;
                        fetchedPaath.unshift(paath)
                        //console.log(fetchedPaath);
                        setPaathList(...paathList, fetchedPaath);
                        setLoading(false);
                    }
            }));
        }
    }

    return(

        <div className={classes.root}>
            {!loading && currentUser?.paaths?.length > 0 ? 
                // TO DO: Create list component showing names of paaths
                <Typography variant="h6" color="textSecondary" component="p">
                    
                </Typography>
                : 
                <Typography variant="body1" color="textSecondary" component="p" className={classes.noPaathText}>
                    You currently don't have any ongoing paaths.
                </Typography>
            }

            {loading && currentUser?.paaths?.length > 0 ? 
            <div> 
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={45} />
            </div> : "" 
            }

            {paathList?.length > 0 ? paathList.map((paath, index) => {
                let progressPercent = (paath.progress.currentPage/1430)*100;
                return(
                    <div key={paath.paathTitle} className={classes.paathTileContainer}>
                        <Paper key={paath.paathTitle} elevation={0} className={classes.paathTile}>
                            <Typography className={classes.paathTitle}>
                                {paath.paathTitle}
                            </Typography>
                        </Paper>
                        <div className={classes.paathTilePercent}>
                            <Typography variant="subtitle1" component="p" className={classes.percentText}>
                                {Math.round(progressPercent) + '%'}
                            </Typography>
                        </div>
                    </div>
                )
            })  
            : 
            ""
            } 
        </div>
    )
}

export default PaathListHome;