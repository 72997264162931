import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import { UserContext } from '../components/UserProvider';
import makeStyles from '@mui/styles/makeStyles';
import StylesProvider from '@mui/styles/StylesProvider';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import gql from 'graphql-tag';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LinearProgress from '@mui/material/LinearProgress';
import { BookmarkBorderOutlined, TurnedInNotRounded } from '@mui/icons-material';
import { firestore } from '../config/firebase-config';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
    loaderContainer:{
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    paathContainer: {
      marginTop: '70px',
      marginBottom: '130px',
    },
    paginationContainer: {
      background: '#f2f2f2',
      position: 'fixed',
      bottom: 70,
      right: 0,
      padding: 5,
      maxWidth: '100%',
    },
    card: {
      minWidth: '275',
      marginBottom: '5px',
      backgroundColor: theme.palette.primary.backgroundCard,
      borderRadius: '10px',
    },
    title: {
      fontSize: 14,
    },
    text: {
      color: theme.palette.primary.main,
    },
    verseNumber: {
      textAlign: 'right',
    },
    pos: {
      marginBottom: 12,
    },
  }));

  const GET_PAATH = gql `
    query($pageNo: Int) {
      PaathPageSearchQuery(input: $pageNo) {
        _id
        count
        source{
          pageNo
        }
        navigation{
          previous
          next
        }
        page{
          lineNo
          verseId
          verse{
            unicode
          }
          translation{
            en{
              bdb
            }
          }
          transliteration{
            english
          }
        }
      }
    }
`

const PaathVerseDisplayHome = () => {

    const classes = useStyles();
    const [lineBookMarked, setLineBookMarked] = useState(false);
    const [page, setPage] = useState(format(new Date(), "d"));
    const [verse, setVerse] = useState({});
    const [loading, setLoading] = useState(false);
    //const [randomPage, setRandomPage] = useState(Math.round((((new Date().valueOf())/10000000000000) * 1430) + 1));
    const [serverPage, setServerPage] = useState("");
    format(new Date(), "eee, MMM dd yyyy")

    //const { loading, error, data } = useQuery(GET_PAATH, {variables:{pageNo: randomPage}});

    useEffect(() => {
      setLoading(true);
      firestore.collection("dailyVerse").doc(page).get().then((doc) => {
        if (doc.exists){
          setVerse(doc.data());
          setLoading(false);
        }
      }).catch((error) => {
        console.log(error);
      });

        // if(data){
        //     setPage(data.PaathPageSearchQuery[0].page);
        //     setServerPage(data.PaathPageSearchQuery[0].source.pageNo);
        //     //console.log(new Date().valueOf() / 10000000000000);
        //   }

    }, []);

    // const renderVerse = () => {

    //   let verse1;
    //   let randomVerse;

    //   if(page?.length === 0){
    //     return;
    //   }
    //   else{
    //     const pageLength = page?.length;
    //     randomVerse = Math.round((((new Date().valueOf())/10000000000000) * pageLength) + 1);
    //     verse1 = page[randomVerse];
        
    //       return(
    //         <div>
    //           <Divider className={classes.divider}>
    //               <Chip label={`Page: ${serverPage} Verse: ${verse1?.verseId}  Line: ${verse1?.lineNo}`} />
    //             </Divider>
    //           <CardContent>
    //             <Typography variant="h6" component="h2" className={classes.text}>
    //                 {verse1?.verse?.unicode}
    //             </Typography>
    //             <Typography className={classes.pos} color="textSecondary">
    //                 {verse1?.transliteration?.english}
    //             </Typography>
    //             <Typography variant="body2" component="p" gutterBottom>
    //                 {verse1?.translation?.en?.bdb}
    //             </Typography>
    //         </CardContent>
    //       </div>
    //     )
    //   }
    // }

    const renderVerse = () => {
      return(
          <div>
              <Divider className={classes.divider}>
                  <Chip label={`${verse?.verseDetails}`} size="small"/>
                </Divider>
              <CardContent>
                <Typography variant="h6" component="h2" className={classes.text}>
                    {verse?.unicode}
                </Typography>
                <Typography className={classes.pos}>
                    {verse?.transliteration}
                </Typography>
                <Typography variant="body2" component="p" gutterBottom>
                    {verse?.translation}
                </Typography>
            </CardContent>
            <div>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                    <Typography>Verse Explanation</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {verse?.explanation}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
            </div>
          </div>
      )
    }

    return (

        <Card className={classes.card} variant="paper">
            {loading ? 
                <div className={classes.loaderContainer}> 
                <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
                </div> 
                : 
                renderVerse()
            }
        </Card>
    );

}

export default PaathVerseDisplayHome;