import React, { useContext, createContext, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { UserContext } from '../components/UserProvider';
import { auth } from '../config/firebase-config';

const userLoggedIn = async () => {
    await auth.currentUser;
}


const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={props => (
        
        userLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{
          pathname: '/',
          state: { from: props.location }
          }}
        />
      )
    )} />
  );
  
export default PrivateRoute;