import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore } from '../config/firebase-config';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UserProvider, { UserContext } from '../components/UserProvider';
import { PaathInviteContext } from '../components/PaathInviteProvider';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  paathInviteBox:{
    display: 'flex', 
    backgroundColor: theme.palette.info.main, 
    borderRadius: '10px', 
    alignContent: 'center', 
    justifyContent: 'space-between', 
    marginTop: '20px', 
    maxWidth: '100%', 
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  paathInviteMessage:{
    fontSize: '18px',
    color: theme.palette.info.dark,
  },
  acceptButton:{
    backgroundColor: theme.palette.secondary.main,
    text: '#ffffff',
  }
}));

const PaathInvite = () => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {user, setUser} = useContext(UserContext);
    const {paathInviteIdContext, setPaathInviteIdContext} = useContext(PaathInviteContext);
    const [currentUser, setCurrentUser] = useState({});
    const [paathList, setPaathList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [paathAdded, setPaathAdded] = useState(false);

    useEffect(() => {

        window.scrollTo(0,0);

        if(user){
            setCurrentUser(user);
        }

    }, [user])

    const acceptPaathInvite = () => {
        setLoading(true);
        addPaathToUserProfile(paathInviteIdContext);
        addUserToPaath(paathInviteIdContext);
    }

     // Add's paath to user's profile
     const addPaathToUserProfile = (id) => {
        const currentPaaths = currentUser.paaths;
        const userDocRef = firestore.collection("users").doc(currentUser.uid);
        // Get list of paaths in users collection and if they try to add
        // the same one again it tells them they are already part of that paath.
        if(currentPaaths.includes(id)){
            alert("You're already a part of this Paath. Please go to your Paaths page.");
            setPaathAdded(true);
            setLoading(false);
            setPaathInviteIdContext("");
        }
        else{
            userDocRef.update({
                paaths: [...currentPaaths, id]
            }).then(() => { 
            return userDocRef.get(); 
            }).then((doc) => {
                let uid = doc.id;
                let userData = doc.data();
                let updatedUser = {uid, ...userData};
                setUser(updatedUser);
            })
            .catch((error) => {
                setError("Error adding paath: ", error);
            });
        }
    }

     const addUserToPaath = (id) => {
        const paathId = id;
        const participant = {displayName: currentUser.displayName, email: currentUser.email, id: currentUser.userName, userId: currentUser.uid}
         firestore.collection("paaths").doc(id).update({
             participants: firebase.firestore.FieldValue.arrayUnion(participant),
             confirmedParticipants:  firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
         }).then(() => {
             setPaathAdded(true);
             setLoading(false);
             setPaathInviteIdContext("");
             setTimeout(() => history.push(`/paaths/${paathId}`), 1000);
         }).catch((error) => {
             setPaathAdded(false);
             setLoading(false);
             console.error("Error writing document: ", error);
             setError("Error adding paath: ", error);
         });
     }

    return(
        <div className={classes.root}>
            <Box className={classes.paathInviteBox}>
                <p className={classes.paathInviteMessage}>You Have A Paath Invite</p>
                <Button variant="contained" className={classes.acceptButton} size="small" onClick={acceptPaathInvite}>Accept</Button>
            </Box>
        </div>
    )
}

export default PaathInvite;