import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import { PaathInviteContext } from '../components/PaathInviteProvider';
import { auth, signInWithGoogle, generateUserDocument, firestore } from '../config/firebase-config';
import * as Realm from 'realm-web';
import { useRealmApp } from '../config/RealmApp';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../images/msplogo.png';


const useStyles = makeStyles((theme) => ({
    container: {
      height: '80vh',
      marginTop: '20vh',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    detailsCard: {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        //padding: theme.spacing(1),
        backgroundColor: '#ffffff',
        borderRadius: '10px',
    },
    logo:{
      width: 120,
      height: 'auto',
      marginBottom: theme.spacing(4),
      justifyContent: 'center',
    },
    welcomeMessage: {
      textAlign: 'center',
      color: theme.palette.primary.main,
    },
    welcomeMessage2: {
        textAlign: 'center',
        fontSize: '18px',
        marginTop: theme.spacing(2),
        color: theme.palette.primary.main,
      },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    signUpButton: {
      marginTop: 20,
    },
    actionButtons: {
        marginTop: theme.spacing(3),
    },
    declineButton: {
        marginTop: theme.spacing(5),
    }
  }));

const Invite = () => {

    const app = useRealmApp();
    const classes = useStyles();
    const history = useHistory();
    const {paathInviteIdContext, setPaathInviteIdContext} = useContext(PaathInviteContext);
    //const [user, userLoading, userError] = useAuthState(auth);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {paathInviteId} = useParams();
    const [accepting, setAccepting] = useState(false);
   

    const acceptInvite = () => {
        setAccepting(true);
        setPaathInviteIdContext(paathInviteId);
        setTimeout(() => history.push('/'), 1000);
    }


    return(

      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
       
            <div className={classes.paper}>
                <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>
                <Typography component="h1" variant="h5" gutterBottom className={classes.welcomeMessage}>
                    You've Been Invited to Join <br/> A Sehaj Paath
                </Typography>
                <Typography component="p" gutterBottom className={classes.welcomeMessage2}>
                    Welcome To The My Sehaj Paath Family.
                </Typography>
                <div className={classes.actionButtons}>
                    <LoadingButton variant="contained" fullWidth onClick={acceptInvite} loading={accepting}>Accept Invite</LoadingButton>
                </div>
            </div>
        
        </Container>
    
    )
}

export default Invite;