import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import firebase from "firebase/app";
import { Capacitor } from '@capacitor/core';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CreateGroupForm from '../components/CreateGroupForm';
import GroupIcon from '@mui/icons-material/Group';
import SpaIcon from '@mui/icons-material/Spa';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MainHomeHeader from '../images/main-home-header.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
    paddingBottom: theme.spacing(8),
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  mainHeaderImage:{
    height: 100,
    width: 'auto',
  },
  forms: {
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  subTitle:{
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.dark,
  },
  listItem: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    fontSize: '17px',
    lineHeight: 1.5,
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AboutUs = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
     
      window.scrollTo(0,0);

    }, [])

   


    return(
        <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h5" component="h1"> About Us</Typography>
                </div>
            <Layout>
            
                <Card className={classes.forms} variant="outlined">
                  <CardMedia className={classes.mainHeaderImage} image={MainHomeHeader} alt=""/>
                  <CardContent>
                          <p className={classes.listItem}>
                          The Sri Guru Granth Sahib ji remains the source of having a deeper knowledge and understanding of how to move 
                          through our time that we have been given as a gift on earth, and to prepare us for our inevitable journey ahead.
                          </p>
                          <p className={classes.listItem}>
                          The team at MySehajPaath is dedicated and will continue to bring additional readings and updates, such as Sukhmani Sahib, that will also be read as a shared experience via this app. 
                          Your support and contributions towards the MySehajPaath ‘e-Golak’ will allow us to continue with these upgrades. 
                          </p>
                          <p className={classes.listItem}>
                          MySehajPaath will also send a portion of your contributions to local and international non-profit organizations engaged in humanitarian work.
                          Blessings to you and yours for your support towards bringing the ability to take part in the readings and teachings of our Guru with family and community, 
                          as it was meant to be. 
                          </p>
                  </CardContent>

                    <CardActions>
                      {Capacitor.getPlatform() === 'web' ? 
                          <Button fullWidth variant="contained" color="primary" startIcon={<SpaIcon/>} onClick={() => history.push('/e-golak/')}>Support Us Via Our E-Golak</Button>    
                        :
                        <Button variant="contained" color="primary" fullWidth endIcon={<OpenInNewIcon/>} onClick={() => window.open('https://www.mysehajpaath.com/', "_blank")}>Learn More</Button>  
                      }
                    </CardActions> 
                </Card>
                
                       
            </Layout>
        </div>
    )
}

export default AboutUs;