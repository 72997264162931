import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const VersionInfo = () => {

    return(
       
        <Typography color="textPrimary"> V 1.26 </Typography>
        
    )
}

export default VersionInfo;