import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore, auth } from '../config/firebase-config';
import firebase from "firebase/app";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
    paddingBottom: theme.spacing(8),
  },
  paathDetailsCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: '10px',
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  paathCardContainer: {
    marginBottom: theme.spacing(9),
  },
  forms: {
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  formControl: {
    minWidth: '100%',
    marginBottom: theme.spacing(3),
  },
  updateEmailButton:{
    marginLeft: theme.spacing(1),
  },
  deleteAccountButton:{
    marginLeft: theme.spacing(1),
  },
  notificationOptions:{
    marginTop: theme.spacing(2),
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Settings = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [provinceState, setProvinceState] = useState("");
    const [country, setCountry] = useState("");
    const [pushNotifications, setPushNotifications] = useState(false);
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [saving, setSaving] = useState(false);
    const [savingEmail, setSavingEmail] = useState(false);
    const [savingUsername, setSavingUsername] = useState(false);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [updateEmailView, setUpdateEmailView] = useState(false);
    const [updateUsernameView, setUpdateUsernameView] = useState(false);
    const [deleteView, setDeleteView] = useState(false);

    useEffect(() => {
     
      window.scrollTo(0,0);
      if(user){
        setUserId(user.uid);
        setDisplayName(user.displayName);
        setUserName(user.userName);
        setEmail(user.email);
        setPhone(user.phone);
        setCity(user.location.city);
        setProvinceState(user.location.provinceState);
        setCountry(user.location.country);
        setPushNotifications(user.preferences.pushNotification);
        setEmailNotifications(user.preferences.emailNotification);
      }

    }, [user])

    const handleChange = (event) => {
      if(event.target.name === 'display-name'){
        setDisplayName(event.target.value);
      }
      else if(event.target.name === 'user-name'){
          setUserName(event.target.value);
      }
      else if(event.target.name === 'email'){
        setEmail(event.target.value);
      }
      else if(event.target.name === 'phone'){
        setPhone(event.target.value);
      }
      else if(event.target.name === 'city'){
        setCity(event.target.value);
      }
      else if(event.target.name === 'province-state'){
        setProvinceState(event.target.value);
      }
      else if(event.target.name === 'country'){
        setCountry(event.target.value);
      }
      else if(event.target.name === 'push-notification'){
          setPushNotifications(event.target.checked);
      }
      else if(event.target.name === 'email-notification'){
        setEmailNotifications(event.target.checked);
      }
    }

    const handleEmailUpdateCancel = () => {
        setUpdateEmailView(false);
        setEmail(user.email);
    }

    const handleUsernameUpdateCancel = () => {
      setUpdateUsernameView(false);
      setUserName(user.userName);
  }

    // Updates User Email
    const updateUserEmail = () => {
      setSavingEmail(true);
      auth.currentUser.updateEmail(email).then(() => {
        setUpdateEmailView(false);
        setSavingEmail(false);
        setSaved(true);
        updateUserProfileOnEmailChange();
        updateUserNameDocumentOnEmailChange();
      }).catch((error) => {
        setSavingEmail(false);
        setError(true);
        setErrorMessage(error.message);
      });
    }

    //Updates Email in User Document
    const updateUserProfileOnEmailChange = () => {
      const userDocRef = firestore.collection("users").doc(userId);
      userDocRef.update({
        email: email,
       }).then(() => { 
        return userDocRef.get(); 
     }).then((doc) => {
         let uid = doc.id;
         let userData = doc.data();
         let updatedUser = {uid, ...userData};
         setUser(updatedUser);
     }).catch((error) => {
        setError(true);
        setErrorMessage(error.message);
     });
    }

     //Updates Email in Username Document
     const updateUserNameDocumentOnEmailChange = () => {
      const userNameDocRef = firestore.collection("usernames").doc(userName);
      userNameDocRef.update({
        email: email
      }).catch((error) => {
        setError(true);
        setErrorMessage(error.message);
      });
    }

    const updateUserProfile = () => {
      if(displayName === ""){
        alert("Please Enter Your Name");
      }
      else{
        setSaving(true);
        const userDocRef = firestore.collection("users").doc(userId);
        userDocRef.update({
          displayName: displayName,
          "location.city":  city,
          "location.country": country,
          "location.provinceState": provinceState,
          phone: phone,
          "preferences.emailNotification": emailNotifications,
          "preferences.pushNotification": pushNotifications,
        }).then(() => { 
          return userDocRef.get(); 
      }).then((doc) => {
          let uid = doc.id;
          let userData = doc.data();
          let updatedUser = {uid, ...userData};
          setUser(updatedUser);
          updateUserNameDocumentOnNameChange();
          setSaving(false);
          setSaved(true);
      }).catch((error) => {
          setError(true);
          setErrorMessage(error.message);
      });
      }
    }


    //Updates Display Name in Username Document
    const updateUserNameDocumentOnNameChange = () => {
      const userNameDocRef = firestore.collection("usernames").doc(userName);
      userNameDocRef.update({
        displayName: displayName
      }).catch((error) => {
          setError(true);
          setErrorMessage(error.message);
      });
    }

    //Deletes The Users Account
    const deleteAccount = () => {
      auth.currentUser.delete().then(() => {

      }).catch((error) => {
        setError(true);
        setErrorMessage(error.message);
      });
    }


    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSaved(false);
    };


    return(
        <div className={classes.root}>
          <div className={classes.pageHeader}>
              <Typography className={classes.title} variant="h5" component="h1"> Settings</Typography>
          </div>
            <Layout>

            <Card className={classes.forms} variant="outlined">
              <CardContent>
                  <Typography variant="h6">Username</Typography>
                  <TextField onChange={handleChange} disabled value={userName} variant="outlined" margin="normal" fullWidth id="user-name" label="Username" name="user-name" />
                </CardContent>
                <Divider/>
                <CardActions>
                  <Button variant="outline" disabled color="primary" className={classes.saveButton}>
                    Username cannot be changed
                  </Button>
              </CardActions>
            </Card>

            <Card className={classes.forms} variant="outlined">
              <CardContent>
                  <Typography variant="h6">Email</Typography>
                  {!updateEmailView ? 
                    <TextField onChange={handleChange} disabled value={email} variant="outlined" margin="normal" fullWidth id="email" label="Email" name="email" />
                    :
                    <TextField onChange={handleChange} value={email} variant="outlined" margin="normal" fullWidth id="email" label="Email" name="email" />
                  }
                </CardContent>
                <Divider/>
                <CardActions>
                  {!updateEmailView ? 
                    <Button onClick={() => setUpdateEmailView(true)} variant="contained" color="primary" className={classes.saveButton}>
                         Update Email
                    </Button>
                    :
                    <div>
                    <Button onClick={handleEmailUpdateCancel} variant="outlined" color="error" className={classes.saveButton}>
                        Cancel
                    </Button>
                    <LoadingButton onClick={updateUserEmail} loading={savingEmail} loadingIndicator="Saving..." variant="contained" className={classes.updateEmailButton}>
                        Save
                    </LoadingButton>
                    </div>
                  }

                </CardActions>
            </Card>
            
            <Card className={classes.forms} variant="outlined">
              <CardContent>
                  <Typography variant="h6">Personal Details</Typography>

                  <TextField onChange={handleChange} value={displayName} variant="outlined" margin="normal" fullWidth id="display-name" label="Name" name="display-name" />
                  <TextField onChange={handleChange} value={phone} variant="outlined" margin="normal" fullWidth id="phone" label="Phone" name="phone" />
                  <TextField onChange={handleChange} value={city} variant="outlined" margin="normal" fullWidth id="city" label="City" name="city" />
                  <TextField onChange={handleChange} value={provinceState} inputProps={{maxLength: 2}} variant="outlined" margin="normal" fullWidth id="province-state" label="Province/State" name="province-state" />
                  <TextField onChange={handleChange} value={country} variant="outlined" margin="normal" fullWidth id="country" label="Country" name="country" />

                  {/* Account Type */}
                  {/* <FormControl variant="outlined" margin="normal" className={classes.formControl}>
                      <InputLabel id="account-type-label">Account Type</InputLabel>
                      <Select labelId="account-type" id="account-type" name="account-type" value={""} onChange={handleChange} label="Account Type" >
                      <MenuItem value="">
                          <em>None</em>
                      </MenuItem>
                      <MenuItem value={'sehaj'}>Sehaj Paath</MenuItem>
                      </Select>
                  </FormControl> */}

                  <div className={classes.notificationOptions}>
                    <Typography variant="h6">Notification Preferences</Typography>
                    <FormControlLabel control={ <Switch checked={pushNotifications} onChange={handleChange} name="push-notification" color="primary" /> } label="Push Notifications"/>
                    <FormControlLabel control={ <Switch checked={emailNotifications} onChange={handleChange} name="email-notification" color="primary" /> } label="Email Notifications"/>
                  </div>
                </CardContent>
                <Divider/>
                <CardActions>
                    <LoadingButton onClick={updateUserProfile} loading={saving} loadingIndicator="Saving..." variant="contained" className={classes.saveButton}>
                         Save
                    </LoadingButton>
                </CardActions>
            </Card>

            <Card className={classes.forms} variant="outlined">
              <CardContent>
                  <Typography variant="h6" marginBottom={1}>Delete Your Account</Typography>
                  <Typography variant="p" color="light">
                    If you would like to delete your account, please hit the delete button below. Please note that this will permanently delete your account
                    and you will lose access to all of your ongoing paaths.
                  </Typography>
                </CardContent>
                <Divider/>
                <CardActions>
                {!deleteView ? 
                    <Button onClick={() => setDeleteView(true)} variant="contained" color="error">
                      Delete Account
                    </Button>
                  :
                  <div>
                    <Button onClick={() => setDeleteView(false)} variant="contained" color="info">
                      Cancel
                    </Button>
                    <Button onClick={deleteAccount} variant="contained" color="error" className={classes.deleteAccountButton}>
                      Confirm Delete
                    </Button>
                  </div>
                  }                 
                </CardActions>
             
               
            </Card>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={error} autoHideDuration={6000} onClose={handleSnackBarClose}>
                  <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>

              <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={saved}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClose}
                  action={
                    <React.Fragment>
                      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                  >
                  <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                    Saved!
                  </Alert>
              </Snackbar>

            </Layout>
        </div>
    )
}

export default Settings;