import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import RealmApolloProvider from './config/RealmApolloProvider';
import { useRealmApp, RealmAppProvider } from './config/RealmApp';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import UserProvider from './components/UserProvider';
import NotificationProvider from './components/NotificationProvider';
import PaathInviteProvider from './components/PaathInviteProvider';
import Signin from './pages/sign-in';
import SignUp from './pages/sign-up';
import AccountManagement from './pages/account-management';
import Welcome from './pages/welcome';
import Home from '../src/pages/home';
import ReadPaath from '../src/pages/read-paath';
import ReadPaathFreely from '../src/pages/read-paath-freely';
import Paaths from '../src/pages/paaths';
import PaathDetails from '../src/pages/paath-details';
import CreatePaath from '../src/pages/create-paath';
import Settings from '../src/pages/settings';
import Notifications from '../src/pages/notifications';
import SavedGroups from '../src/pages/saved-groups';
import ReadingGuidelines from './pages/reading-guidelines';
import AboutUs from './pages/about-us';
import EGolak from './pages/e-golak';
import Invite from './pages/invite';
import PrivateRoute from '../src/components/PrivateRoute';
import AppUrlListener from './components/AppUrlListener';




const theme = createTheme(adaptV4Theme({
  typography: {
    fontFamily:['minerva-modern', 'lato'].join(','),
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    button: {
      fontFamily: 'lato',
      fontWeight: 700,
    },
  },
  palette: {
    primary: {
      main: '#0D253F', //dark blue
      dark: '#5A8087', // darker teal blue
      light: '#97C6CF', //light teal blue
      background: '#FAFAFA', //light grey
      backgroundCard: '#FFFEFD', //lightest grey
      lightText: '#F7F9F8', //light grey
      darkText: '#303030', //dark gray
    },
    secondary: {
      main: '#89C09F', //light green
      dark: '#43604F', //dark green
    },
    info: {
      main: '#E9B24C', //mustard yellow
      dark: '#694F20', //dark brown
    },
    success: {
      main: '#4caf50',
    },
    error: {
      main: '#F44336',
    },
  },
}));

export const APP_ID = "mysehajpaath-mhsrw";
    
function App() {

  return (
    <div className="App">
      <RealmAppProvider appId={APP_ID}>
        <RealmApolloProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <UserProvider>
                <NotificationProvider>
                  <PaathInviteProvider>
                  <AppUrlListener></AppUrlListener>
                    <Route path="/" exact component={Signin}/>
                    <Route path="/sign-up/" exact component={SignUp}/>
                    <Route path="/account-management/" exact component={AccountManagement}/>
                    <Route path="/invite/:paathInviteId" exact component={Invite}/>
                    <PrivateRoute path="/welcome" exact component={Welcome}/>
                    <PrivateRoute path="/home" exact component={Home}/>
                    <PrivateRoute path="/paaths" exact component={Paaths}/>
                    <PrivateRoute path="/paaths/:id" exact component={PaathDetails}/>
                    <PrivateRoute path="/read-paath/:paathId/:page/:verse" exact component={ReadPaath}/>
                    <PrivateRoute path="/read-paath-freely" exact component={ReadPaathFreely}/>
                    <PrivateRoute path="/create-paath" exact component={CreatePaath}/>
                    <PrivateRoute path="/settings" exact component={Settings}/>
                    <PrivateRoute path="/notifications" exact component={Notifications}/>
                    <PrivateRoute path="/saved-groups" exact component={SavedGroups}/>
                    <PrivateRoute path="/reading-guidelines" exact component={ReadingGuidelines}/>
                    <PrivateRoute path="/about-us" exact component={AboutUs}/>
                    <PrivateRoute path="/e-golak" exact component={EGolak}/>
                  </PaathInviteProvider>
                </NotificationProvider>
              </UserProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </RealmApolloProvider>
      </RealmAppProvider>
    </div>
  );
}

export default App;
