import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import { UserContext } from '../components/UserProvider';
import { PaathInviteContext } from '../components/PaathInviteProvider';
import { auth } from '../config/firebase-config';
import * as Realm from 'realm-web';
import { useRealmApp } from '../config/RealmApp';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../images/msplogo.png';
import Chip from '@mui/material/Chip';
import VersionInfo from '../components/VersionInfo';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    logo:{
      width: '35%',
      height: 'auto',
      marginBottom: theme.spacing(2),
    },
    title:{
      textAlign: 'center',
    },
    authStatus:{
      marginTop: theme.spacing(1),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    signUpButton: {
      marginTop: 20,
    }
  }));

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const Signin = () => {

    const app = useRealmApp();
    const classes = useStyles();
    const history = useHistory();
    const {paathInviteId} = useParams();
    const user = useContext(UserContext);
    const {paathInviteIdContext} = useContext(PaathInviteContext);
    //const [user, userLoading, userError] = useAuthState(auth);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signingIn, setSigningIn] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [forgotPasswordView, setForgotPasswordView] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);
    const [resetEmailSuccess, setResetEmailSuccess] = useState(false);

    useEffect(() => {
      if(user?.user !== undefined && user?.user !== null){
        history.push('/home');
      }
    }, [user])
    
    const displayAuthStatus = (user) => {
      if(user?.user){
        return(
          <Chip className={classes.authStatus} label="We're Signing You In" color="success" variant="outlined" />
        )
      }
      else{
        return ""
      }
    }

    const signInWithEmailAndPasswordHandler = (event, email, password) => {
      event.preventDefault();
      setSigningIn(true);
      realmSignin();
      auth.signInWithEmailAndPassword(email, password).catch(error => {
        setSigningIn(false);
        setError(true);
        setErrorMessage("" + error);
      });
    };

    const sendPasswordResetEmail = (event, email) => {
      event.preventDefault();
      setSigningIn(true);
      auth.sendPasswordResetEmail(email)
      .then(() => {
        setResetEmailSent(true);
        setResetEmailSuccess(true);
        setSigningIn(false);
      })
      .catch(error => {
        setSigningIn(false);
        setError(true);
        setErrorMessage("" + error);
      });
    };

    // Removing google sign in as we are not using it
    // Will need to import and uncomment signInWithGoogle function
    //in firebase-config file if planning to re-use again.
    // const googleSignIn = () => {
    //   signInWithGoogle();
    // }

    const realmSignin = async () => {
      try {
         // TODO: Call the logIn() method with email credentials.
        await app.logIn(Realm.Credentials.anonymous());
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      }
    };

    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setError(false);
      setResetEmailSent(false);
    };

    const handleKeyAdd = (event) => {
      if (event.key === 'Enter' && email !== "" && password !== "") {
        signInWithEmailAndPasswordHandler(event, email, password);
      }
      else if(event.key === 'Enter' && email === ""){
          setError(true);
          setErrorMessage("Please enter email.");
      }
      else if(event.key === 'Enter' && password === ""){
          setError(true);
          setErrorMessage("Please enter password.");
      }
    }


    return(

      <Container component="main" maxWidth="xs">
        <CssBaseline />
          <div className={classes.paper}>
            <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>
          {!forgotPasswordView ?  
              <Typography component="h1" variant="h5" color="primary" className={classes.title}>
                  {paathInviteIdContext !== "" ? "Please Sign In To Accept The Paath Invite or Create An Account": "Sign In"}
              </Typography>
              :
              <Typography component="h1" variant="h5" color="primary">
                  Forgot Password
              </Typography>
            }
            {displayAuthStatus(user)}
            {!forgotPasswordView ?  
              <form className={classes.form} noValidate>
                  <TextField onChange={(event) => setEmail(event.target.value.trim())} onKeyDown={handleKeyAdd}  variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                  <TextField onChange={(event) => setPassword(event.target.value)} onKeyDown={handleKeyAdd} variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                  <Button onClick={(event) => {signInWithEmailAndPasswordHandler(event, email, password)}} fullWidth variant="contained" color="primary" className={classes.submit}>
                    {signingIn ? <CircularProgress size={24} color="secondary"/> : "Sign In"}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                        <Button onClick={() => setForgotPasswordView(true)}>
                        Forgot password?
                        </Button>
                    </Grid>
                  </Grid>
                  <Button className={classes.signUpButton} onClick={() => history.push('/sign-up')} fullWidth variant="outlined">
                      Create Account
                  </Button>
              </form>
              :
              <form className={classes.form} noValidate>
                 {!resetEmailSuccess ? 
                  <div>
                    <TextField onChange={(event) => setEmail(event.target.value)} onKeyDown={handleKeyAdd}  variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />
                      <Button onClick={(event) => {sendPasswordResetEmail(event, email)}} fullWidth variant="contained" color="primary" className={classes.submit}>
                        {signingIn ? <CircularProgress size={24} color="secondary"/> : "Send Password Reset Email"}
                      </Button>
                      <Button className={classes.signUpButton} onClick={() => setForgotPasswordView(false)} fullWidth variant="outlined" color="error">
                            Cancel
                      </Button>
                    </div> 
                  :
                  <div className={classes.title}> 
                    <Typography component="h1" variant="h6" color="secondary">
                      Password Reset Email Sent.
                    </Typography>
                    <Button className={classes.signUpButton} onClick={() => setForgotPasswordView(false)} fullWidth variant="outlined" color="primary">
                        Back To Sign In
                    </Button>
                  </div>
                  }
              </form>
              }
            </div>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={error} autoHideDuration={6000} onClose={handleSnackBarClose}>
                  <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>
                
              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={resetEmailSent}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                message={"Password Reset Email Sent."}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
            {/* VERSION INFO */}  
            <Box mt={5} sx={{textAlign: 'center'}}>
              <VersionInfo/>
            </Box>
        </Container>
    
    )
}

export default Signin;