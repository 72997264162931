import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import { UserContext } from '../components/UserProvider';
import { PaathInviteContext } from '../components/PaathInviteProvider';
import { firestore, auth } from '../config/firebase-config';
import * as Realm from 'realm-web';
import { useRealmApp } from '../config/RealmApp';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../images/msplogo.png';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    logo:{
      width: '35%',
      height: 'auto',
      marginBottom: theme.spacing(2),
    },
    title:{
      textAlign: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    signUpButton: {
      marginTop: 20,
    }
  }));

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
//https://my-sehaj-paath-2.firebaseapp.com/__/auth/action

function useQuery() {

  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);

}

const AccountManagement = () => {

    const classes = useStyles();
    const history = useHistory();
    let query = useQuery();
    const { user, setUser } = useContext(UserContext)
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [resettingPassword, setResettingPassword] = useState(false);
    const [recoveringEmail, setRecoveringEmail] = useState()
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordReset, setPasswordReset] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [emailRecoverySuccess, setEmailRecoverySuccess] = useState(false);
    const [mode, setMode] = useState(query.get("mode"));
    const [oobCode, setOobCode] = useState(query.get("oobCode"));
    const [codeExpired, setCodeExpired] = useState(false);


    useEffect(() => {
     
      verifyPasswordResetRequest(oobCode);

    }, [oobCode])

    
    const verifyPasswordResetRequest = (actionCode) => {
      auth.verifyPasswordResetCode(actionCode).then((email) => {
        const accountEmail = email;
        setUserEmail(accountEmail);
        }).catch((error) => {
        setError(true);
        setErrorMessage(error?.message);
        setCodeExpired(true);
      });
    }


    const handleResetPassword = (event) => {
      event.preventDefault();
      if(password.length < 8){
        setError(true);
        setErrorMessage("Password has to be 8 characters minimum");
      }
      else if(password !== confirmPassword){
        setError(true);
        setErrorMessage("Passwords don't match.");
      }
      else{
        let actionCode = oobCode;
        setResettingPassword(true);
        auth.confirmPasswordReset(actionCode, password).then((resp) => {
          setPasswordReset(true);
          setPasswordResetSuccess(true);
          setResettingPassword(false);
          
        }).catch((error) => {
          setError(true);
          setErrorMessage(error?.message);
        });
      }
    }


    const handleRecoverEmail = (event) => {
      event.preventDefault();
      setRecoveringEmail(true);
      let actionCode = oobCode;
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      let restoredEmail = null;
      // Confirm the action code is valid.
      auth.checkActionCode(actionCode).then((info) => {
        // Get the restored email address.
        restoredEmail = info['data']['email'];
        // Revert to the old email.
        return auth.applyActionCode(actionCode);
        }).then(() => {
        // Account email reverted to restoredEmail

        setEmailRecoverySuccess(true);

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        auth.sendPasswordResetEmail(restoredEmail).then(() => {
          // Password reset confirmation sent. Ask user to check their email.
        }).catch((error) => {
          setError(true);
          setErrorMessage(error?.message);
        });
      }).catch((error) => {
          setError(true);
          setErrorMessage(error?.message);
          setCodeExpired(true);
      });
    }
   

    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setError(false);
      setPasswordReset(false);
    };



    return(

      <Container component="main" maxWidth="xs">
        <CssBaseline />
          <div className={classes.paper}>
           
            <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>

              {mode === "resetPassword" ? 
                <div>
                  <form className={classes.form} noValidate>
                    <Typography component="h1" variant="h5" color="primary"> Reset Password </Typography>
                    <Typography component="p" variant="p" color="primary"> {userEmail} </Typography>
                    <hr/>
                    {codeExpired ?
                      <div>
                        <Typography component="p" variant="p" color="primary">
                          Your password reset code has expired. Please request to reset your password again.
                        </Typography> 
                        <Button className={classes.signUpButton} onClick={() => history.push('/')} fullWidth variant="outlined">
                         Back To Sign In
                        </Button>
                      </div>
                    :
                      <div>
                      {!passwordResetSuccess ?
                        <div>  
                          <TextField onChange={(event) => setPassword(event.target.value)} variant="outlined" margin="normal" helperText="8 Characters Minimum" required fullWidth name="password" label="New Password" type="password" id="password" />
                          <TextField onChange={(event) => setConfirmPassword(event.target.value)} variant="outlined" margin="normal" required fullWidth name="confirm-password" label="Confirm Password" type="password" id="confirm-password" />
                          <Button onClick={(event) => handleResetPassword(event)} fullWidth variant="contained" color="primary" className={classes.submit}>
                            {resettingPassword ? <CircularProgress size={24} color="secondary"/> : "Reset Password"}
                          </Button>
                        </div>
                        :
                        <div>
                          <Typography component="h1" variant="h5" color="secondary"> Your Password Has Been Reset </Typography>
                          <Button className={classes.signUpButton} onClick={() => history.push('/')} fullWidth variant="outlined">
                            Back To Sign In
                          </Button>
                        </div>
                        }
                      </div> 
                    }
                  </form> 
                </div>
              : "" }

              {mode === "recoverEmail" ? 
                <div>
                  <form className={classes.form} noValidate>
                    <Typography component="h1" variant="h5" color="primary"> Recover Your Old Email </Typography>
                    <Typography component="p" variant="p" color="primary"> {userEmail} </Typography>
                    
                    {codeExpired ?
                      <div>
                        <Typography component="p" variant="p" color="primary">
                          Your recovery code has expired.
                        </Typography> 
                        <Button className={classes.signUpButton} onClick={() => history.push('/')} fullWidth variant="outlined">
                         Back To Sign In
                        </Button>
                      </div>
                    :
                      <div>
                      {!emailRecoverySuccess ?
                        <div>
                          <hr/>
                            <Typography component="p" variant="p" color="primary"> Only do this if your email was changed without your consent. If you made the change, you don't have to take any further action.</Typography>
                          <hr/>  
                          <Button onClick={(event) => handleRecoverEmail(event)} fullWidth variant="contained" color="primary" className={classes.submit}>
                            {recoveringEmail ? <CircularProgress size={24} color="secondary"/> : "Recover Email"}
                          </Button>
                        </div>
                        :
                        <div>
                           <hr/>
                            <Typography component="p" variant="p" color="secondary"> Your Email Has Been Recovered. We Have Sent You a Passowrd Reset Link. </Typography>
                          <hr/>
                          <Button className={classes.signUpButton} onClick={() => history.push('/')} fullWidth variant="outlined">
                            Back To Sign In
                          </Button>
                        </div>
                        }
                      </div> 
                    }
                  </form> 
                </div>
              : "" }
              
            </div>

              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={error} autoHideDuration={6000} onClose={handleSnackBarClose}>
                  <Alert severity="error">{errorMessage}</Alert>
              </Snackbar>

              <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={passwordReset}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                message={"Password Successfully Reset."}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />
           
        </Container>
    
    )
}

export default AccountManagement;