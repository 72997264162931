import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import { firestore } from '../config/firebase-config';
import firebase from "firebase/app";
import CheckoutForm from '../components/CheckoutForm'
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CreateGroupForm from '../components/CreateGroupForm';
import GroupIcon from '@mui/icons-material/Group';
import SpaIcon from '@mui/icons-material/Spa';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import Logo from '../images/msplogo.png';
import 'cordova-plugin-purchase';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
    paddingBottom: theme.spacing(8),
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  forms: {
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
    textAlign: 'center',
  },
  logo:{
    width: '18%',
    height: 'auto',
    marginBottom: theme.spacing(2),
  },
  subTitle:{
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.dark,
  },
  listItem: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '18px',
    lineHeight: 1.5,
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const EGolak = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext)
    const [userId, setUserId] = useState("");
    const [loading, setLoading] = useState(false);
    //const {store, ProductType, Platform} = CdvPurchase;
  

    useEffect(() => {
     
      window.scrollTo(0,0);

      if (Capacitor.isNativePlatform()){
        //Initialising store
      }

    }, [])

   
    return(
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                <Typography className={classes.title} variant="h4" component="h1"> Donate </Typography>
            </div>
            <Layout>
              {/* We are only rendering the donate button and paath on the web app. This is hidden on native apps until in app purchase is implemented */}
              {Capacitor.getPlatform() === 'web' ? 
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                        <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>
                        <Typography className={classes.subTitle} gutterBottom variant="h4" component="h3"> Support Us </Typography>
                        {/*<Elements stripe={stripePromise}>
                            <CheckoutForm/>
                        </Elements>*/}
                        <p className={classes.listItem}>
                        All donations are securely handled via STRIPE and you will be emailed a donation receipt. We truly appreciate your support.
                        </p>
                    
                        <p className={classes.listItem}>
                        The donation form will load in a seperate browser window.
                        </p>

                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="secondary" fullWidth endIcon={<OpenInNewIcon/>} onClick={() => window.open('https://www.mysehajpaath.com/e-golak', "_blank")}>Open Donation Form</Button>    
                    </CardActions>   
                </Card>
              :
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                        <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>
                        <Typography className={classes.subTitle} gutterBottom variant="h4" component="h3"> Support Us </Typography>
                       
                        <p className={classes.listItem}>
                        Thank you for your interest in supporting MySehajPaath. To learn more about how you can support us, please visit our website.
                        </p>

                    </CardContent>
                    <CardActions>
                        <Button variant="contained" color="secondary" fullWidth endIcon={<OpenInNewIcon/>} onClick={() => window.open('https://www.mysehajpaath.com/', "_blank")}>Learn More</Button>    
                    </CardActions>  
                </Card>
            }
                
                       
            </Layout>
        </div>
    )
}

export default EGolak;