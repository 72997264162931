import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { UserContext } from '../components/UserProvider';
import { firestore, auth, generateUserDocument, generateUserNameDocument, generateNotificationDocument, generateFCMTokenDocument, functions } from '../config/firebase-config';
import * as Realm from 'realm-web';
import { useRealmApp } from '../config/RealmApp';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useAuthState} from "react-firebase-hooks/auth";
import Logo from '../images/msplogo.png';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    logo:{
      width: '35%',
      height: 'auto',
      marginBottom: theme.spacing(2),
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const Alert = React.forwardRef(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const SignUp = () => {

    const app = useRealmApp();
    const classes = useStyles();
    const history = useHistory();
    const user = useContext(UserContext);
    //const [user, userLoading, userError] = useAuthState(auth);
    //const [displayName, setDisplayName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signingUp, setSigningUp] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userNameExists, setUserNameExists] = useState(false);
    const [checkingUserName, setCheckingUserName] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const checkUserName = functions.httpsCallable('checkUserName');
 

    useEffect(() => {
      if(user?.user !== undefined && user?.user !== null && user?.user.displayName){
          history.push('/home');
      }
      if(user?.user !== undefined && user?.user !== null && !user?.user.displayName){
          history.push('/welcome');
      }
    }, [user])


    const realmSignin = async () => {
      try {
         // TODO: Call the logIn() method with email credentials.
        await app.logIn(Realm.Credentials.emailPassword('rana.designscience@gmail.com', 'test123'));
      } catch (error) {
        setError(true);
        setErrorMessage(error);
      }
    };

    const sendWelcomeEmail = (email) => {

      firestore.collection('mail').add({
        to: email,
        message: {
            subject: `Welcome To MySehajPaath`,
            html: `<!doctype html>
            <html>
              <head>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                <title>Simple Transactional Email</title>
                <style>
            @media only screen and (max-width: 620px) {
              table.body h1 {
                font-size: 28px !important;
                margin-bottom: 10px !important;
              }
            
              table.body p,
            table.body ul,
            table.body ol,
            table.body td,
            table.body span,
            table.body a {
                font-size: 16px !important;
              }
            
              table.body .wrapper,
            table.body .article {
                padding: 10px !important;
              }
            
              table.body .content {
                padding: 0 !important;
              }
            
              table.body .container {
                padding: 0 !important;
                width: 100% !important;
              }
            
              table.body .main {
                border-left-width: 0 !important;
                border-radius: 0 !important;
                border-right-width: 0 !important;
              }
            
              table.body .btn table {
                width: 100% !important;
              }
            
              table.body .btn a {
                width: 100% !important;
              }
            
              table.body .img-responsive {
                height: auto !important;
                max-width: 100% !important;
                width: auto !important;
              }
            }
            @media all {
              .ExternalClass {
                width: 100%;
              }
            
              .ExternalClass,
            .ExternalClass p,
            .ExternalClass span,
            .ExternalClass font,
            .ExternalClass td,
            .ExternalClass div {
                line-height: 100%;
              }
            
              .apple-link a {
                color: inherit !important;
                font-family: inherit !important;
                font-size: inherit !important;
                font-weight: inherit !important;
                line-height: inherit !important;
                text-decoration: none !important;
              }
            
              #MessageViewBody a {
                color: inherit;
                text-decoration: none;
                font-size: inherit;
                font-family: inherit;
                font-weight: inherit;
                line-height: inherit;
              }
            
              .btn-primary table td:hover {
                background-color: #34495e !important;
              }
            
              .btn-primary a:hover {
                background-color: #34495e !important;
                border-color: #34495e !important;
              }
            }
            </style>
              </head>
              <body style="background-color: #f6f6f6; text-align: center; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                  <tr>
                    <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                    <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                      <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
            
                        <!-- START CENTERED WHITE CONTAINER -->
                        <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
            
                          <!-- START MAIN CONTENT AREA -->
                          <tr>
                            <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                              <img src="http://mysehajpaath.com/wp-content/uploads/2022/03/MSP-Final-Gold-300.png" style="width: 100px; height: auto; margin-bottom: 15px" alt="My Sehaj Paath Logo"/>  
                              <tr>
                                  <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                    <p style="font-family: sans-serif; font-size: 14px; font-weight: bold; margin: 0; margin-bottom: 18px;">Welcome To MySehajPaath,</p>
                                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 13px;"> The Sri Guru Granth Sahib Ji remains the source of having a deeper knowledge and understanding of how to move through our time that we have been given as a gift on earth, and to prepare us for our inevitable journey ahead.
                                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 13px;">We hope through the use of this app, you are able to connect to this source of deeper knowledge.</p>
                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                      <tbody>
                                        <tr>
                                          <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                              <tbody>
                                                <tr>
                                                  <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> 
                                                    <a href="https://app.mysehajpaath.com" target="_blank" style="border: solid 1px #3498db; border-radius: 5px; box-sizing: border-box; width: 100%; cursor: pointer; display: inline-block; font-size: 13px; font-weight: bold; margin: 0; padding: 10px 20px; text-decoration: none; text-transform: capitalize; background-color: #5A8087; border-color: #5A8087; color: #ffffff;">Open App</a> 
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
            
                        <!-- END MAIN CONTENT AREA -->
                        </table>
                        <!-- END CENTERED WHITE CONTAINER -->
            
                        <!-- START FOOTER -->
                        <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                            <tr>
                              <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                <a href="https://www.mysehajpaath.com" target="_blank"><span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">https://www.MySehajPaath.com</span></a>
                              </td>
                            </tr>
                          </table>
                        </div>
                        <!-- END FOOTER -->
            
                      </div>
                    </td>
                    <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                  </tr>
                </table>
              </body>
            </html>`,
        },
    })
  }

    const checkUserNameAvailable = () => {
      if(userName === ""){
        setUserNameExists(false);
        setCheckingUserName(false);
        return;
      }
      else{
        setUserNameExists(false);
        setCheckingUserName(true);
        const data = {requestedUserName: userName};
        const context = "";
        let exists;
        checkUserName(data, context).then((result) => {
            exists = result.data.userNameExists;
            setUserNameExists(exists);
            setCheckingUserName(false);
        }).catch((error) => {
            console.log(error);
        });
        return exists;
      }
     
    };

    const handleUserNameChange = (event) => {
      const lowercaseUsername = event.target.value.toLowerCase();
      setUserName(lowercaseUsername);
    }
    

    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
        event.preventDefault();
        if(email === ""){
          setError(true);
          setErrorMessage("Please enter email.");
        }
        else if(firstName === ""){
          setError(true);
          setErrorMessage("Please enter your first name.");
        }
        else if(lastName === ""){
          setError(true);
          setErrorMessage("Please enter your last name.");
        }
        else if(userName === ""){
          setError(true);
          setErrorMessage("Please enter a username");
        }
        else if(password.length < 8){
          setPasswordError(true);
        }
        else{
          setPasswordError(false);
          setSigningUp(true);
          try{
            const {user} = await auth.createUserWithEmailAndPassword(email, password);
            const displayName = firstName + " " + lastName;
            generateUserDocument(user, {displayName, userName});
            generateUserNameDocument(user, displayName, userName, email);
            generateNotificationDocument(user);
            generateFCMTokenDocument(user);
            realmSignin();
            sendWelcomeEmail(email);
          }
          catch(error){
            setSigningUp(false);
            setError(true);
            setErrorMessage('Error Signing up with email and password');
          }
          setSigningUp(false);
        }
    };


    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setError(false);
    };

    const handleKeyAdd = (event) => {
      if (event.key === 'Enter' && email !== "" && password !== "" && firstName !== "" && lastName !== "" && userName !== "") {
        createUserWithEmailAndPasswordHandler(event, email, password);
      }
      else if(event.key === 'Enter' && email === ""){
          setError(true);
          setErrorMessage("Please enter email.");
      }
      else if(event.key === 'Enter' && firstName === ""){
        setError(true);
        setErrorMessage("Please enter your full name.");
      }
      else if(event.key === 'Enter' && lastName === ""){
        setError(true);
        setErrorMessage("Please enter your full name.");
      }
      else if(event.key === 'Enter' && userName === ""){
        setError(true);
        setErrorMessage("Please enter a username");
      }
      else if(event.key === 'Enter' && password === ""){
          setError(true);
          setErrorMessage("Please enter password.");
      }
    };


    return(

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <img src={Logo} className={classes.logo} alt="My Sehaj Paath Logo"/>
            <Typography component="h1" variant="h5">
                Sign Up
            </Typography>
                <form className={classes.form} noValidate>
                    
                    <TextField onChange={(event) => setFirstName(event.target.value)} onKeyDown={handleKeyAdd}  variant="outlined" margin="normal" required fullWidth id="first-name" label="First Name" name="first-name" autoComplete="first name" autoFocus />
                    
                    <TextField onChange={(event) => setLastName(event.target.value)} onKeyDown={handleKeyAdd}  variant="outlined" margin="normal" required fullWidth id="last-name" label="Last Name" name="last-name" autoComplete="last name" />

                    <TextField onChange={(event) => setEmail(event.target.value.trim())} onKeyDown={handleKeyAdd}  variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" />
                    
                    {userNameExists ? 
                    
                      <TextField onChange={handleUserNameChange} onKeyDown={handleKeyAdd} onBlur={checkUserNameAvailable} 
                      error helperText={userNameExists ? "Username Taken" : ""} variant="outlined" margin="normal" required fullWidth id="user-name" 
                      label="User Name" name="username"/>
                      :
                      <TextField onChange={handleUserNameChange} onKeyDown={handleKeyAdd} onBlur={checkUserNameAvailable} 
                      variant="outlined" margin="normal" required fullWidth id="user-name" label="User Name" name="username" helperText={checkingUserName ? "Checking Username" : ""}
                      InputProps={{
                        endAdornment: <InputAdornment position="start">  {checkingUserName ? <CircularProgress color="primary" size={25}/> : "" } </InputAdornment>
                      }}/>
                    
                    }
                    
                    {passwordError ? 
                     <TextField onChange={(event) => setPassword(event.target.value)} onKeyDown={handleKeyAdd} helperText="*8 Characters Minimum" error variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                    :
                    <TextField onChange={(event) => setPassword(event.target.value)} onKeyDown={handleKeyAdd} helperText="*8 Characters Minimum" variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />
                    }
                   
                  
                    {userNameExists || checkingUserName ? 

                      <Button disabled fullWidth variant="contained" color="primary" className={classes.submit}>
                       Create Account
                      </Button>
                      :
                      <Button onClick={(event) => {createUserWithEmailAndPasswordHandler(event, email, password)}} fullWidth variant="contained" color="primary" className={classes.submit}>
                        {signingUp ? <CircularProgress size={24} color="secondary"/> : "Create Account"}
                      </Button>
                    
                    }
                 
                    <Button onClick={() => history.push('/')} fullWidth variant="text">
                        Back To Sign In
                    </Button>

                </form>
            </div>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={error} autoHideDuration={6000} onClose={handleSnackBarClose}>
                  <Alert severity="error">{errorMessage}</Alert>
            </Snackbar>

            {/* <Snackbar
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={error}
                autoHideDuration={6000}
                onClose={handleSnackBarClose}
                message={errorMessage}
                action={
                  <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              /> */}
            <Box mt={8}>
            
            </Box>
        </Container>
    
    )
}

export default SignUp;