import { FC, RefObject, useCallback, useRef, useImperativeHandle, memo } from "react";

function StripeInput ({ component: Component, inputRef, ...props }) {
    const elementRef = useRef();
    useImperativeHandle(inputRef, () => ({
      focus: () => elementRef.current.focus
    }));
  
    return (
      <Component
        onReady={element => (elementRef.current = element)}
        style={{
          base: {
            color: 'green' // The desired result is white, for the example I'm using green but no changes
          }
        }}
        {...props}/>
    )
  }
  
  export default StripeInput;