import React, { useState, useContext, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useStripe, useElements, PaymentElement, CardNumberElement, CardCvcElement, CardExpiryElement} from '@stripe/react-stripe-js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import StripeInput from '../components/StripeInput';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
      width: '100%',
      paddingBottom: theme.spacing(1),
    },
    forms: {
      marginBottom: theme.spacing(2),
      borderRadius: '10px',
    },
    subTitle:{
      marginBottom: theme.spacing(4),
      color: theme.palette.primary.dark,
    },
    inputStyle:{
       marginBottom: theme.spacing(1),
    }
  }));

const CheckoutForm = () => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
        }

        const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
            return_url: "https://example.com/order/123/complete",
        },
        });

        if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <InputLabel htmlFor="outlined-adornment-amount">Donation Amount</InputLabel>
            <OutlinedInput id="outlined-adornment-amount" value={""} onChange={""} className={classes.inputStyle}
            startAdornment={<InputAdornment position="start">$</InputAdornment>} label="Amount" fullWidth/>

             <TextField variant="outlined" size="large" required fullWidth className={classes.inputStyle} 
             InputProps={{ inputComponent: StripeInput, inputProps: { component: CardNumberElement }, 
             className: classes.multilineColor }} />

             <TextField variant="outlined" size="large" required fullWidth className={classes.inputStyle} 
             InputProps={{ inputComponent: StripeInput, inputProps: { component: CardExpiryElement }, 
             className: classes.multilineColor }} />

             <TextField variant="outlined" size="large" required fullWidth className={classes.inputStyle} 
             InputProps={{ inputComponent: StripeInput, inputProps: { component: CardCvcElement }, 
             className: classes.multilineColor }} />
           
            <Button variant="contained" color="secondary" fullWidth disabled={!stripe}>Donate</Button>
        </form>
    )
};

export default CheckoutForm;