import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { firestore, functions } from '../config/firebase-config';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { UserContext } from '../components/UserProvider';
import PaathReader from '../components/PaathReader';
import PaathReaderCurrent from '../components/PaathReaderCurrent';
import EditPaathForm from '../components/EditPaathForm';
import { format, differenceInCalendarDays, compareAsc, isAfter } from 'date-fns';
import { lineHeight } from '@mui/system';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import SpaIcon from '@mui/icons-material/Spa';
import GreetingImage from '../images/greeting-image.png';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        backgroundColor: theme.palette.primary.background,
    },
    card: {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        borderRadius: '10px',
    },
    pageHeader: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 54,
        marginBottom: '20px',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',   
        minHeight: 210,
        backgroundColor: theme.palette.info.main,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    paathTypeBadge:{
        width: '120px',
    },
    greetingImage:{
        width: '100px',
    },
    donationMessage:{
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    paathCardContainer: {
        marginTop: '-12px',
        marginBottom: theme.spacing(9),
    },
    paathActionButtons: {
        marginTop: theme.spacing(2),
    },
    paathAction: {
        marginRight: 5,
    },
    paathEditButton: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.primary.lightText,
    },
    participantList:{
        padding: theme.spacing(1),
    },
    participant:{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1),
    },
    title: {
        
    },
    progressContainer: {
        backgroundColor: '#F1F5F5',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: '10px',
    },
    dateDetail:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(1),
        border: 'solid 2px',
        borderRadius: '10px',
        borderColor: '#C4C4C4',
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 37%, rgba(233,178,76,0.5284488795518207) 63%, rgba(178,218,225,1) 100%)',
    },
    dateLabel: {
        fontWeight: 700,
        color: theme.palette.darkText,
        fontSize: '15px'
    },
    displayDate: {
        fontWeight: 700,
        color: theme.palette.darkText,
        lineHeight: 1,
    },
    startDate:{
    
    },
    endDate:{
        textAlign: 'right',
    },
    paathCompletion: {
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#F1F5F5',
        marginTop: 5,
        borderRadius: '10px',
        alignContent: 'center',
    },
    paathTilePercent: {
        backgroundColor: theme.palette.secondary.main,
        padding: 10,
        alignSelf: 'center',
        borderRadius: '10px',
        fontSize: '12px',
    },
    progressPercent: {
        marginTop: '3px',
        paddingLeft: '5px',
    },
    paathStatusContainer: {
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignContent: 'flex-end',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    readingSpeedStatusContainer: {
        // display: 'flex',
        // justifyContent: 'space-between',
        // alignContent: 'flex-end',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    statusLabel: {
        fontWeight: 700,
        color: theme.palette.darkText,
        fontSize: '15px',
        lineHeight: 1.2,
    },
    statusLabel2: {
        color: theme.palette.darkText,
        fontSize: '15px',
        lineHeight: 1.2,
    },
    paathStatusText: {
        color: theme.palette.primary.darkText,
        fontSize: '19px',
        lineHeight: 1.2,
    },
    paathLink: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    copyLinkButton:{
        marginTop: '-10px',
    },
    divider:{
        marginBottom: theme.spacing(2),
    },
    readerList:{
        padding: 0,
    }
}));

const PaathDetails = () => {

    const classes = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const { user } = useContext(UserContext);
    const [loggedInUserId, setLoggendInUserId] = useState("");
    const [paathDetails, setPaathDetails] = useState({});
    const [progressPercent, setProgressPercent] = useState(0);
    const [currentPaathReaderId, setCurrentPaathReaderId] = useState("");
    const [loading, setLoading] = useState(false);
    const [editView, setEditView] = useState(false);
    const sendPushNotificationTurnToRead = functions.httpsCallable('sendPushNotificationTurnToRead');

    useEffect(() => {

        window.scrollTo(0,0);
        setLoading(true);
        // Realtime listener setup for firestore collection updates.
        const unsubscribe = firestore.collection("paaths").doc(id).onSnapshot(doc => {
            if(doc.exists){
                const paath = doc.data();
                paath.id = doc.id;
                setPaathDetails(paath);
                setProgressPercent((paath.progress.currentPage/1430)*100);
                setCurrentPaathReaderId(paath.progress.currentReader);
                setLoading(false);
            }
            else{
                console.log("No Paath");
            }
        })
        setLoggendInUserId(user?.uid);
        return () => {
            unsubscribe()
        }

    }, [user])



    const startPaath = () => {
        let startDate = paathDetails?.startDate?.toDate();
        let compareDateResult = isAfter(new Date(startDate), new Date());
        let startDateFormat = format(startDate, "EEE MMM dd, yyyy");
        let todaysDateFormat = format(new Date(), "EEE MMM dd, yyyy");
        // Checks to see if the paath start date is after todays date
        // thus not allowing the paath to start. However if its not after
        // today or is today, lets the paath start.
        if(!compareDateResult || startDateFormat === todaysDateFormat){
            firestore.collection("paaths").doc(id).update({
                paathStatus: "started",
                progress:{
                    currentPage: 1,
                    lineNumberMarker: 1,
                    currentReader: paathDetails.participants[0].userId,
                    readingStatus: "toStartRead"
                } 
             });
        }
        else{
            alert("Paath can only be started on the start date. You can edit the paath and change the start date if you would like to start sooner.");
        }
       
    }

    const pausePaath = () => {
        if(paathDetails.progress.readingStatus === "readingStarted"){
            alert("Paath can not be paused when a participant is reading.");
        }
        else{
            firestore.collection("paaths").doc(id).update({
                paathStatus: "paused",
             })
        }
    }

    const reStartPaath = () => {
        let startDate = paathDetails?.startDate?.toDate();
        const compareDateResult = isAfter(new Date(startDate), new Date());
        let startDateFormat = format(startDate, "EEE MMM dd, yyyy");
        let todaysDateFormat = format(new Date(), "EEE MMM dd, yyyy");
        // Checks to see if the paath start date is after todays date
        // thus not allowing the paath to re-start. However if its not after
        // today or is today, lets the paath start.
        if(!compareDateResult || startDateFormat === todaysDateFormat){
        firestore.collection("paaths").doc(id).update({
            paathStatus: "started",
         })
        }else{
            alert("Paath can only be re-started on the start date. You can edit the paath and change the start date if you would like to start sooner.");
        }
    }

    const completePaath = () => {
        firestore.collection("paaths").doc(id).update({
            paathStatus: "completed",
        })
    }

     // Updates each participants notifications collection with a message they've been added to a paath.
    // The participant then has to accept or decline the paath invitation for the paath to show up
    // for that user.
    const notifyParticipantTurnToRead = (userId, paathId) => {
        const paathCreatedBy = user.uid;
        firestore.collection("notifications").doc(userId).update({
            userNotification:  firebase.firestore.FieldValue.arrayUnion({
                date: new Date(),
                description: "",
                paathCreatedBy: paathCreatedBy,
                paathId: paathId,
                paathTitle: paathDetails.paathTitle,
                read: false,
                title: "It's Your Turn To Read",
                type: "turnToRead"
            })
        })
        // Add Cloud Notification here.
    }

    const sendPushNotificationToParticipant = (userId, paathId) => {
        const data = {paathId: paathId, paathTitle: paathDetails.paathTitle , userId: userId, message: "It's Your Turn to Read Paath"};
        const context = user.uid;
        sendPushNotificationTurnToRead(data, context).then((result) => {
            console.log(result);
        }).catch((error) => {
            console.log(error);
        });
   }


   // Sends User Email that it's there turn to read.
   const sendEmailToParticipant = async (userId, paathId) => {

        let userEmailAllowed;
        let participant = paathDetails.participants.filter(participant => participant.userId === userId);
        //Gets the user document to check their email notificaiton preferences.
        await firestore.collection('users').doc(userId).get().then((doc) => userEmailAllowed = doc.data().preferences.emailNotification);
        //Send the user a turn to read email if they have email notifications enabled.
        if(userEmailAllowed){
            firestore.collection('mail').add({
                to: participant[0].email,
                message: {
                    subject: `MySehajPaath - Your Turn To Read for ${paathDetails.paathTitle}`,
                    html: `<!doctype html>
                    <html>
                      <head>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                        <title>Simple Transactional Email</title>
                        <style>
                    @media only screen and (max-width: 620px) {
                      table.body h1 {
                        font-size: 28px !important;
                        margin-bottom: 10px !important;
                      }
                    
                      table.body p,
                    table.body ul,
                    table.body ol,
                    table.body td,
                    table.body span,
                    table.body a {
                        font-size: 16px !important;
                      }
                    
                      table.body .wrapper,
                    table.body .article {
                        padding: 10px !important;
                      }
                    
                      table.body .content {
                        padding: 0 !important;
                      }
                    
                      table.body .container {
                        padding: 0 !important;
                        width: 100% !important;
                      }
                    
                      table.body .main {
                        border-left-width: 0 !important;
                        border-radius: 0 !important;
                        border-right-width: 0 !important;
                      }
                    
                      table.body .btn table {
                        width: 100% !important;
                      }
                    
                      table.body .btn a {
                        width: 100% !important;
                      }
                    
                      table.body .img-responsive {
                        height: auto !important;
                        max-width: 100% !important;
                        width: auto !important;
                      }
                    }
                    @media all {
                      .ExternalClass {
                        width: 100%;
                      }
                    
                      .ExternalClass,
                    .ExternalClass p,
                    .ExternalClass span,
                    .ExternalClass font,
                    .ExternalClass td,
                    .ExternalClass div {
                        line-height: 100%;
                      }
                    
                      .apple-link a {
                        color: inherit !important;
                        font-family: inherit !important;
                        font-size: inherit !important;
                        font-weight: inherit !important;
                        line-height: inherit !important;
                        text-decoration: none !important;
                      }
                    
                      #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                        font-size: inherit;
                        font-family: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                      }
                    
                      .btn-primary table td:hover {
                        background-color: #34495e !important;
                      }
                    
                      .btn-primary a:hover {
                        background-color: #34495e !important;
                        border-color: #34495e !important;
                      }
                    }
                    </style>
                      </head>
                      <body style="background-color: #f6f6f6; text-align: center; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                          <tr>
                            <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                            <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                              <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
                    
                                <!-- START CENTERED WHITE CONTAINER -->
                                <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
                    
                                  <!-- START MAIN CONTENT AREA -->
                                  <tr>
                                    <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                      <img src="http://mysehajpaath.com/wp-content/uploads/2022/03/MSP-Final-Gold-300.png" style="width: 100px; height: auto; margin-bottom: 15px" alt="My Sehaj Paath Logo"/>   
                                      <tr>
                                          <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                            <p style="font-family: sans-serif; font-size: 18px; font-weight: normal; margin: 0; margin-bottom: 15px;">Dear ${participant[0].displayName},</p>
                                            <p style="font-family: sans-serif; font-size: 18px; font-weight: normal; margin: 0; margin-bottom: 15px;">It's your turn to read for the following paath:</p>
                                            <p style="font-family: sans-serif; font-size: 20px; font-weight: bold; margin: 0; margin-bottom: 20px;">${paathDetails.paathTitle}</p>
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                              <tbody>
                                                <tr>
                                                  <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                                      <tbody>
                                                        <tr>
                                                          <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> 
                                                            <a href="https://app.mysehajpaath.com/paaths/${paathId}" target="_blank" style="border: solid 1px #5A8087; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 10px 20px; text-decoration: none; text-transform: capitalize; background-color: #5A8087; border-color: #5A8087; color: #ffffff;">Read Paath</a> 
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                    
                                <!-- END MAIN CONTENT AREA -->
                                </table>
                                <!-- END CENTERED WHITE CONTAINER -->
                    
                                <!-- START FOOTER -->
                                <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                    <tr>
                                      <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                        <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">MySehajPaath</span>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                                <!-- END FOOTER -->
                    
                              </div>
                            </td>
                            <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                          </tr>
                        </table>
                      </body>
                    </html>`,
                },
            })
        }
    }

    const passPaath = () => {
        if(window.confirm("Pass Paath To Next Reader?")){
            const currentReader = paathDetails.progress.currentReader;
            const currentReaderIndex = paathDetails.confirmedParticipants.indexOf(currentReader);
            // This was the old way to get the Index from the main participant array as it was an array of objects.
            // Leaving this here for future reference.
            //const currentReaderIndex = paathDetails.confirmedParticipants.map((reader) => { return reader.userId; }).indexOf(currentReader);
            const numberParticipants = paathDetails.confirmedParticipants.length-1;
            //console.log(currentReader, currentReaderIndex, numberParticipants);
    
            // If the current reader is the last participant
            // sets the next reader as the first reader in the participant array.
             if(currentReaderIndex === numberParticipants){
                   firestore.collection("paaths").doc(id).update({
                    'progress.currentReader': paathDetails.confirmedParticipants[0],
                 });
                 //Notification Function Goes Here
                 notifyParticipantTurnToRead(paathDetails.confirmedParticipants[0], id);
                 sendPushNotificationToParticipant(paathDetails.confirmedParticipants[0], id);
                 sendEmailToParticipant(paathDetails.confirmedParticipants[0], id);
              }
              //Increments index to the next reader.
              else{
                  firestore.collection("paaths").doc(id).update({
                      'progress.currentReader': paathDetails.confirmedParticipants[currentReaderIndex+1],
                 });
                 //Notification Function Goes Here
                 notifyParticipantTurnToRead(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
                 sendPushNotificationToParticipant(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
                 sendEmailToParticipant(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
              }
        }
        else{
            return;
        }
    }

    const passPaathAdmin = () => {
        if(paathDetails.progress.readingStatus === "readingStarted"){
            alert("The Paath Can't Be Passed To The Next Reader While a Participant is Reading.");
        }
        else {
            if(window.confirm("Pass Paath To Next Reader as Admin? Please Only Pass The Paath as An Admin if the Reader is Not Able To Read.")){
                const currentReader = paathDetails.progress.currentReader;
                const currentReaderIndex = paathDetails.confirmedParticipants.indexOf(currentReader);
                // This was the old way to get the Index from the main participant array as it was an array of objects.
                // Leaving this here for future reference.
                //const currentReaderIndex = paathDetails.confirmedParticipants.map((reader) => { return reader.userId; }).indexOf(currentReader);
                const numberParticipants = paathDetails.confirmedParticipants.length-1;
                //console.log(currentReader, currentReaderIndex, numberParticipants);
        
                // If the current reader is the last participant
                // sets the next reader as the first reader in the participant array.
                 if(currentReaderIndex === numberParticipants){
                       firestore.collection("paaths").doc(id).update({
                        'progress.currentReader': paathDetails.confirmedParticipants[0],
                     });
                     //Notification Function Goes Here
                     notifyParticipantTurnToRead(paathDetails.confirmedParticipants[0], id);
                     sendPushNotificationToParticipant(paathDetails.confirmedParticipants[0], id);
                     sendEmailToParticipant(paathDetails.confirmedParticipants[0], id);
                  }
                  //Increments index to the next reader.
                  else{
                      firestore.collection("paaths").doc(id).update({
                          'progress.currentReader': paathDetails.confirmedParticipants[currentReaderIndex+1],
                     });
                     //Notification Function Goes Here
                     notifyParticipantTurnToRead(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
                     sendPushNotificationToParticipant(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
                     sendEmailToParticipant(paathDetails.confirmedParticipants[currentReaderIndex+1], id);
                  }
            }
            else{
                return;
            }
        }
    }

    const readPaath = () => {
        history.push(`/read-paath/${id}/${paathDetails.progress.currentPage}/${paathDetails.progress.verseNumberMarker}`)
    }

    const renderStartDate = () => {
        if(paathDetails?.startDate?.toDate()){
            let date = paathDetails?.startDate?.toDate();
            return format(date, "EEE MMM dd, yyyy");
        }
    }

    const renderFinishDate = () => {
        if(paathDetails?.finishDate?.toDate()){
            let date = paathDetails?.finishDate?.toDate();
            return format(date, "EEE MMM dd, yyyy");
        }
    }

    const renderReadingSpeed = () => {
        let finishDate = paathDetails?.finishDate?.toDate();
        let startDate = paathDetails?.startDate?.toDate();
        if(!startDate || !finishDate || paathDetails?.paathStatus === "toStart"){
            return ""
        }
        else{
            let finishDateFormat = format(finishDate, "yyyy, M, d");
            let startDateFormat = format(startDate, "yyyy, M, d");
            let finishDateFormatDisplay = format(finishDate, "EEE MMM dd, yyyy");
            let today = new Date();
            let pastFinishDate = new Date() > new Date(finishDate);
            const numDays = differenceInCalendarDays(new Date(finishDate), new Date(startDate));
            const numDaysLeft = differenceInCalendarDays(new Date(finishDate), today);

            const numDaysElapsed = numDays - numDaysLeft; 
            const currentPage = paathDetails?.progress?.currentPage;
            const pagesPerDay = Math.round(1430/numDays);

            const requiredProgress = pagesPerDay * (numDaysElapsed === 0 ? 1 : numDaysElapsed);
            const currentReadingSpeed = Math.round(currentPage/(numDaysElapsed === 0 ? 1 : numDaysElapsed));
            
            //console.log(finishDate, startDate, today, numDays, numDaysLeft, numDaysElapsed, pastFinishDate, pagesPerDay);

            return (
                <div className={classes.readingSpeedStatusContainer}>
                    <div>
                        <Typography variant="body1" color="textPrimary" component="p">
                            Reading Speed
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p"> 
                            {numDaysLeft >= 0 ? currentReadingSpeed + " Pages / Day" : "You're Behind on Reading"}
                        </Typography>
                        <Typography className={classes.statusLabel2}> 
                            Required: {pagesPerDay} Pages / Day - Page {requiredProgress > 1430 ? "1430" : requiredProgress} by {numDaysLeft < 0 ? finishDateFormatDisplay : "Today"}
                        </Typography>
                    </div>
                </div>
            )
        }
    }

    const handlePaathEdit = () => {
        if(paathDetails?.paathStatus === "started"){
            alert("Please Pause the Paath Before Editing");
        }
        else{
            setEditView(true);
        }
    }

    const handleEditCancel = () => {
        if (window.confirm("Discard Changes?")){
            setEditView(false);
        }
    }


    const renderPaathStatus = (paathStatus, createdBy, progress) => {

        function paathComplete(progress){
            if(progress.currentPage === 1430 && progress.lineNumberMarker === 19 && progress.verseNumberMarker === 60403){
                return true;
            }
            else{
                return false;
            }
        }

        //Only the person who has created the paath has the ability to start and pause it.

        if(paathStatus === "toStart" && createdBy === loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath has to be started</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={startPaath}>
                        Start Paath
                        </Button>
                        <Button size="small" variant="contained" className={classes.paathEditButton} onClick={handlePaathEdit}>
                        Edit
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "started" && createdBy === loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Started</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={pausePaath}>
                        Pause Paath
                        </Button>
                        <Button size="small" variant="contained" className={classes.paathEditButton} onClick={handlePaathEdit}>
                        Edit
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "paused" && createdBy === loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Paused</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={reStartPaath}>
                        Re-Start Paath
                        </Button>
                        <Button size="small" variant="contained" className={classes.paathEditButton} onClick={handlePaathEdit}>
                        Edit
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "toStart" && createdBy !== loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath To Be Started</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "started" && createdBy !== loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath Started</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "paused" && createdBy !== loggedInUserId && !paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath Paused</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "toStart" && createdBy === loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={completePaath}>
                        Mark Paath Complete
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "started" && createdBy === loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={completePaath}>
                        Mark Paath Complete
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "paused" && createdBy === loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} onClick={completePaath}>
                        Mark Paath Complete
                        </Button>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "toStart" && createdBy !== loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "started" && createdBy !== loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "paused" && createdBy !== loggedInUserId && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                    <Typography className={classes.statusLabel}> Paath Status </Typography>
                    <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                </div>
            )
        }
        else if(paathStatus === "completed" && paathComplete(progress)){
            return(
                <div className={classes.paathStatusContainer}>
                    <div>
                        <Typography className={classes.statusLabel}> Paath Status </Typography>
                        <Typography className={classes.paathStatusText}>Paath Completed</Typography>
                    </div>
                    <div className={classes.paathActionButtons}>
                        <Button size="small" color="primary" variant="contained" className={classes.paathAction} startIcon={<SpaIcon/>} onClick={() => history.push('/e-golak/')}>
                        Support My Sehaj Paath
                        </Button>
                    </div>
                </div>
            )
        }

    }

    const renderCurrentPaathReader = (currentPaathReaderId) => {
        // Does not render the current reader component if the paath was just created
        // and has not yet been started as no current reader is assigned.
        // This only displays when the Star Paath button is pressed for the first time
        // and a reader gets assigned as the current reader of the paath.
        if(paathDetails?.participants?.length > 0 && currentPaathReaderId !== ""){
            const currentPaathReaderIndex = paathDetails?.participants?.findIndex(user => user.userId === currentPaathReaderId)
            //console.log(currentPaathReaderId, currentPaathReaderIndex);
            return(
                <PaathReaderCurrent 
                    displayName={paathDetails?.participants[currentPaathReaderIndex]?.displayName} 
                    userName={paathDetails?.participants[currentPaathReaderIndex]?.id} 
                    userId={paathDetails?.participants[currentPaathReaderIndex]?.userId}
                    createdBy={paathDetails?.createdBy}
                    confirmedParticipants={paathDetails?.confirmedParticipants}
                    declinedParticipants={paathDetails?.declinedParticipants}  
                    progress={paathDetails?.progress}
                    status={paathDetails?.paathStatus}
                    passPaath={passPaath}
                    passPaathAdmin={passPaathAdmin}
                    readPaath={readPaath}
                />
            )
        }
        else{
            return <ListItemText primary={"No Current Reader"} secondary={"Waiting For Paath To Start"}/>;
        }
    }

    // This function reorders the participants array to match the confirmed participants array order.
    const reorderParticipants = (participants) => {
        const confirmed = paathDetails.confirmedParticipants;
        const reorderedParticipants = confirmed.map((i) => participants.find((j) => j.userId === i));
        const remainingParticipants = participants.filter(participant => !confirmed.includes(participant.userId));
        const updatedParticipants = [...reorderedParticipants, ...remainingParticipants];
        return updatedParticipants;
    }

    const copyPaathInviteLink = () => {
        const paathLink = `https://app.mysehajpaath.com/invite/${paathDetails.id}`;
        navigator.clipboard.writeText(paathLink).then(() => {
            alert("Copied to clipboard");
        });
    }


    return(
        <div className={classes.root}>
            <div className={classes.pageHeader}>
                
                {!loading && paathDetails?.paathTitle ? 
                    <Typography className={classes.title} variant="h4" component="h1"> {paathDetails?.paathTitle}</Typography> 
                    : 
                    <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'50%'} height={32} /> 
                } 
                {!loading && paathDetails?.createdByDisplayName ? 
                    <Typography variant="h6" color="textPrimary" component="p" gutterBottom> Started By: {paathDetails.createdBy === user?.uid ? `You - ${paathDetails.createdByDisplayName}` : `${paathDetails.createdByDisplayName}`}</Typography>
                    : 
                    <Skeleton variant="rectangular" animation="wave" style={{marginTop: 5, marginBottom: 5}} width={'100%'} height={30} /> 
                } 
                 <Chip className={classes.paathTypeBadge} label={ paathDetails?.publicPaath ? "Public" : "Private" +" "+ paathDetails?.paathType} size="small"/> 
            </div>
            <Layout>

            {!editView ? 
                <div className={classes.paathCardContainer}>
                   {/* <Button fullWidth variant="contained" color="secondary" onClick={() => history.push('/e-golak')} className={classes.donationMessage}>Support My Sehaj Paath</Button> */}
                    <Card className={classes.card} variant="outlined">
                        <CardContent>
                            <div className={classes.progressContainer}>
                                <div className={classes.dateDetail}>
                                    <div className={classes.startDate}>
                                        <Typography className={classes.dateLabel}>
                                            Start
                                        </Typography>
                                        {!loading && paathDetails?.startDate ? 
                                             <Typography className={classes.displayDate}> { renderStartDate() } </Typography>
                                            : 
                                            <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'100%'} height={16} /> 
                                        } 
                                       
                                    </div>
                                    <div className={classes.endDate}>
                                        <Typography className={classes.dateLabel}>
                                            Finish
                                        </Typography>
                                        {!loading && paathDetails?.finishDate ? 
                                            <Typography className={classes.displayDate}> { renderFinishDate() } </Typography>
                                            : 
                                            <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'100%'} height={16} /> 
                                        } 
                                    </div>
                                </div>

                                <Paper elevation={0} className={classes.paathCompletion}>
                                    <Box sx={{ backgroundColor: '#89C09F', padding: '15px', alignSelf: 'center', borderRadius: '10px', fontSize: '12px', width: progressPercent + '%' }}/>
                                    <Typography className={classes.progressPercent}>    
                                        {Math.round(progressPercent) + '%'}
                                    </Typography>
                                </Paper>

                                {!loading && paathDetails?.paathStatus ? 
                                     renderPaathStatus(paathDetails?.paathStatus, paathDetails?.createdBy, paathDetails?.progress)
                                    :
                                    <div> 
                                        <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'40%'} height={16} />
                                        <Skeleton variant="rectangular" animation="wave" style={{marginTop: 5}} width={'50%'} height={16} />
                                    </div> 
                                }
                                
                            </div>                          
                
                            <Typography variant="body1" color="textPrimary" component="p">
                                Current Page & Line
                            </Typography>
                            {!loading && paathDetails?.progress ? 
                                <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                                    Page: {paathDetails?.progress?.currentPage} - Line: {paathDetails?.progress?.lineNumberMarker} - Verse: {paathDetails?.progress?.verseNumberMarker}
                                </Typography>
                                : 
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'100%'} height={30} /> 
                            } 

                              {/* Reading Speed */}
                              {!loading && paathDetails?.paathStatus === "completed" ? "" : renderReadingSpeed() } 
                            <Divider className={classes.divider}/>
                            <Typography variant="body1" color="textPrimary" component="p">
                                Paath Invite Link (Click To Copy)
                            </Typography>
                            {!loading && paathDetails?.id ? 
                                 <div className={classes.paathLink}>
                                    <Typography onClick={copyPaathInviteLink} variant="subtitle1" color="textSecondary" component="p" gutterBottom>invite/{paathDetails.id}</Typography>
                                    <ContentCopyIcon onClick={copyPaathInviteLink} fontSize="small"/>
                                </div>
                                : 
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'100%'} height={30} /> 
                            }

                             <Divider className={classes.divider}>
                                <Chip label="CURRENT READER" />
                            </Divider>

                            {/* Renders The Current Paath Reader */}
                            {renderCurrentPaathReader(currentPaathReaderId)}

                            <Divider className={classes.divider}>
                                <Chip label="ALL PAATHIS" />
                            </Divider>

                            
                            {!loading && paathDetails?.participants ? 

                                <List className={classes.readerList}>
                                {
                                    paathDetails?.participants?.length === 0 ? "No Paathis Added" 
                                    : 
                                    reorderParticipants(paathDetails?.participants).map((user, index) => (
                                        
                                        <PaathReader 
                                            key={index} 
                                            displayName={user?.displayName} 
                                            userName={user?.id} 
                                            userId={user?.userId}
                                            createdBy={paathDetails?.createdBy}
                                            confirmedParticipants={paathDetails?.confirmedParticipants}
                                            declinedParticipants={paathDetails?.declinedParticipants}  
                                            progress={paathDetails?.progress}
                                            status={paathDetails?.paathStatus}
                                            passPaath={passPaath}
                                            passPaathAdmin={passPaathAdmin}
                                            readPaath={readPaath}
                                        />
                                    
                                    ))
                                }
                                </List>
                                :
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: 0}} width={'100%'} height={300} /> 
                            }
                        </CardContent>     
                    </Card>
                </div> 
                : 
                <EditPaathForm
                    id={id}
                    CreatedBy={paathDetails?.createdBy} 
                    PaathTitle={paathDetails.paathTitle} 
                    PaathType={paathDetails.paathType}
                    PublicPaath={paathDetails.publicPaath}
                    StartDate={paathDetails.startDate}
                    FinishDate={paathDetails.finishDate} 
                    UserName={""}
                    SelectedUsers={paathDetails.participants}
                    ConfirmedParticipants={paathDetails.confirmedParticipants}
                    DeclinedParticipants={paathDetails.declinedParticipants}
                    Options={paathDetails.options} 
                    handleEditCancel={handleEditCancel}
                    setEditView={setEditView}
                /> 
            }

            </Layout>
        </div>
    )
}

export default PaathDetails;