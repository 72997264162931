import React, { Component, createContext } from 'react';
import { useState, useEffect } from 'react';
//import {Redirect} from 'react-router-dom';


export const PaathInviteContext = createContext({ paathInviteId: "" });


 const PaathInviteProvider = ( {children} ) => {

     const [paathInviteIdContext, setPaathInviteIdContext] = useState("");
  
 
    return (
      <PaathInviteContext.Provider value={{paathInviteIdContext, setPaathInviteIdContext}}>

        {children}
        
      </PaathInviteContext.Provider>
    );
  
}

export default PaathInviteProvider;