import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore } from '../config/firebase-config';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserContext } from '../components/UserProvider';
import { NotificationContext } from '../components/NotificationProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  notificationDetailsCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: '10px',
  },
  notificationContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  notificationsContainer: {
    marginBottom: theme.spacing(9),
  },
  titleBold: {
      fontWeight: 700,
  },
  noticationBadge: {
      float: 'right',
      marginRight: 12,
      marginTop: 1,
  }
}));

const Notifications = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext);
    const numberNotifications = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);

    // Reverses the notification array coming from firebase
    // as there is no way to sort with a realtime listner. 
    const renderNotifications = (fetchedNotification) => {
        const notificationArray = fetchedNotification.userNotification;
        const reversedArray = notificationArray.reverse();
        setNotifications({userNotification: reversedArray})
    }
    
    useEffect(() => {
     
        window.scrollTo(0,0);
        setLoading(true);
        // Realtime listener setup for firestore collection updates.
        const unsubscribe = firestore.collection("notifications").doc(user?.uid).onSnapshot(doc => {
            const notification = doc.data();
            setNotifications(notification);
            setLoading(false);
        })

        return () => {
            unsubscribe()
        }

    }, [user])


    const renderNotificationActions = (notificationType, paathId, paathCreatedBy, index) => {
        if(notificationType === "addedToPaath" && paathCreatedBy !== user.uid){
            return(
                <React.Fragment>
                    <Button color="success" onClick={() => handleAccept(paathId, user.uid, index)}>Accept</Button>
                    <Button color="error" onClick={() => handleDecline(paathId, user.uid, index)}>Decline</Button>
                </React.Fragment>
            )
        }
        if(notificationType === "addedToPaath" && paathCreatedBy === user.uid){
            return(
                <React.Fragment>
                    <Button color="success" onClick={() => handleMarkRead(user.uid, index)}>Mark Read</Button>
                </React.Fragment>
            )
        }
        else if(notificationType === "turnToRead"){
            return(
                <Button onClick={() => handleGoToPaath(paathId, user.uid, index)}>Go To Paath</Button>
            )
        }
    }

    const handleAccept = (paathId, userId, index) => {
    
        addPaathToParticipantProfile(paathId, userId);
        updatePaathParticipantInvite(paathId, userId);
        udpateNotificationRead(userId, index);
        updateUserPaathArrayInContext(userId);

    }

    const handleDecline = (paathId, userId, index) => {
        if(window.confirm("Decline Paath Invite?")){
            udpateNotificationRead(userId, index);
            declinePaathParticipantInvite(paathId, userId);
        }
    }

    const handleMarkRead = (userId, index) => {
        udpateNotificationRead(userId, index);
    }

    const handleGoToPaath = (paathId, userId, index) => {
        udpateNotificationRead(userId, index);
        history.push(`/paaths/${paathId}`);
    }

    // This will take all the notifications, update just the one that needs to be updated.
    // then write all the notifications back to the cloud firestore.
    // This is because there is no way to update a specific array item in the cloud firestore
    // so the whole array needs to be copied, updated and sent back.
    const udpateNotificationRead = (userId, index) => {
        const updatedNotificationArray = notifications.userNotification;
        const updatedNotification = updatedNotificationArray[index];
        updatedNotification.read = true;
        updatedNotificationArray[index] = updatedNotification;
        //console.log(updatedNotificationArray);
        firestore.collection("notifications").doc(userId).update({
            userNotification:  updatedNotificationArray
        })
    }

    const deleteNotification = (userId, index) => {
        //alert("Delete" + index + userId);
        const notificationArray = notifications.userNotification;
        notificationArray.splice(index, 1);
        
        //console.log(notificationArray);
         firestore.collection("notifications").doc(userId).update({
             userNotification:  notificationArray
         })
    }

    // Updates user paath array in context with updated array.
    // This ensures the new paath is fetched when the user goes to the paath page.
    const updateUserPaathArrayInContext = (userId) => {
        firestore.collection("users").doc(userId).get().then((doc) => {
            let currentUser = doc.data();
            let updatedPaathIds = currentUser.paaths;
            let updatedUser = user;
            updatedUser.paaths = updatedPaathIds;
            //console.log(updatedUser);
            setUser(updatedUser);
        })
    }

    // Paath gets added to that users profile
     const addPaathToParticipantProfile = (paathId, userId) => {
        firestore.collection("users").doc(userId).update({
            paaths:  firebase.firestore.FieldValue.arrayUnion(paathId)
        })
     }

     // Paath confirmed participant array gets updated with the users ID.
     // This is then used to see if the user can read for the paath and
     // if the paath action button show up for that user.
     const updatePaathParticipantInvite = (paathId, userId) => {
        firestore.collection("paaths").doc(paathId).update({
            confirmedParticipants:  firebase.firestore.FieldValue.arrayUnion(userId)
        })
     }

     const declinePaathParticipantInvite = (paathId, userId) => {
        firestore.collection("paaths").doc(paathId).update({
            declinedParticipants:  firebase.firestore.FieldValue.arrayUnion(userId)
        })
     }


    return(
        <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h5" component="h1"> Notifications</Typography>
                    {/* <span>{numberNotifications.notificationToken}</span> */}
                </div>
            <Layout>
            <div className={classes.notificationsContainer}>
                {notifications?.userNotification?.length > 0 ? notifications?.userNotification?.map((notification, index) => {
                    return(
                        <Card key={index} className={classes.notificationDetailsCard} variant="outlined">
                            {notification.read ? "" : 
                                <div className={classes.noticationBadge}>
                                    <Badge color="secondary" badgeContent=" "/>
                                </div>
                            }
                            <CardContent>
                                <div className={classes.notificationContent}>
                                    <div>   
                                        <Typography>{format(notification.date.toDate(), "EEE MMM dd, yyyy")}</Typography>
                                        {notification.read ? 
                                            <Typography>{notification.paathCreatedBy === user?.uid ? "You Created a New Paath" : notification.title}</Typography> 
                                                : 
                                            <Typography className={classes.titleBold}>{notification.paathCreatedBy === user?.uid ? "You Created a New Paath" : notification.title}</Typography> 
                                        }
                                        <Typography>Paath Name: {notification.paathTitle}</Typography>
                                        <Typography>{notification.description}</Typography>
                                    </div>
                                    <div>
                                        <IconButton aria-label="delete" onClick={() => deleteNotification(user.uid, index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </CardContent>
                            { notification.read ? "" :    
                                <CardActions>
                                    {renderNotificationActions(notification.type, notification.paathId, notification.paathCreatedBy, index)}
                                </CardActions>
                            }
                        </Card>
                    )
                }) 
                : 
                    <Card className={classes.notificationDetailsCard} variant="outlined">
                        <CardContent>
                            <Typography className={classes.titleBold}>No Current Notifications</Typography> 
                        </CardContent>
                    </Card>
                }   
            </div>

            </Layout>
        </div>
    )
}

export default Notifications;