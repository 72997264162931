import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import firebase from "firebase/app";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CreateGroupForm from '../components/CreateGroupForm';
import GroupIcon from '@mui/icons-material/Group';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
    paddingBottom: theme.spacing(8),
  },
  paathDetailsCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: '10px',
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    marginBottom: '20px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  subTitle:{
    textAlign: 'center',
    color: theme.palette.primary.dark,
    margin: theme.spacing(2),
  },
  paathCardContainer: {
    marginBottom: theme.spacing(9),
  },
  forms: {
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
  },
  formControl: {
    minWidth: '100%',
    marginBottom: theme.spacing(3),
  },
  notificationOptions:{
    marginTop: theme.spacing(2),
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SavedGroups = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, setUser } = useContext(UserContext)
    const [userId, setUserId] = useState("");
    const [groupIds, setGroupdIds] = useState([]);
    const [savedGroups, setSavedGroups] = useState([]);
    const [createGroupView, setCreateGroupView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
     
      window.scrollTo(0,0);

      if(user){
        setUserId(user?.uid);
        setGroupdIds(user?.savedGroups);
        setTimeout(() => fetchUserSavedGroups(), 500);
      }

    }, [user, history])


    const fetchUserSavedGroups = () => {
        const fetchedGroup = [];
        if(user?.savedGroups.length > 0){
            user?.savedGroups.forEach((id) =>
              firestore.collection("savedGroups").doc(id).get().then((doc) => {
                  if(doc.exists){
                      setLoading(true);
                      let group = doc.data();
                      group.id = doc.id;
                      fetchedGroup.unshift(group);
                      setTimeout(() => setLoading(false), 500);
                  }
                  setSavedGroups(fetchedGroup);
              }
          ));
        }
        else{
          return;
        }
    }


    const handleViewChange = () => {
        setCreateGroupView(true);
    }

    const handleCancel = () => {
        if(window.confirm("Cancel creating group?")){
            setCreateGroupView(false);
        }
    }

    return(
        <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h5" component="h1"> Saved Groups </Typography>
                </div>
            <Layout>
            
           {!createGroupView ?  
           
            <Card className={classes.forms} variant="outlined">
                 <Typography className={classes.subTitle} variant="p" component="p">
                  With saved groups, you can create a group of paathis that you're always adding to your paaths.
                </Typography>
                <Divider/>
              <CardContent>
                <List>  
                    {!loading && savedGroups?.length > 0 ? 
                    
                        savedGroups?.map((group) => {
                            return(
                                <ListItemButton key={group.groupName}>
                                    <ListItemIcon> <GroupIcon /> </ListItemIcon>
                                    <ListItemText primary={group.groupName} secondary={group.users.map((user) => "@" + user.id + " ")}/>
                                </ListItemButton>
                            )
                        })
                        : 
                        <ListItem>
                          {loading ? "Loading" : <ListItemText primary={"No Saved Groups"} />}
                        </ListItem>
                    }
                </List>
                </CardContent>

                <Divider/>
                <CardActions>
                    <Button variant="contained" className={classes.saveButton} onClick={handleViewChange}>
                        Create New Group
                    </Button>
                    <Button onClick={() => fetchUserSavedGroups()}>Refresh</Button>
                </CardActions>
              </Card>

                :

              <CreateGroupForm handleCancelCreate={handleCancel} setCreateGroupView={setCreateGroupView} setRefetch={setRefetch}/>
            }
                       
            </Layout>
        </div>
    )
}

export default SavedGroups;