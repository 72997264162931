import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore, functions } from '../config/firebase-config';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UserProvider, { UserContext } from '../components/UserProvider';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
//import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Stack from '@mui/material/Stack';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';




const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
    header:{
      textAlign: 'center',
      marginBottom: 20,
    },
    pageHeader: {
        display: 'flex',
        marginTop: 54,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: 100,
        backgroundColor: theme.palette.primary.light,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    forms:{
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.backgroundCard,
    },
    formControl: {
        minWidth: '100%',
        marginBottom: theme.spacing(3),
    },
    dateField: {
        minWidth: '100%',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
      marginTop: '80px',
    },
    paathSection: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginTop: theme.spacing(2),
    },
    paathOptions:{
        marginTop: theme.spacing(2),
    },
    autoComplete: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    userNameSearch: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    addGroupButton: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    selectedUserSearch: {
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    selectedUsers: {
        marginTop: theme.spacing(2),
    },
    selectedUsersList: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
      },
      ul: {
        backgroundColor: 'inherit',
        padding: 0,
      },
    bottomActionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    cancelButton: {
        color: theme.palette.error.main,
    },
    endPaathButton: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.error.main,
    }
}));



const steps = ['Start', 'Paathis', 'Finish'];

const EditPaathForm = ({id, CreatedBy, PaathTitle, PaathType, PublicPaath, StartDate, FinishDate, UserName, SelectedUsers, ConfirmedParticipants, DeclinedParticipants, Options, handleEditCancel, setEditView}) => {

    const classes = useStyles();
    //const theme = useTheme();
    const history = useHistory();
    const {user, setUser} = useContext(UserContext);
    //const functions = getFunctions();
    const sendPushNotifications = functions.httpsCallable('sendPushNotifications');

    const [value, setValue] = useState(0);
    const [paathTitle, setPaathTitle] = useState(PaathTitle);
    const [paathType, setPaathType] = useState(PaathType);
    const [publicPaath, setPublicPaath] = useState(false);
    const [startDate, setStartDate] = useState(dayjs(StartDate.toDate()));
    const [finishDate, setFinishDate] = useState(dayjs(FinishDate.toDate()));
    const [participants, setParticipants] = useState([]);
    const [userNameSearchOpen, setUserNameSearchOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [usersFound, setUsersFound] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newlyAddedUsers, setNewlyAddedUsers] = useState([]);
    const [removedUsers, setRemovedUsers] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addingPaath, setAddingPaath] = useState(false);
    const [paathAdded, setPaathAdded] = useState(false);
    const [error, setError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    useEffect(() => {

        let foundUser = {displayName: '', userName: '', id: ''};
        if(userName !== ''){
           
            firestore.collection("usernames").doc(userName).get().then((doc) => {
                if(doc.data() === undefined){
                    return;
                }
                else{
                    foundUser = doc.data();
                    foundUser.id = doc.id;
                    //console.log(foundUser);
                    setOptions([foundUser]);
                }
            })
        }

    }, [loading, userName])

    useEffect(() => {
        
        confirmedSelectedUsers();

    }, [])

    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1); 
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    
    const handleReset = () => {
        setActiveStep(0);
    };
  
    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const handleChange = (event) => {
        if(event.target.name === 'paath-title'){
            setPaathTitle(event.target.value);
        }
        else if(event.target.name === 'paath-type'){
            setPaathType(event.target.value);
        }
        else if(event.target.name === 'public-paath'){
            setPublicPaath(event.target.checked);
        }
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleFinishDateChange = (date) => {
        setFinishDate(date);
    };

    const handleCancelCreate = () => {
        if(window.confirm("Are you sure you want to cancel creating this Paath?")){
            history.push('/paaths');
        }
        else{
            return;
        }
    }

    const savePaathUpdates = (event) => {
        event.preventDefault();
        if (paathTitle === "") {
            alert("Please Complete Paath Title");
        }
        else if (paathType === ""){
            alert("Please Select The Paath Type");
        }
        else if(startDate === null || finishDate === null){
            alert("Please Select Paath Start and Finish Dates");
        }
        else if(startDate.toDate() > finishDate.toDate()){
            alert("The Paath Finish Date Cannot Be Before The Paath Start Date");
        } else {
            setAddingPaath(true);
            const updatedUsers = [...selectedUsers, ...newlyAddedUsers];
            //console.log(updatedUsers);
            firestore.collection("paaths").doc(id).update({
                // createdBy: user.uid,
                // createdByDisplayName: user.displayName,
                // createdByUserName: user.userName,
                // createdAt: new Date(),
                paathTitle,
                //paathType,
                //paathStatus: 'toStart',
                startDate: startDate.toDate(),
                finishDate: finishDate.toDate(),
                publicPaath: publicPaath,
                //progress: {currentPage: 1, currentReader: '', lineNumberMarker: 1, verseNumberMarker: 1, readingStatus: 'toStart'},
                participants: updatedUsers,
                //confirmedParticipants: [user.uid],
                //declinedParticipants: [],
            }).then(() => {
                //addPaathToUserProfile(docRef.id);
                //addPaathToParticipantProfile(id);
                removePaathFromParticipantProfile(id);
                removeConfirmedParticipants(id);
                notifyParticipants(id);
                sendEmailToParticipants(id);
                //sendPushNotificationToParticipants(docRef.id);
                setPaathAdded(true);
            }).then(() => {
                // setPaathTitle("");
                // setStartDate(new Date());
                // setFinishDate(new Date());
                // setPaathType("");
                // setParticipants([""]);
                //setProgramAdded(true);
                setTimeout(() => setAddingPaath(false), 1000);
                setEditView(false);
            }).catch((error) => {
                setAddingPaath(false);
                console.error("Error writing document: ", error);
                setError("Error adding paath: ", error);
            });
        }
    }

    const endPaath = (event) => {
        event.preventDefault();
        if (window.confirm("Are you sure you would like to delete this paath? All progress will be deleted and the paath cannot be restored.")){
            firestore.collection("paaths").doc(id).delete().then(() => {
                history.push('/paaths');
            }).catch((error) => {
                console.error("Error Removing Paath: ", error);
            })
        }
        else{
            return;
        }
    }

    // Add's paath to user's profile
    const addPaathToUserProfile = (id) => {
        const currentPaaths = user.paaths;
        const userDocRef = firestore.collection("users").doc(user.uid);
        userDocRef.update({
            paaths: [...currentPaaths, id]
        }).then(() => { 
           return userDocRef.get(); 
        }).then((doc) => setUser(doc.data()))
        .catch((error) => {
            console.error("Error updating document: ", error);
                setError("Error adding program: ", error);
        });
    }

    // Add's the paath to each one of the new participants profile
    // So this paath shows up in their Ongoing Paaths Tab
    const addPaathToParticipantProfile = (id) => {
        newlyAddedUsers.forEach((user) => {
            firestore.collection("users").doc(user.userId).update({
              paaths:  firebase.firestore.FieldValue.arrayUnion(id)
            })
        })
    } // CHANGE THIS FUNCTION so that the paath does not get added to the user's profile until they accept the invite.

    // Removes the paath from the removed users profiles
    // so that it does not show up in their paaths list.
    const removePaathFromParticipantProfile = (id) => {
        removedUsers.forEach((user) => {
            firestore.collection("users").doc(user.userId).update({
              paaths:  firebase.firestore.FieldValue.arrayRemove(id)
            })
        })
    }

    // Removes participants from the confirmed participants array of the paath.
    const removeConfirmedParticipants = (id) => {
        removedUsers.forEach((user) => {
            firestore.collection("paaths").doc(id).update({
              confirmedParticipants:  firebase.firestore.FieldValue.arrayRemove(user.userId)
            })
        })
    }

    // Updates each participants notifications collection with a message they've been added to a paath.
    // The participant then has to accept or decline the paath invitation for the paath to show up
    // for that user.
    const notifyParticipants = (id) => {
        const paathCreatedBy = user.uid;
        newlyAddedUsers.forEach((user) => {
            firestore.collection("notifications").doc(user.userId).update({
              userNotification:  firebase.firestore.FieldValue.arrayUnion({
                  date: new Date(),
                  description: "",
                  paathCreatedBy: paathCreatedBy,
                  paathId: id,
                  paathTitle,
                  read: false,
                  title: "You've Been Added To a Paath",
                  type: "addedToPaath"
                })
            })
        })
    }

    // This will call the FCM cloud function and send a push notification
    // to each participant.
    const sendPushNotificationToParticipants = (id) => {
         const selectedUserIds = [];
         selectedUsers.filter((user) => {  
            let id = user.userId;
            selectedUserIds.push(id);
            return selectedUserIds;
         })
         //console.log(selectedUserIds);
         const data = {paathId: id, paathTitle: paathTitle , userIds: selectedUserIds, message: "You've Been Added To a New Paath"};
         const context = user.uid;
         sendPushNotifications(data, context).then((result) => {
             console.log(result);
         }).catch((error) => {
             console.log(error);
         });
    }

     // Sends User Email that they've been added to a paath
   const sendEmailToParticipants = async (id) => {
    let userEmailAllowed;
    const selectedUserEmails = [];
    newlyAddedUsers.filter((user) => {  
        let email = user.email;
        selectedUserEmails.push(email);
        return selectedUserEmails;
     })
    //Gets the user document to check their email notificaiton preferences.
    //await firestore.collection('users').doc(userId).get().then((doc) => userEmailAllowed = doc.data().preferences.emailNotification);
    //Send the user a they've been added to a paath email if they have email notifications enabled.
     selectedUserEmails.forEach((email => {
        firestore.collection('mail').add({
            to: email,
            message: {
                subject: `MySehajPaath - You've Been Added To a New Paath - ${paathTitle}`,
                html: `<!doctype html>
                <html>
                  <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                    <title>Simple Transactional Email</title>
                    <style>
                @media only screen and (max-width: 620px) {
                  table.body h1 {
                    font-size: 28px !important;
                    margin-bottom: 10px !important;
                  }
                
                  table.body p,
                table.body ul,
                table.body ol,
                table.body td,
                table.body span,
                table.body a {
                    font-size: 16px !important;
                  }
                
                  table.body .wrapper,
                table.body .article {
                    padding: 10px !important;
                  }
                
                  table.body .content {
                    padding: 0 !important;
                  }
                
                  table.body .container {
                    padding: 0 !important;
                    width: 100% !important;
                  }
                
                  table.body .main {
                    border-left-width: 0 !important;
                    border-radius: 0 !important;
                    border-right-width: 0 !important;
                  }
                
                  table.body .btn table {
                    width: 100% !important;
                  }
                
                  table.body .btn a {
                    width: 100% !important;
                  }
                
                  table.body .img-responsive {
                    height: auto !important;
                    max-width: 100% !important;
                    width: auto !important;
                  }
                }
                @media all {
                  .ExternalClass {
                    width: 100%;
                  }
                
                  .ExternalClass,
                .ExternalClass p,
                .ExternalClass span,
                .ExternalClass font,
                .ExternalClass td,
                .ExternalClass div {
                    line-height: 100%;
                  }
                
                  .apple-link a {
                    color: inherit !important;
                    font-family: inherit !important;
                    font-size: inherit !important;
                    font-weight: inherit !important;
                    line-height: inherit !important;
                    text-decoration: none !important;
                  }
                
                  #MessageViewBody a {
                    color: inherit;
                    text-decoration: none;
                    font-size: inherit;
                    font-family: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                  }
                
                  .btn-primary table td:hover {
                    background-color: #34495e !important;
                  }
                
                  .btn-primary a:hover {
                    background-color: #34495e !important;
                    border-color: #34495e !important;
                  }
                }
                </style>
                  </head>
                  <body style="background-color: #f6f6f6; text-align: center; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                      <tr>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                        <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                          <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
                
                            <!-- START CENTERED WHITE CONTAINER -->
                            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
                
                              <!-- START MAIN CONTENT AREA -->
                              <tr>
                                <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                  <img src="http://mysehajpaath.com/wp-content/uploads/2022/03/MSP-Final-Gold-300.png" style="width: 100px; height: auto; margin-bottom: 15px" alt="My Sehaj Paath Logo"/>   
                                  <tr>
                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                        <p style="font-family: sans-serif; font-size: 20px; font-weight: bold; margin: 0; margin-bottom: 15px;">You've Been Added To A New Paath</p>
                                        <p style="font-family: sans-serif; font-size: 18px; font-weight: normal; margin: 0; margin-bottom: 15px;">Please Sign In To The MySehajPaath App and Accept the Paath Invite From Your Notifications.</p>
                                        <p style="font-family: sans-serif; font-size: 18px; font-weight: normal; margin: 0; margin-bottom: 20px;">Paath Title: ${paathTitle}</p>
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                          <tbody>
                                            <tr>
                                              <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                                  <tbody>
                                                    <tr>
                                                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> 
                                                        <a href="https://app.mysehajpaath.com/paaths/${id}" target="_blank" style="border: solid 1px #5A8087; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 10px 20px; text-decoration: none; text-transform: capitalize; background-color: #5A8087; border-color: #5A8087; color: #ffffff;">View Paath</a> 
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                
                            <!-- END MAIN CONTENT AREA -->
                            </table>
                            <!-- END CENTERED WHITE CONTAINER -->
                
                            <!-- START FOOTER -->
                            <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                <tr>
                                  <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                    <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">MySehajPaath</span>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <!-- END FOOTER -->
                
                          </div>
                        </td>
                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                      </tr>
                    </table>
                  </body>
                </html>`,
            },
        })
     }))
    }

    // This function takes the participants array of objects from props
    // and removes the declined participants from it and sets the 
    // new array as the selectedUsers array which includes confirmed and
    //pending participants. This has to be done
    // becaue the standard particiapnts array shows all users even
    // the ones which have declined.
    const confirmedSelectedUsers = () => {
        let allUsers = SelectedUsers;
        let confirmed = ConfirmedParticipants;
        let declined = DeclinedParticipants;
        let confirmedUsersOnly = allUsers.filter(user => confirmed.includes(user.userId));
        let pendingUsersOnly = allUsers.filter(user => !confirmed.includes(user.userId) && !declined.includes(user.userId));
        let confirmedAndPendingUsers = [...confirmedUsersOnly, ...pendingUsersOnly];
        setSelectedUsers(confirmedAndPendingUsers);
    }

    const handleAddUser = (user, index) => {
        let updatedUser = user;
        //checks to see if the user already exists.
        let array1= selectedUsers.filter(existingUser => {
            if(existingUser.userId === updatedUser.userId){
                //console.log("exists1");
                return true;
            }
        });
        //checks to see if the user exists in the newly added user array.
        let array2 = newlyAddedUsers.filter(existingUser => {
            if(existingUser.userId === updatedUser.userId){
                //console.log("exists2");
                return true;
            }
        });

        // Checks to see if the user has already been added to the paath.
        // Stops the user from being added twice.
        if(array1.length > 0 || array2.length > 0){
            alert("User already added.");
        }
        else{
            setNewlyAddedUsers([...newlyAddedUsers, updatedUser]);
        }
            
    }

    const handleRemoveUser = (index) => {
        if(window.confirm("Delete Participant?")){
            // Removes user from selected users array
            // Also adds removed users to a seperate array so that
            // this particular paath can be removed from those specific users profile.
            const removedUserList = removedUsers;
            const updatedUserList = selectedUsers.filter((_, i) => i !== index);
            const removedUser = selectedUsers.filter((_, i) => i === index);
            removedUserList.push(removedUser[0]);
            setSelectedUsers(updatedUserList);
            setRemovedUsers(removedUserList);
        }
    }

    const handleRemoveNewUser = (index) => {
        if(window.confirm("Delete Participant?")){
            const updatedNewUserList = newlyAddedUsers.filter((_, i) => i !== index);
            setNewlyAddedUsers(updatedNewUserList);
        }
    }

    
    const renderStepForm = (activeStep) => {
        if(activeStep === 0){
            return(
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                    <TextField onChange={handleChange} value={paathTitle} variant="outlined" margin="normal" fullWidth id="paath-title" label="Paath Title" name="paath-title" />
                    {/* Paath Type */}
                    <FormControl variant="outlined" margin="normal" disabled className={classes.formControl}>
                        <InputLabel id="paath-type-label">Paath Type</InputLabel>
                        <Select labelId="paath-type" id="paath-type" name="paath-type" value={paathType} onChange={handleChange} label="Paath Type" >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'sehaj'}>Sehaj Paath</MenuItem>
                        </Select>
                    </FormControl>
                    <Stack spacing={3}>
                    {/* Paath Start Date */}
                        <MobileDatePicker id="start-date" label="Start Date" margin="normal" value={startDate} onChange={handleStartDateChange} className={classes.dateField} renderInput={(params) => <TextField {...params} />} />
                        {/* Paath End Date */}
                        <MobileDatePicker id="finish-date" label="Finish Date" margin="normal" value={finishDate} onChange={handleFinishDateChange} className={classes.dateField} renderInput={(params) => <TextField {...params} />} />
                    </Stack>
                    {/* Public Paath */}
                    <div className={classes.paathOptions}>
                       {/* <FormControlLabel control={ <Switch checked={publicPaath} onChange={handleChange} name="public-paath" color="primary" /> } label="Public Paath"/> */}
                    </div> 
                    <Button variant="contained" className={classes.endPaathButton} onClick={endPaath}>Delete Paath</Button>
                    </CardContent>
                </Card>
            )
        }
        else if(activeStep === 1){
            return(
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                    <TextField label="Search Username to Add" variant="outlined" value={userName} className={classes.userNameSearch} onChange={(event) => setUserName(event.target.value)} 
                    InputProps={{ endAdornment: ( <React.Fragment> {loading ? <CircularProgress color="inherit" size={20} /> : null} </React.Fragment> ), }} />
                        <List dense className={classes.selectedUserSearch}>
                            <Divider />

                                {options ? options.map((user, index) => {

                                return(
                                    <ListItem button key={index} onClick={() => handleAddUser(user, index)}>
                                        <ListItemAvatar> <Avatar> </Avatar> </ListItemAvatar>
                                        <ListItemText primary={user.displayName} secondary={user.userName} />
                                        {/* <ListItemSecondaryAction> <IconButton edge="end" aria-label="delete"> <DeleteIcon /> </IconButton> </ListItemSecondaryAction> */}
                                    </ListItem>
                                    )
                                }) : ''}

                            <Divider />
                        </List>

                        <div className={classes.addGroupButton}>
                            <Button variant="outlined">Add Paathis From Saved Group</Button>
                        </div>

                        <div className={classes.selectedUsers}>
                            <List className={classes.selectedUsersList} subheader={<li />}>

                            <li className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        <ListSubheader>New Participants</ListSubheader>
                                        {newlyAddedUsers.length > 0 ? newlyAddedUsers.map((user, index) => (
                                            <ListItem key={user?.displayName}>
                                                <ListItemText primary={user?.displayName} secondary={user?.id}/>
                                                <ListItemSecondaryAction> 
                                                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveNewUser(index)} size="large"> <DeleteIcon /> </IconButton> 
                                                </ListItemSecondaryAction> 
                                            </ListItem>
                                        )) : 
                                        <ListItem>
                                            <ListItemText primary={"No New Users Selected"} />
                                        </ListItem>}
                                    </ul>
                                </li>
                                <Divider />    
                                <li className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        <ListSubheader>Current Participants</ListSubheader>
                                        {selectedUsers.map((user, index) => (
                                            <ListItem key={user?.displayName}>
                                                <ListItemText primary={user?.displayName} secondary={user?.id}/>
                                                <ListItemSecondaryAction> 
                                                    { CreatedBy === user?.userId ? "Admin" : <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(index)} size="large"> <DeleteIcon /> </IconButton> }
                                                </ListItemSecondaryAction> 
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                                         
                            </List>
                        </div>
                    </CardContent>
                </Card>
            )
        }
        else if(activeStep === 2){
            return(
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Paath Details
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Paath Title
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {paathTitle ? paathTitle : "No Title Set"}
                        </Typography>
                    
                        <Typography variant="body2" color="textSecondary" component="p">
                            Paath Type
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {paathType ? paathType : "Please Select Paath Type"}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Start Date
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {startDate.toString()}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Finish Date
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {finishDate.toString()}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Public Paath
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {publicPaath ? "This Paath is Public" : "This Paath is Private"}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Paathis
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {
                            selectedUsers.length === 0 ? "No Paathis Added" 
                            : 
                            selectedUsers.map((user, index) => (
                                <div> 
                                <Typography key={index} variant="h6" color="textSecondary" component="p" gutterBottom>
                                    {user?.displayName} 
                                </Typography>
                                </div>
                            ))
                            }
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {
                            newlyAddedUsers.length === 0 ? "" 
                            : 
                            newlyAddedUsers.map((user, index) => (
                                <div> 
                                <Typography key={index} variant="h6" color="textSecondary" component="p" gutterBottom>
                                    {user?.displayName} 
                                </Typography>
                                </div>
                            ))
                            }
                        </Typography>
                    </CardContent>     
                </Card>
            )
        }
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            
             <Box sx={{ width: '100%', paddingTop: 5, paddingBottom: 10 }}>

                 <Stepper activeStep={activeStep}>
                     {steps.map((label, index) => {
                     const stepProps = {};
                     const labelProps = {};
                     return (
                         <Step key={label} {...stepProps}>
                         <StepLabel {...labelProps}>{label}</StepLabel>
                         </Step>
                     );
                     })}
                 </Stepper>

                <React.Fragment>
                    {/* Main From Rendered*/}    
                    {renderStepForm(activeStep)}
                
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button className={classes.cancelButton} onClick={handleEditCancel} sx={{ mr: 1 }} >
                            Cancel
                        </Button>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} >
                            Back
                        </Button>

                        <Box sx={{ flex: '1 1 auto' }} />
                
                        {activeStep === steps.length - 1 ? 
                        
                        <LoadingButton onClick={savePaathUpdates} loading={addingPaath} loadingIndicator="Saving..." variant="outlined" >
                         Save Changes
                        </LoadingButton>
                        : 
                        <Button onClick={handleNext}>
                            Next
                        </Button>
                        }
                    </Box>
                </React.Fragment>
                
             </Box>   
           
            </LocalizationProvider>
    );
}

export default EditPaathForm;