import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import { UserContext } from '../components/UserProvider';
import makeStyles from '@mui/styles/makeStyles';
import StylesProvider from '@mui/styles/StylesProvider';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import gql from 'graphql-tag';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LinearProgress from '@mui/material/LinearProgress';
import { BookmarkBorderOutlined, TurnedInNotRounded } from '@mui/icons-material';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
    paathContainer: {
      marginTop: '70px',
      marginBottom: '130px',
    },
    paginationContainer: {
      background: '#f2f2f2',
      position: 'fixed',
      bottom: 70,
      right: 0,
      padding: 5,
      maxWidth: '100%',
    },
    card: {
      minWidth: '275',
      marginBottom: '5px',
      backgroundColor: theme.palette.primary.backgroundCard,
      borderRadius: '10px',
    },
    title: {
      fontSize: 14,
    },
    text: {
      color: theme.palette.primary.darkText,
    },
    verseNumber: {
      textAlign: 'right',
    },
    pos: {
      marginBottom: 12,
    },
  }));


const PaathVerseDisplay = ({index, serverPage, verseId, lineNo, unicode, transliteration, translation, bookmarkProgress, previousBookmarkPage, previousBookmarkVerse, readingStatus, bookmarkRef, showReadingOverlay}) => {

    const classes = useStyles();
    const [lineBookMarked, setLineBookMarked] = useState(false);

   
    const bookMark = () => {
        if(readingStatus === "toStartRead"){
            alert("Please start reading before bookmarking.")
        }
        else{
            setLineBookMarked(true);
            bookmarkProgress(serverPage, verseId, lineNo);
        }
    }

    const removeBookMark = () => {
        setLineBookMarked(false);
        bookmarkProgress(serverPage, "", "");
    }

    const renderPreviousBookmarkChip = (page, verse, prevPage, prevVerse) => {
        if(page === parseInt(prevPage) && verse === parseInt(prevVerse)){
            return <Chip ref={bookmarkRef} label="Last Bookmark" color="secondary" />;
        }
       else{
           return ""
       }
    }

    return (
        <Card key={index} className={classes.card} variant="outlined">
            <CardContent>
              {renderPreviousBookmarkChip(serverPage, verseId, previousBookmarkPage, previousBookmarkVerse)}
              <Typography className={classes.verseNumber} color="textSecondary" gutterBottom>
              Page: {serverPage} Verse: {verseId}  Line: {lineNo} 
              </Typography> 
              <Typography variant="h6" component="h2" className={classes.text}>
                  {unicode}
              </Typography>
              <Typography className={classes.pos} color="textSecondary">
                  {transliteration}
              </Typography>
              <Typography variant="body2" component="p">
                  {translation}
              </Typography>
            </CardContent>
            <CardActions>
              {showReadingOverlay ? "" : 
                <IconButton aria-label="bookmark" size="large">
                    {lineBookMarked ? <TurnedInIcon onClick={removeBookMark}/> : <TurnedInNotIcon onClick={bookMark}/> }
                </IconButton>
              }
            </CardActions>
        </Card>
    );

}

export default PaathVerseDisplay;