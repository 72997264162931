import React, { useState, useEffect, useRef, useContext } from 'react';
import Layout from '../components/Layout';
import { UserContext } from '../components/UserProvider';
import makeStyles from '@mui/styles/makeStyles';
import StylesProvider from '@mui/styles/StylesProvider';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { firestore, functions } from '../config/firebase-config';
import gql from 'graphql-tag';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LinearProgress from '@mui/material/LinearProgress';
import { CenterFocusStrong, TurnedInNotRounded } from '@mui/icons-material';
import PaathVerseDisplay from '../components/PaathVerseDisplay';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReadingModalImage from '../images/reading-modal-image.png';
import { Capacitor } from '@capacitor/core';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    top: 64,
    justifyContent: 'center',
    alignContent: 'center',   
    height: 20,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 100,
  },
  pageHeaderReadingStarted: {
    display: 'flex',
    flexDirection: 'row',
    top: 64,
    justifyContent: 'center',
    alignContent: 'center',   
    height: 20,
    backgroundColor: theme.palette.secondary.light,
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 100,
  },
  pageHeaderIOS: {
    display: 'flex',
    flexDirection: 'row',
    top: 104,
    justifyContent: 'center',
    alignContent: 'center',   
    height: 20,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 100,
  },
  pageHeaderReadingStartedIOS: {
    display: 'flex',
    flexDirection: 'row',
    top: 104,
    justifyContent: 'center',
    alignContent: 'center',   
    height: 20,
    backgroundColor: theme.palette.secondary.light,
    textAlign: 'center',
    padding: theme.spacing(2),
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    width: '100%',
    zIndex: 100,
  },
  headerStatus:{
    marginTop: 5,
    fontWeight: 700,
  },
  backButton:{
    marginTop: 5,
    marginLeft: theme.spacing(2),
  },
  startReadingOverlay:{
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.7)',
    position: 'fixed',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  startReadingInfoCard:{
    zIndex: 100,
    textAlign: 'center',
    maxWidth: '90%',
  },
  readingModalImage:{
    width: '150px',
    height: 'auto',
  },
  startReadingMessage:{
    marginTop: -35,
    fontSize: '24px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  paathContainer: {
    paddingTop: theme.spacing(6),
    marginTop: '100px',
    marginBottom: '130px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.backgroundCard,
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    bottom: 70,
    //right: 0,
    padding: 5,
    width: '95%',
    maxWidth: '95%',
    borderRadius: '10px',
  },
  card: {
    minWidth: '275',
    marginBottom: '5px',
    backgroundColor: theme.palette.primary.backgroundCard,
  },
  title: {
    fontSize: 14,
  },
  text: {
    color: theme.palette.primary.darkText,
  },
  verseNumber: {
    textAlign: 'right',
  },
  pos: {
    marginBottom: 12,
  },
  startButton: {
    backgroundColor: theme.palette.secondary.main,
    //color: theme.palette.secondary.dark,
  },
  pauseButton: {
    //backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.dark,
  },
  saveButton: {
    marginLeft: 5,
  },
  saveButtonActive: {
    marginLeft: 5,
    backgroundColor: theme.palette.secondary.main,
    //color: theme.palette.secondary.dark,
  },
  pageButtonContainer: {
    marginLeft: 0,
  },
  pageTurnButton: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: 5,
  },
  pageNumber: {
    fontSize: '18px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: 3,
  },
  guidelinesTitle:{
    fontSize: '20px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  guidelinesText:{
    marginBottom: theme.spacing(2),
  },
  backPaathButton:{
    width: '35%',
  },
  passButton:{
   width: '65%',
  }
}));

const GET_PAATH = gql `
  query($pageNo: Int) {
    PaathPageSearchQuery(input: $pageNo) {
      _id
      count
      source{
        pageNo
      }
      navigation{
        previous
        next
      }
      page{
        lineNo
        verseId
        verse{
          unicode
        }
        translation{
          en{
            bdb
          }
        }
        transliteration{
          english
        }
      }
    }
  }
`


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ReadPaath = () => {

    const classes = useStyles();
    const [paathData, setPaathData] = useState([]);
    const [paathDetails, setPaathDetails] = useState({});
    const [serverPage, setServerPage] = useState(null);
    const [progress, setProgress] = useState(10);
    const [paathDetailsLoading, setPaathDetailsLoading] = useState(false);
    const { paathId, page, verse } = useParams();
    const {user, setUser} = useContext(UserContext);
    const [loggedInUserId, setLoggedInUserId] = useState("");
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(page);
    const [currentVerse, setCurrentVerse] = useState(verse);
    const [bookmarkPage, setBookmarkPage] = useState('');
    const [bookmarkVerse, setBookmarkVerse] = useState('');
    const [bookmarkLine, setBookmarkLine] = useState('');
    const [firstReadStarted, setFirstReadStarted] = useState(false);
    const [savingProgress, setSavingProgress] = useState(false);
    const [progressSaved, setProgressSaved] = useState(false);
    const [progressWasSaved, setProgressWasSaved] = useState(false);
    const bookmarkRef = useRef(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [pageSearchOpen, setPageSearchOpen] = useState(false);
    const [pageSearchNumber, setPageSearchNumber] = useState('');
    const [showReadingOverlay, setShowReadingOverlay] = useState(true);
    const [openReadingGuidelines, setOpenReadingGuidelines] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const [passingPaath, setPassingPaath] = useState(false);
    const sendPushNotificationTurnToRead = functions.httpsCallable('sendPushNotificationTurnToRead');

    const { loading, error, data } = useQuery(GET_PAATH, {variables:{pageNo: page}}); //the page number will come from query params
  

    useEffect(() => {

      //window.scrollTo(0,0);
      setTimeout(() => bookmarkRef?.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"}), 2000);   


      if(data){
        setPaathData(data.PaathPageSearchQuery[0].page);
        setServerPage(data.PaathPageSearchQuery[0].source.pageNo);
      }

      if(currentPage !== parseInt(page)){
        history.push(`/read-paath/${paathId}/${currentPage}/${currentVerse}`)
      }

      setLoggedInUserId(user?.uid);

      // if(currentPage === 1 && currentPage < parseInt(page)){
      //   setCurrentPage(page);
      // }

      // if(currentPage < parseInt(page)){
      //   history.push(`/read-paath/${paathId}/${currentPage}/1`)
      // }

      const unsubscribe = firestore.collection("paaths").doc(paathId).onSnapshot(doc => {

        setPaathDetailsLoading(true);
        const paath = doc.data();
        paath.id = doc.id;
        setPaathDetails(paath);
        setPaathDetailsLoading(false);

    })

    return () => {
        unsubscribe()
    }

    }, [data, currentPage])



    const handlePageSearchChange = () => {
      const newPageNumber = pageSearchNumber;
      if(newPageNumber > 1430){
        alert("Please enter a page number between 1 - 1430.");
      }else{
        setCurrentPage(newPageNumber);
        setPageSearchNumber('');
        setPageSearchOpen(false);
        window.scrollTo(0,0);
      }
    };

    const handlePageSearchClose = () => {
      setPageSearchOpen(false);
    }

    const handleReadingGuidelinesClose = () => {
      setOpenReadingGuidelines(false);
    }

    const handleNextPage = () => {
      const currentPg = parseInt(currentPage);
      if(currentPg === 1430){
        alert("This is the last ang of the Guru Granth Sahib Ji. You have completed your reading. Please bookmark the last verse and press save.");
      }
      else{
        setCurrentPage(currentPg + 1);
        window.scrollTo(0,0);
      }
    }

    const handlePrevPage = () => {
      const currentPg = parseInt(currentPage);
      if(currentPg === 1){
        setCurrentPage(1);
      }
      else{
        setCurrentPage(currentPg - 1);
      }
    }

    // Updates each participants notifications collection with a message they've been added to a paath.
    // The participant then has to accept or decline the paath invitation for the paath to show up
    // for that user.
    const notifyParticipantTurnToRead = (userId, paathId) => {
      const paathCreatedBy = user.uid;
      firestore.collection("notifications").doc(userId).update({
          userNotification:  firebase.firestore.FieldValue.arrayUnion({
              date: new Date(),
              description: "",
              paathCreatedBy: paathCreatedBy,
              paathId: paathId,
              paathTitle: paathDetails.paathTitle,
              read: false,
              title: "It's Your Turn To Read",
              type: "turnToRead"
          })
      })
      // Add Cloud Notification here.
  }

    const sendPushNotificationToParticipant = (userId, paathId) => {
      const data = {paathId: paathId, paathTitle: paathDetails.paathTitle , userId: userId, message: "It's Your Turn to Read Paath"};
      const context = user.uid;
      sendPushNotificationTurnToRead(data, context).then((result) => {
          console.log(result);
      }).catch((error) => {
          console.log(error);
      });
    }


    const passPaath = () => {
      if(window.confirm("Pass Paath To Next Reader?")){
          setPassingPaath(true);
          const currentReader = paathDetails.progress.currentReader;
          const currentReaderIndex = paathDetails.confirmedParticipants.indexOf(currentReader);
          // This was the old way to get the Index from the main participant array as it was an array of objects.
          // Leaving this here for future reference.
          //const currentReaderIndex = paathDetails.confirmedParticipants.map((reader) => { return reader.userId; }).indexOf(currentReader);
          const numberParticipants = paathDetails.confirmedParticipants.length-1;
          //console.log(currentReader, currentReaderIndex, numberParticipants);
  
          // If the current reader is the last participant
          // sets the next reader as the first reader in the participant array.
           if(currentReaderIndex === numberParticipants){
                 firestore.collection("paaths").doc(paathId).update({
                 'progress.currentReader': paathDetails.confirmedParticipants[0],
               });
               //Notification Function Goes Here
               notifyParticipantTurnToRead(paathDetails.confirmedParticipants[0], paathId);
               sendPushNotificationToParticipant(paathDetails.confirmedParticipants[0], paathId);
               setTimeout(() => history.push(`/paaths/${paathId}`), 1000);
            }
            //Increments index to the next reader.
            else{
                firestore.collection("paaths").doc(paathId).update({
                    'progress.currentReader': paathDetails.confirmedParticipants[currentReaderIndex+1],
               });
               //Notification Function Goes Here
               notifyParticipantTurnToRead(paathDetails.confirmedParticipants[currentReaderIndex+1], paathId);
               sendPushNotificationToParticipant(paathDetails.confirmedParticipants[currentReaderIndex+1], paathId);
               setTimeout(() => history.push(`/paaths/${paathId}`), 1000);
            }
      }
      else{
          return;
      }
  }

  const backToPaathList = () => {
    if(progressWasSaved === false && firstReadStarted === true){
      alert("Please save your reading progress");
    }
    else if(progressWasSaved === false && firstReadStarted === false){
      history.push(`/paaths/${paathId}/`)
    }
    else if(progressWasSaved === true && firstReadStarted === false){
      history.push(`/paaths/${paathId}/`)
    }
    else{
      history.push(`/paaths/${paathId}/`)
    }
  }

    const startRead = () => {
      // If a participant is on the reading screen without having started to read
      // and the admin pauses the paath, this makes sure that the participant cant
      // start reading for the paath while its paused, even if they are on the reading screen.
      if(paathDetails.paathStatus === "paused"){
        alert("The Admin Has Paused the Paath. Please Wait For The Admin to Re-Start It.");
      }
      // If a participant is on the reading screen and not reading, and meanwhile
      // the admin passes the paath to the next reader, this ensure that the reader
      // can't start reading until it's their turn.
      else if(paathDetails.progress.currentReader !== loggedInUserId){
        alert("The Admin Has Passed the Paath to The Next Reader. Please Wait Until It's Your Turn To Read");
      }
      else{
        setShowReadingOverlay(false);
        setFirstReadStarted(true);
        firestore.collection("paaths").doc(paathId).update({
          'progress.readingStatus': 'readingStarted',
        });
      }
    }


    const pauseRead = () => {
      setShowReadingOverlay(true);
      firestore.collection("paaths").doc(paathId).update({
        'progress.readingStatus': 'toStartRead',
     });
    }


    const saveProgress = () => {
      if(bookmarkVerse === "" && bookmarkLine === ""){
        alert("Please Bookmark The Verse you completed reading.");
      }
      else{
        //alert(bookmarkPage + '' + bookmarkVerse + '' + bookmarkLine);
        setSavingProgress(true);
        firestore.collection("paaths").doc(paathId).update({
          'progress.readingStatus': 'toStartRead',
          'progress.currentPage': bookmarkPage,
          'progress.verseNumberMarker': bookmarkVerse,
          'progress.lineNumberMarker': bookmarkLine
       });
       setSavingProgress(false);
       setProgressSaved(true);
       setProgressWasSaved(true);
       setTimeout(() => setShowReadingOverlay(true), 1000);
      }
    }


    const bookmarkProgress = (page, verse, line) => {
      //alert(page + " " + verse + " " + line);
      setBookmarkPage(page);
      setBookmarkVerse(verse);
      setBookmarkLine(line);
    }


    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setProgressSaved(false);
    };

    const renderHeader = (readingStatus) => {

      if(readingStatus === "toStartRead" && Capacitor.getPlatform() !== 'ios'){
        return (
          <div className={classes.pageHeader}>
             {/* <IconButton size="small" aria-label="close" color="inherit" className={classes.backButton} onClick={() => history.push(`/paaths/${paathId}/`)}>
              <ArrowBackIcon />
            </IconButton> */}
            <Typography className={classes.headerStatus}>Please Press Start Button To Begin Reading</Typography>
          </div>
        );
      }
      else if(readingStatus === "readingStarted" && Capacitor.getPlatform() !== 'ios'){
        return (
          <div className={classes.pageHeaderReadingStarted}>
            {/* <IconButton size="small" aria-label="back" color="inherit" className={classes.backButton} onClick={() => window.alert("Please Save Reading.")}>
              <ArrowBackIcon />
            </IconButton> */}
            <Typography className={classes.headerStatus}>Reading Started</Typography>
          </div>
        );
      }
      else if(readingStatus === "toStartRead" && Capacitor.getPlatform() === 'ios'){
        return (
          <div className={classes.pageHeaderIOS}>
             {/* <IconButton size="small" aria-label="close" color="inherit" className={classes.backButton} onClick={() => history.push(`/paaths/${paathId}/`)}>
              <ArrowBackIcon />
            </IconButton> */}
            <Typography className={classes.headerStatus}>Please Press Start Button To Begin Reading</Typography>
          </div>
        );
      }
      else if(readingStatus === "readingStarted" && Capacitor.getPlatform() === 'ios'){
        return (
          <div className={classes.pageHeaderReadingStartedIOS}>
            {/* <IconButton size="small" aria-label="back" color="inherit" className={classes.backButton} onClick={() => window.alert("Please Save Reading.")}>
              <ArrowBackIcon />
            </IconButton> */}
            <Typography className={classes.headerStatus}>Reading Started</Typography>
          </div>
        );
      }
    }

    const renderStartSaveButton = (readingStatus) => {
      if(readingStatus === "toStartRead"){
        return (
          <div>
             <Button onClick={startRead} className={classes.startButton} variant="contained">Start</Button>
             <Button onClick={saveProgress} className={classes.saveButton} variant="outlined" disabled>Save</Button>
          </div>
       
        );
      }
      else if(readingStatus === "readingStarted"){
        return (
          <div>
            <Button onClick={pauseRead} className={classes.pauseButton} variant="outlined">Pause</Button>
            <Button onClick={saveProgress} className={classes.saveButtonActive} variant="contained">{savingProgress ? <CircularProgress size={24} color="secondary"/> : "Save"}</Button>
          </div>
        );
      }
    }

    const renderOverlay = (loading) => {
      if(showReadingOverlay && loading){
        return(
          <div className={classes.startReadingOverlay}>
            <p className={classes.startReadingMessage}>Loading</p>
          </div>
        )
      }
      else if(showReadingOverlay && !loading){
        return(
          <div>
          <div className={classes.startReadingOverlay}>
            <Card className={classes.startReadingInfoCard}>
              <CardContent>
                <img src={ReadingModalImage} className={classes.readingModalImage} alt=""/>
                <p className={classes.startReadingMessage}>Please Press Start Button (Bottom Left) To Begin Reading</p>
                <Button size="small" variant="outlined" onClick={() => setOpenReadingGuidelines(true)}>Reading Guidelines</Button>
              </CardContent>
              <CardActions>
                <Button size="small" variant="contained" className={classes.backPaathButton} onClick={backToPaathList} startIcon={<ArrowBackIcon/>}>Back</Button>
                <LoadingButton size="small" variant="contained" color="info" loading={passingPaath} endIcon={<ArrowForwardIcon/>} className={classes.passButton} onClick={passPaath}>Pass To Next Reader</LoadingButton>
              </CardActions>
            </Card>
          </div>
          </div>
        )
      }else if(!showReadingOverlay && !loading){
        return "";
      }
    }

    const handleDialogClose = () => {
      setDialogOpen(false);
    }

    return (

      <div className={classes.root}>
        {renderHeader(paathDetails?.progress?.readingStatus)}
        {renderOverlay(loading)}

      <Layout>
        <div className={classes.paathContainer}> 

            <Dialog open={pageSearchOpen} onClose={handlePageSearchClose}>
              <DialogTitle>Page Search</DialogTitle>
              <DialogContent>
                <TextField onChange={(event) => setPageSearchNumber(event.target.value)} autoFocus margin="normal" id="page" label="Page No." type="number" fullWidth variant="standard" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handlePageSearchClose}>Cancel</Button>
                <Button onClick={handlePageSearchChange}>Search</Button>
              </DialogActions>
            </Dialog>
        
          {loading ? 
            <div> 
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />  
            </div> : "" 
            }

            <div>
              {!loading && paathData ? paathData?.map((paath, index) => {

                return(

                  <PaathVerseDisplay 
                    index={index} 
                    serverPage={serverPage} 
                    verseId={paath.verseId} 
                    lineNo={paath.lineNo} 
                    unicode={paath.verse.unicode} 
                    transliteration={paath.transliteration.english} 
                    translation={paath.translation.en.bdb} 
                    bookmarkProgress={bookmarkProgress}
                    previousBookmarkPage={page}
                    previousBookmarkVerse={verse}
                    readingStatus={paathDetails?.progress?.readingStatus}
                    bookmarkRef={bookmarkRef}
                    showReadingOverlay={showReadingOverlay}
                  />

                )

              }) : ""  }
            </div>          

        </div>


          <Paper className={classes.paginationContainer} elevation={3}>

            {renderStartSaveButton(paathDetails?.progress?.readingStatus)}

            {/*<Typography className={classes.pageNumber}> {currentPage} </Typography>*/}
           
           <Fab size="small" onClick={() => setPageSearchOpen(true)}>{currentPage}</Fab>

            <div className={classes.pageButtonContainer}>
              {showReadingOverlay ? 
                <div> 
                  <Button disabled className={classes.pageTurnButton} size="small" variant="contained"><ArrowBackIosNewIcon fontSize="small"/></Button>
                  <Button disabled className={classes.pageTurnButton} size="small" variant="contained"><ArrowForwardIosIcon fontSize="small"/></Button>
                </div> 
                :
                <div> 
                  <Button onClick={handlePrevPage} className={classes.pageTurnButton} size="small" variant="contained"><ArrowBackIosNewIcon fontSize="small"/></Button>
                  <Button onClick={handleNextPage} className={classes.pageTurnButton} size="small" variant="contained"><ArrowForwardIosIcon fontSize="small"/></Button>
                </div>
              }
            </div>
        
          </Paper>

          <Dialog open={openReadingGuidelines} scroll={scroll} onClose={handleReadingGuidelinesClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
            <DialogTitle>
              {"Reading Guidlines"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.guidelinesTitle}>
              Personal Preparation
              </DialogContentText>
              <DialogContentText className={classes.guidelinesText}>
              Consciously understand that you are about to read teachings, writings, poetry, and ode’s written 
              by 36 authors that include 6 of the Sikh Gurus. The teachings speak to our interaction with daily life, 
              conduct, stories, relationship to the Creator and much more. 
              </DialogContentText>
              <DialogContentText className={classes.guidelinesText}>
              Connect with the themes and messages to reflect on how they connect with our own daily life and where 
              we can apply them. This is the true purpose of the teachings, to be applied in our daily life. 
              </DialogContentText>
              <DialogContentText className={classes.guidelinesText}>
              Commit to take something away for reflection, personal discussion, and further exploration. 
              </DialogContentText>
              <DialogContentText className={classes.guidelinesTitle}>
              Physical Preparation
              </DialogContentText>
              <DialogContentText className={classes.guidelinesText}>
                  Find a quiet place where you will not be distracted or disturbed so that you can receive the gift of 
                  the teachings and connect deeply with the reading. 
              </DialogContentText>
              <DialogContentText className={classes.guidelinesText}>
                Please cover your head prior to reading Guru’s Bani. This is more for our own preparation and engaging in the 
                reading of Bani with reverence and devotion. 
              </DialogContentText>
            </DialogContent>
      
            <DialogActions>
              <Button onClick={handleReadingGuidelinesClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={progressSaved}
              autoHideDuration={6000}
              onClose={handleSnackBarClose}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            >
              <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
              Progress Saved!
            </Alert>
        </Snackbar>

   
    </Layout>

    </div>
    );
}

export default ReadPaath;