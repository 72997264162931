import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  paathDetailsCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: '10px',
  },
  pageHeader: {
    display: 'flex',
    marginTop: 54,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    height: 100,
    backgroundColor: theme.palette.primary.light,
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  paathCardContainer: {
    marginBottom: theme.spacing(9),
  },
  paathTitle: {
    color: theme.palette.primary.light,
    },
  viewPaathButton: {
      backgroundColor: theme.palette.primary.light,
      color: '#ffffff',
  },
  paathCompletion: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F1F5F5',
    marginTop: 5,
    marginBottom: 10,
    borderRadius: '10px',
  },
  paathTilePercent: {
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    alignSelf: 'center',
    borderRadius: '10px',
    fontSize: '12px',
    }
}));

const Paaths = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
     
      window.scrollTo(0,0);

    }, [user])


    return(
        <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h4" component="h1"> My Paaths </Typography>
                    <Button variant="contained" color="primary" onClick={() => history.push('/create-paath')}>+ Create Paath</Button>
                </div>
            <Layout>
            
               <PaathListMain/>

            </Layout>
        </div>
    )
}

export default Paaths;