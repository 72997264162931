import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore, auth } from '../config/firebase-config';
import firebase from "firebase/app";
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathListMain from '../components/PaathListMain';
import { FreeBreakfastOutlined } from '@mui/icons-material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { resultKeyNameFromField } from '@apollo/client/utilities';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
 detailsCard: {
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '10px',
    textAlign: 'center',
  },

  updateEmailButton:{
    marginLeft: theme.spacing(1),
  },
  notificationOptions:{
    marginTop: theme.spacing(2),
  }
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EmailUpdateAfterRecovery = ({userId, userName, newEmail, currentEmail}) => {

    const classes = useStyles();
    const { user, setUser } = useContext(UserContext)
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [savingEmail, setSavingEmail] = useState(false);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        if(newEmail !== currentEmail){
            updateUserProfileOnEmailChange();
            updateUserNameDocumentOnEmailChange();
        }

    }, [newEmail, currentEmail])


    //Updates Email in User Document
    const updateUserProfileOnEmailChange = () => {
     if(userId !== undefined && newEmail !== undefined){
        const userDocRef = firestore.collection("users").doc(userId);
        userDocRef.update({
            email: newEmail,
        }).then(() => { 
            return userDocRef.get(); 
        }).then((doc) => {
            let uid = doc.id;
            let userData = doc.data();
            let updatedUser = {uid, ...userData};
            setUser(updatedUser);
            setSaved(true);
        }).catch((error) => {
            setError(true);
            setErrorMessage(error.message);
        });
      }
      else return;
    }

     //Updates Email in Username Document
     const updateUserNameDocumentOnEmailChange = () => {
        if(userName !== undefined && newEmail !== undefined){
            const userNameDocRef = firestore.collection("usernames").doc(userName);
            userNameDocRef.update({
                email: newEmail
            }).catch((error) => {
                setError(true);
                setErrorMessage(error.message);
            });
        }
    }


    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSaved(false);
    };


    return(
        <div className={classes.root}>

            {newEmail !== currentEmail ? 
                <div className={classes.detailsCard}> Your Email Was Recently Updated </div>
                : 
                ""
            }

              <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={saved}
                  autoHideDuration={6000}
                  onClose={handleSnackBarClose}
                  action={
                    <React.Fragment>
                      <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  }
                  >
                  <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                    Email Address Updated!
                  </Alert>
              </Snackbar>
        </div>
    )
}

export default EmailUpdateAfterRecovery;