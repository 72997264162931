import React, { useState, useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { UserContext } from '../components/UserProvider';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import PaathHomeImage from '../images/paaths-home-header.png';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  paathDetailsCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    //padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.backgroundCard,
    borderRadius: '10px',
  },
  paathCardContainer: {
    marginBottom: theme.spacing(9),
  },
  paathHeaderImage: {
    height: 150,
    width: 'auto',
  },
  notificationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paathType: {
    marginLeft: 5,
  },
  paathTitle: {
    marginTop:  2,
    marginLeft: 5,
    color: theme.palette.primary.dark,
    lineHeight: 1.2,
  },
  viewPaathButton: {
      backgroundColor: theme.palette.primary.light,
      color: '#ffffff',
  },
  progressContainer: {
    backgroundColor: '#F1F5F5',
    padding: theme.spacing(1),
    borderRadius: '10px',
    marginBottom: theme.spacing(2),
  },
  dateDetail:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    border: 'solid 2px',
    borderRadius: '10px',
    borderColor: '#C4C4C4',
    background: 'linear-gradient(90deg, rgba(255,255,255,0) 37%, rgba(233,178,76,0.5284488795518207) 63%, rgba(178,218,225,1) 100%)',
  },
  dateLabel: {
    fontWeight: 700,
    color: theme.palette.darkText,
    fontSize: '15px'
  },
  displayDate: {
    fontWeight: 700,
    color: theme.palette.darkText,
    lineHeight: 1,
  },
  startDate:{
    
  },
  endDate:{
    textAlign: 'right',
  },
  paathCompletion: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#F1F5F5',
    marginTop: 5,
    borderRadius: '10px',
    alignContent: 'center',
  },
  paathTilePercent: {
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    alignSelf: 'center',
    borderRadius: '10px',
    fontSize: '12px',
    },
  progressPercent: {
    marginTop: '3px',
    paddingLeft: '5px',
  },
  notificationBaddge: {
    marginTop: '-15px',
  }
}));

const PaathListMain = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(UserContext)
    const [paathList, setPaathList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    
        if(user || history){
            fetchUserPaaths();
        }
           

    }, [user, history])

    const fetchUserPaaths = async () => {
        const fetchedPaath = []; 
        if(user?.paaths?.length > 0){
            await user.paaths.forEach((id) =>
                 firestore.collection("paaths").doc(id).get().then((doc) => {
                    if(doc.exists){
                        setLoading(true);
                        const paath = doc.data();
                        paath.id = doc.id;
                        fetchedPaath.unshift(paath)
                        setPaathList(...paathList, fetchedPaath);
                        setTimeout(() => setLoading(false), 1000);
                    }
            }));
        }
    }

    const renderNotificationBadge = (paath) => {
        if(paath.progress.currentReader === user?.uid && paath.paathStatus !== "completed"){
            return(
                <Chip className={classes.notificationBaddge} label="Your Turn To Read" color="info" size="small"/>
            );
        }
        else if(paath.progress.currentReader === user?.uid && paath.paathStatus === "completed"){
            return(
                <Chip className={classes.notificationBaddge} label="Paath Completed" color="secondary" size="small"/>
            );
        }
        else if(paath.progress.currentReader !== user?.uid && paath.paathStatus === "completed"){
            return(
                <Chip className={classes.notificationBaddge} label="Paath Completed" color="secondary" size="small"/>
            );
        }
        else if(paath.progress.currentReader !== user?.uid && paath.paathStatus !== "completed"){
            return(
                <Chip className={classes.notificationBaddge} label="Paath In Progress" size="small"/>
            );
        }
    }

    return(
        <div className={classes.root}>
                
            <div className={classes.paathCardContainer}>

                {!loading && paathList?.length > 0 ? 
                    paathList.map((paath, index) => {
                        let progressPercent = (paath.progress.currentPage/1430)*100;
                        return(
                            <Card className={classes.paathDetailsCard} key={paath.id} variant="outlined">
                                <CardContent>
                                    <div className={classes.progressContainer}>
                                        <div className={classes.notificationHeader}>
                                            <Typography variant="body1" color="textPrimary" component="p" className={classes.paathType}>
                                                {paath.public ? "Public" : "Private"} {paath.paathType}
                                            </Typography>
                                            {renderNotificationBadge(paath)} 
                                        </div>
                                        <Typography gutterBottom variant="h5" component="h2" className={classes.paathTitle}>
                                                {paath.paathTitle}
                                        </Typography>
                                        <div className={classes.dateDetail}>
                                            <div className={classes.startDate}>
                                                <Typography className={classes.dateLabel}>
                                                    Start
                                                </Typography>
                                                <Typography className={classes.displayDate}>
                                                    {format(paath.startDate.toDate(), "EEE MMM dd, yyyy")}
                                                </Typography>
                                            </div>
                                            <div className={classes.endDate}>
                                                <Typography className={classes.dateLabel}>
                                                    Finish
                                                </Typography>
                                                <Typography className={classes.displayDate}>
                                                    {format(paath.finishDate.toDate(), "EEE MMM dd, yyyy")}
                                                </Typography>
                                            </div>
                                        </div>
        
                                        <Paper key={index} elevation={0} className={classes.paathCompletion}>
                                            <Box sx={{ backgroundColor: '#89C09F', padding: '15px', alignSelf: 'center', borderRadius: '10px', fontSize: '12px', width: progressPercent + '%' }}/>
                                            <Typography className={classes.progressPercent}>    
                                                {Math.round(progressPercent) + '%'}
                                            </Typography>
                                        </Paper>
                                    </div>
        
                                    <Typography variant="body2" component="p">
                                        Started By
                                    </Typography>
                                    <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                                        {paath.createdBy === user?.uid ? `You - ${paath.createdByDisplayName}` : `${paath.createdByDisplayName}`}
                                    </Typography>
                                </CardContent>
                        
                                <CardActions>
                                    <Button size="small" variant="contained" className={classes.viewPaathButton} onClick={() => history.push(`/paaths/${paath.id}`)}>
                                    View Paath
                                    </Button>
                                </CardActions>
                            </Card>
                        )})
                :  
                    <div>
                        {loading && paathList?.length > 0 ?
                            <React.Fragment>
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={335} />
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={335} />
                                <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={335} />
                            </React.Fragment>
                        :  
                            <Card className={classes.paathDetailsCard} variant="outlined">
                                <CardMedia className={classes.paathHeaderImage} image={PaathHomeImage} title="Ik Onkar" />
                                <CardContent>
                                    <Typography variant="h5" component="h2" color="secondary">
                                        You Currently Don't Have Any Ongoing Paaths.
                                    </Typography>
                                </CardContent>
                            </Card>
                        }
                    </div> 
                }

            </div>

        </div>
    )
}

export default PaathListMain;