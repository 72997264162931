import React, { Component, createContext } from 'react';
import { useState, useEffect } from 'react';
//import {Redirect} from 'react-router-dom';


export const NotificationContext = createContext({ notification: 0, notificationToken: "" });


 const NotificationProvider = ( {children} ) => {

     const [notification, setNotification] = useState(0);
     const [notificationToken, setNotificationToken] = useState("");
  
 
    return (
      <NotificationContext.Provider value={{notification, setNotification, notificationToken, setNotificationToken}}>

        {children}
        
      </NotificationContext.Provider>
    );
  
}

export default NotificationProvider;