import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/messaging';


const firebaseConfig = {
  apiKey: "AIzaSyCqwrXUiGGPQbtyRk_-nrLeyiBVR3oR2s8",
  authDomain: "my-sehaj-paath-2.firebaseapp.com",
  projectId: "my-sehaj-paath-2",
  storageBucket: "my-sehaj-paath-2.appspot.com",
  messagingSenderId: "952508329019",
  appId: "1:952508329019:web:00a9f3c09d6e45378d001e"
};

try {

  firebase.initializeApp(firebaseConfig);

} 

catch(err){
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack)}

}

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const functions = firebase.functions();

let messaging = null;

if(firebase.messaging.isSupported()){
    messaging = firebase.messaging();
}else{
  console.log("Messaging Not Supported");
}
//export const messaging = firebase.messaging();

// Google Sign In
const provider = new firebase.auth.GoogleAuthProvider();

export const getToken = (setTokenFound, userId) => {
  if(messaging && userId){
    return messaging.getToken({vapidKey: 'BAw9EbLkZKWzanbhSOc4fWtXry-fy62L_CLiZAWgcrV87jqcJ4hJ96nkKtfLAROcTMiwIKO0VFfNDllgQVz9kqE'}).then((currentToken) => {
      if (currentToken) {
        //console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
        //console.log('Uploading token');
        //Changed from .update to .set so that only 1 notification token gets set per session.
        //Removes the need to do any token management as only the newest token gets stored.
        //Also fixes the issue of multiple notifications on one device.
        firestore.collection("fcmTokens").doc(userId).set({
          deviceTokens: firebase.firestore.FieldValue.arrayUnion(currentToken)
        })
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  else{
    return "Messaging Not Supported";
  } 
}

export const getMobileToken = (setTokenFound, userId, mobileToken) => {
  if(mobileToken) {
      setTokenFound(mobileToken);
      firestore.collection("fcmTokens").doc(userId).set({
        deviceTokens: firebase.firestore.FieldValue.arrayUnion(mobileToken)
      })
  } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
  }
}

export const onMessageListener = () => {
  new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
  });
}


// Get User Document
const getUserDocument = async uid => {
  //console.log("GETTING USER DOCUMENT");
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

//Email Sign In
export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, userName } = user;
      try {
        await userRef.set({
          displayName,
          userName,
          email,
          phone: "",
          location: {
            country: "",
            city: "",
            provinceState: "",
          },
          accountType: "basic",
          paaths:[],
          savedGroups:[],
          preferences:{ 
            emailNotification: true,
            pushNotification: true,
            textNotification: false,
          },
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
  };

  export const generateUserNameDocument = async (user, displayName, userName, email) => {
    //console.log("Creating username document");
    const userNameRef = firestore.doc(`usernames/${userName}`);
    const snapshot = await userNameRef.get();
    if (!snapshot.exists) {
      //const {displayName, userId} = user;
      try{
        await userNameRef.set({
          displayName: displayName,
          userId: user.uid,
          email: email,
        });
      } catch (error) {
        console.log("Error creating username document", error);
      }
      }
    }

  export const generateNotificationDocument = async (user) => {
    //console.log("Creating notification document");
    firestore.collection("notifications").doc(user.uid).set({
      userNotification:  []
    })
    }

    export const generateFCMTokenDocument = async (user) => {
      //console.log("Creating token document");
      firestore.collection("fcmTokens").doc(user.uid).set({
        deviceTokens:  []
      })
    }

    // Google Sign In Code. Removing for now as social sign in isn't needed.

  // export const signInWithGoogle = () => {
  //   auth.signInWithPopup(provider).then((result) => {
  //     /** @type {firebase.auth.OAuthCredential} */
  //     const credential = result.credential;
  
  //     // This gives you a Google Access Token. You can use it to access the Google API.
  //     const token = credential.accessToken;
  //     // The signed-in user info.
  //     const user = result.user;
  //     generateGoogleUserDocument(user);
      
  //   }).catch((error) => {
  //     // Handle Errors here.
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     // The email of the user's account used.
  //     var email = error.email;
  //     // The firebase.auth.AuthCredential type that was used.
  //     var credential = error.credential;
  //     // ...
  //   });
  // };
  
  
  // Google Sign In User Doc Creation
  // const generateGoogleUserDocument = async (user, additionalData) => {
  //   if (!user) return;
  //   const userRef = firestore.doc(`users/${user.uid}`);
  //   const snapshot = await userRef.get();
  //   if (!snapshot.exists) {
  //     const { email, displayName, userName } = user;
  //     try {
  //       await userRef.set({
  //         displayName,
  //         userName,
  //         email,
  //         phone: "",
  //         location: {
  //           country: "",
  //           city: "",
  //           provinceState: "",
  //         },
  //         accountType: "basic",
  //         paaths:[],
  //         savedGroups:[],
  //         preferences: {
  //           emailNotification: true,
  //           pushNotification: true,
  //           textNotification: false,
  //         },
  //         ...additionalData
  //       });
  //     } catch (error) {
  //       console.error("Error creating user document", error);
  //     }
  //   }
  //   return getUserDocument(user.uid);
  // };
