import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore } from '../config/firebase-config';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UserProvider, { UserContext } from '../components/UserProvider';
import { PaathInviteContext } from '../components/PaathInviteProvider';
import CampaignIcon from '@mui/icons-material/Campaign';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  paathInviteBox:{
    display: 'flex', 
    backgroundColor: theme.palette.primary.light, 
    borderRadius: '10px', 
    alignContent: 'center',
    alignItems: 'center', 
    justifyContent: 'space-between', 
    marginTop: '20px', 
    maxWidth: '100%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  paathInviteMessage:{
    fontSize: '17px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  acceptButton:{
    backgroundColor: theme.palette.info.main,
    text: '#ffffff',
  }
}));

const CommunityPaathInvite = ({communityPaathDetails}) => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {user, setUser} = useContext(UserContext);
    const {paathInviteIdContext, setPaathInviteIdContext} = useContext(PaathInviteContext);
    const [currentUser, setCurrentUser] = useState({});
    const [paathList, setPaathList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [paathAdded, setPaathAdded] = useState(false);
   
    //const communityPaathId = 'AbLTuMhbhn6MAPDLySnA';


    useEffect(() => {

        window.scrollTo(0,0);

        if(user){
            setCurrentUser(user);
           
        }

    }, [user])

    const acceptPaathInvite = () => {
       if(window.confirm("Please confirm you would like to join the community paath.")){
        setLoading(true);
        addPaathToUserProfile(communityPaathDetails?.communityPaathId);
        addUserToPaath(communityPaathDetails?.communityPaathId);
       }
        else{
            return;
        }
    }

     // Add's paath to user's profile
     const addPaathToUserProfile = (id) => {
        const currentPaaths = currentUser.paaths;
        const userDocRef = firestore.collection("users").doc(currentUser.uid);
        userDocRef.update({
            paaths: [...currentPaaths, id]
        }).then(() => { 
           return userDocRef.get(); 
        }).then((doc) => {
            let uid = doc.id;
            let userData = doc.data();
            let updatedUser = {uid, ...userData};
            setUser(updatedUser);
        })
        .catch((error) => {
            setError("Error adding program: ", error);
        });
    }

     const addUserToPaath = (id) => {
        const paathId = id;
        const participant = {displayName: currentUser.displayName, email: currentUser.email, id: currentUser.userName, userId: currentUser.uid}
         firestore.collection("paaths").doc(id).update({
             participants: firebase.firestore.FieldValue.arrayUnion(participant),
             confirmedParticipants:  firebase.firestore.FieldValue.arrayUnion(currentUser.uid),
         }).then(() => {
             setPaathAdded(true);
             setLoading(false);
             setPaathInviteIdContext("");
             setTimeout(() => history.push(`/paaths/${paathId}`), 1000);
         }).catch((error) => {
             setPaathAdded(false);
             setLoading(false);
             console.error("Error writing document: ", error);
             setError("Error adding paath: ", error);
         });
     }

    return(

        <div className={classes.root}>
            
            {currentUser?.paaths?.includes(communityPaathDetails?.communityPaathId) ? 
                ""
                :
                <Box className={classes.paathInviteBox}>
                    <CampaignIcon/> 
                    <p className={classes.paathInviteMessage}>{communityPaathDetails?.messageText}</p>
                    <Button variant="contained" className={classes.acceptButton} size="large" onClick={acceptPaathInvite}>Join</Button>
                </Box> 
            }

        </div>
    )
}

export default CommunityPaathInvite;