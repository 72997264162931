import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UserProvider, { UserContext } from '../components/UserProvider';
import { PaathInviteContext } from '../components/PaathInviteProvider';
import { NotificationContext } from '../components/NotificationProvider';
import { firestore, getToken, onMessageListener, auth } from '../config/firebase-config';
import { getDividerUtilityClass } from '@mui/material';
import { format } from 'date-fns';
import PaathListHome from '../components/PaathListHome';
import PaathVerseDisplayHome from '../components/PaathVerseDisplayHome';
import PaathInvite from '../components/PaathInvite';
import CommunityPaathInvite from '../components/CommunityPaathInvite';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaathHomeImage from '../images/paaths-home-header.png';
import DailyVerseHome from '../images/daily-verse-home-header.png';
import MainHomeHeader from '../images/main-home-header.jpg';
import EmailUpdateAfterRecovery from '../components/EmailUpdateAfterRecovery';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    backgroundColor: theme.palette.primary.background,
  },
  header:{
    marginTop: 100,
    minHeight: 100,
    borderRadius: '10px',
  },
  mainHeaderImage:{
    height: 100,
    width: 'auto',
  },
  paathInviteBox:{
    display: 'flex', 
    backgroundColor: theme.palette.info.main, 
    borderRadius: '10px', 
    alignContent: 'center', 
    justifyContent: 'space-between', 
    marginTop: '20px', 
    maxWidth: '100%', 
    padding: theme.spacing(1),
  },
  paathInviteMessage:{
    fontSize: '18px',
  },
  globalNotificationBox:{
    backgroundColor: theme.palette.info.main, 
    borderRadius: '10px', 
    alignContent: 'center', 
    justifyContent: 'space-between', 
    marginTop: '20px', 
    maxWidth: '100%', 
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  globalNotificationMessage:{
    fontSize: '16px',
  },
  dateBox: {
    backgroundColor: theme.palette.info.main,
    borderRadius: 10,
  },
  card: {
    maxWidth: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.backgroundCard,
  },
  paathCard: {
    maxWidth: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.backgroundCard,
  },
  dailyVerse: {
    marginTop: theme.spacing(3),
  },
  verseHeaderImage:{
    height: 150,
    width: 'auto',
    margin: '0 auto',
  },
  userGreeting: {
    color: theme.palette.primary.dark,
  },
  enableNotificationBox:{
    display: 'flex', 
    flexDirection: 'column',
    backgroundColor: '#fcf3e2', 
    borderRadius: '10px', 
    alignContent: 'center', 
    justifyContent: 'space-between', 
    marginTop: '20px', 
    maxWidth: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  enableMessage:{
    fontSize: '18px',
    lineHeight: '1.7em',
    color: theme.palette.primary.darkText,
  },
  enableNotificationsChip:{
    alignSelf: 'center',
  },
  acceptButton:{
    backgroundColor: theme.palette.secondary.main,
    text: '#ffffff',
  },
  titleName: {
    color: theme.palette.primary.dark,
    fontSize: '28px',
  },
  cardTitle: {
      color: '#5A8087',
  },
  dailyVerseCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.info.dark,
    marginBottom: theme.spacing(2),
  },
  text: {
      color: theme.palette.primary.darkText,
  },
  paathSection: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(9),
  },
  paathHeaderImage: {
    height: 170,
    width: 'auto',
  },
  paathSectionHeader: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.dark,
  },
  paathTile: {
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#F1F5F5',
      marginTop: 5,
  },
  paathTilePercent: {
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(1),
      alignSelf: 'center',
      borderRadius: '10px',
      fontSize: '12px',
  },
  paathActionButton: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
  }
}));

const Home = () => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {user} = useContext(UserContext);
    const {paathInviteIdContext} = useContext(PaathInviteContext);
    const numberNotifications = useContext(NotificationContext);
    const [currentUser, setCurrentUser] = useState({});
    const [paathList, setPaathList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [communityPaathDetails, setCommunityPaathDetails] = useState({});
    const [globalAppNotification, setGlobalAppNotification] = useState({});

    const fetchCommunityPaathData = () => {
      setLoading(true);
      firestore.collection("admin").doc("communityPaath").get().then((doc) => {
          if (doc.exists){
            setCommunityPaathDetails(doc.data());
            setLoading(false);
          }
          }).catch((error) => {
              console.log(error);
          });  
    }

    const fetchGlobalAppNotificationData = () => {
      setLoading(true);
      firestore.collection("admin").doc("globalAppNotification").get().then((doc) => {
          if (doc.exists){
            setGlobalAppNotification(doc.data());
            setLoading(false);
          }
          }).catch((error) => {
              console.log(error);
          });  
    }


    useEffect(() => {

        window.scrollTo(0,0);
        const fetchedPaath = []; 

        if(user){
          setCurrentUser(user);
          fetchCommunityPaathData();
          fetchGlobalAppNotificationData();
        }

    }, [user])



    const userGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12){
            return "Good Morning";
        }
        else if (hour < 18){
            return "Good Afternoon";
        }
        else{
            return "Good Evening";
        }
        //return("Good " + (hour<12 && "Morning" || hour<18 && "Afternoon" || "Evening"))
    }


    return(
        <div className={classes.root}>
           
            <Layout>
              <Card variant="outlined" className={classes.header}>
                <CardMedia className={classes.mainHeaderImage} image={MainHomeHeader} alt=""/>
                <CardContent>
                  <div>
                    <Typography variant="h5" component="h1" className={classes.userGreeting} > {userGreeting()} </Typography>
                    <Typography className={classes.titleName} variant="p" component="h2"> {currentUser?.displayName} </Typography>
                  </div>
                  </CardContent>
                </Card>
                {/* This Email update component is only ever displayed if a users email is changd without their consent
                and they click the email recovery link that firebase sends to their old email. The logic in this component
                 will then update the email addresses in the user and username document to match the users sign in email which
                 was recovered through the account management section */}
                {auth.currentUser?.email !== currentUser?.email ? <EmailUpdateAfterRecovery userId={currentUser?.uid} userName={currentUser?.userName} newEmail={auth.currentUser?.email} currentEmail={currentUser?.email}/> : ""}
                {paathInviteIdContext !== "" ? <PaathInvite/> : "" }
                {/* Community Paath Invite Component*/}
                {!loading && communityPaathDetails?.showMessage ? <CommunityPaathInvite communityPaathDetails={communityPaathDetails}/> : ""}
                {/* Global Notifiation Component*/}
                {!loading && globalAppNotification?.showGlobalNotification ? 
                  <Box className={classes.globalNotificationBox}>
                      <p className={classes.globalNotificationMessage}>{globalAppNotification?.globalNotificationMessage}</p>
                  </Box>
                  : "" } 
                
                {/* Daily Verse */}
                <div className={classes.dailyVerse}>    
                    <Card className={classes.card} variant="outlined">
                      <CardMedia className={classes.verseHeaderImage} image={DailyVerseHome} title="Guru Nanak" />
                        <CardContent>
                        
                            <div className={classes.dailyVerseCardHeader}>
                                <div>
                                    <Typography component="h2" variant="h5">
                                        Daily Verse
                                    </Typography>
                                    <Typography component="p" variant="p">
                                        {format(new Date(), "eee, MMM dd yyyy")}
                                    </Typography>
                                </div>
                                <Box sx={{ display: 'flex', backgroundColor: theme.palette.info.main, borderRadius: '5px', alignContent: 'center', textAlign: 'center', width: '5px', height: 'auto'}}/>   
                            </div>
                       
                        </CardContent>
                        <PaathVerseDisplayHome/>
                    </Card>
                </div>

                <div className={classes.paathSection}>
                    {/* Create Paath Card if no paaths*/}
                    <Card className={classes.paathCard} variant="outlined">
                      <CardMedia className={classes.paathHeaderImage} image={PaathHomeImage} title="Ik Onkar" />
                        <CardContent>
                            <div className={classes.paathSectionHeader}>
                                <div>
                                    <Typography component="h2" variant="h4" gutterBottom>
                                        Your Paaths
                                    </Typography>
                                </div>
                            </div>
                            <PaathListHome/> 
                        </CardContent>
                    
                        <CardActions>
                            {currentUser?.paaths?.length > 0 ? 
                                <Button onClick={() => history.push('/paaths')} size="large" fullWidth className={classes.paathActionButton} variant="contained">
                                View Paaths
                                </Button>
                                :
                                <Button onClick={() => history.push('/create-paath')} size="large" fullWidth className={classes.paathActionButton} variant="contained">
                                + Start New Paath
                                </Button>
                            }
                        </CardActions>
                    </Card>
                </div>
            </Layout>
        </div>
    )
}

export default Home;