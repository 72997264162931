import React, { useState, useEffect, useContext } from 'react';
import { auth } from '../config/firebase-config';
import firebase from "firebase/app";
import 'firebase/messaging';
import { firestore, getToken, onMessageListener, getMobileToken } from '../config/firebase-config';
import * as Realm from "realm-web";
import { useRealmApp } from "../config/RealmApp";
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import { UserContext } from '../components/UserProvider';
import { NotificationContext } from '../components/NotificationProvider';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Drawer from '@mui/material/Drawer';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import BallotIcon from '@mui/icons-material/Ballot';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SettingsIcon from '@mui/icons-material/Settings';
import NavIcon from '../images/mspnavicon.png';
import GroupIcon from '@mui/icons-material/Group';
import InfoIcon from '@mui/icons-material/Info';
import SpaIcon from '@mui/icons-material/Spa';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import Chip from '@mui/material/Chip';
import useMediaQuery from '@mui/material/useMediaQuery';
import LogRocket from 'logrocket';
import { Capacitor } from '@capacitor/core';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";
import { FormControlUnstyledContext } from '@mui/base';
import VersionInfo from './VersionInfo';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.primary.background,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    navIcon:{
        width: '30%',
        height: 'auto',
        opacity: 0.7,
    },
    title: {
      flexGrow: 1,
      height: '40px',
    },
    enableNotificationBox:{
        display: 'flex', 
        flexDirection: 'column',
        backgroundColor: '#fcf3e2', 
        alignContent: 'center', 
        justifyContent: 'center', 
        textAlign: 'center',
        maxWidth: '100%',
        marginTop: '65px',
        marginBottom: '-90px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
     enableMessage:{
        fontSize: '18px',
        lineHeight: '1.7em',
        color: theme.palette.primary.darkText,
    },
    list: {
        width: 250,
    },
    listHeader: {
        paddingTop: theme.spacing(7),
        height: 150,
        backgroundColor: theme.palette.secondary.main,
    },
    fullList: {
        width: 'auto',
    },
    mainContentContainer: {
        paddingBottom: theme.spacing(2),
    },
    childrenContainer: {
        paddingTop: theme.spacing(1),
    },
    childrenContainerIOS: {
        paddingTop: theme.spacing(3),
    },
    bottomNav: {
        backgroundColor: '#fcfcfc',
        width: '100%',
        paddingBottom: theme.spacing(1),
        position: 'fixed',
        bottom: 0,
        zIndex: 9999,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 40,
    },
  }));

  const StyledToolbarIOS = styled(Toolbar)(({ theme }) => ({
    alignItems: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    '@media all': {
      minHeight: 80,
    },
  }));

const Layout = ({ children }) => {

    const realmApp = useRealmApp();
    const { user } = useContext(UserContext);
    const numberNotifications = useContext(NotificationContext);
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const [currentRoute, setCurrentRoute] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    //const [isTokenFound, setTokenFound] = useState(false);
    const [show, setShow] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [denyNotification, setDenyNotification] = useState(false);
    const [nativeNotificationAllowed, setNativeNotificationAllowed] = useState(false);
    const [nativeTokenReceived, setNativeTokenReceived] = useState(false);
    const [nativeTokenError, setNativeTokenError] = useState('');
    const [messagingSupported, setMessagingSupported] = useState(false);
    const [openSignoutModal, setOpenSignoutModal] = useState(false);
    const above350px = useMediaQuery('(min-width:350px)');


    const getNumberUnreadNotifications = (fetchedNotifications) => {
        if(fetchedNotifications === undefined){
            return 0;
        }
        else{
            const numberUnread = fetchedNotifications.filter(notification => notification.read === false);
            return numberUnread.length;
        }
    }

      
    const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
      }

    const showToast = async (msg) => {
        await Toast.show({
            text: msg
        })
    }

    useEffect(() => {


        //Notifications on Mobile
        // Moviing this function to the very top as on the native builds
        // It doesn't seem to run on component mount if it's at the end
        // of the useEffect hook.
        hanldeNotificationPermissionNative();

        // Listens to the users notification document and updates notification count if there are unread notifications.
        const unsubscribe = firestore.collection("notifications").doc(user?.uid).onSnapshot(doc => {
            const notification = doc.data();
            numberNotifications.setNotification(getNumberUnreadNotifications(notification?.userNotification));
        })

        setCurrentRoute(location.pathname);
        
        if(user === undefined){
            history.push('/');
        }

        if(currentRoute === '/home' && location.pathname !== '/home'){
            history.push('/home');
        }

        if(currentRoute === '/paaths' && location.pathname !== '/paaths'){
            history.push('/paaths');
        }

        if(currentRoute === '/read-paath-freely' && location.pathname !== '/read-paath-freely'){
            history.push('/read-paath-freely');
        }

        if(currentRoute === '/e-golak' && location.pathname !== '/e-golak'){
            history.push('/e-golak');
        }

        // Notifications on Web
        if(firebase.messaging.isSupported()){
            setMessagingSupported(true);
        }
        else{
          setMessagingSupported(false);
        }

        if(!('Notification' in window)){
            return;
        }
        else{
            if(Notification.permission === 'granted'){
                getToken(numberNotifications.setNotificationToken, user?.uid);
            }
        }


        return () => {
            unsubscribe()
        }

    }, [user, currentRoute])


    LogRocket.identify(user?.uid, {
        name: user?.displayName,
        email: user?.email,
      
        // Add your own custom user variables here, ie:
        userName: user?.userName
      });

    
    const handleMenu = (event) => {
        event.preventDefault();
        setMenuOpen(true);
    };

    const registerNativeNotifications = () => {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token) => {
                getMobileToken(numberNotifications.setNotificationToken, user?.uid, token.value);
                setNativeNotificationAllowed(true);
                setNativeTokenReceived(true);
            }
        );
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                setNativeTokenError('Error on registration: ' + JSON.stringify(error));
            }
        );
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
            (notification) => {
                setNotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
            }
        );
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
            (notification) => {
                setNotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
            }
        );
    }

    const hanldeNotificationPermissionNative = () => {
        if(Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android'){
            PushNotifications.checkPermissions().then((res) => {
                if (res.receive !== 'granted') {
                    PushNotifications.requestPermissions().then((res) => {
                        if (res.receive === 'denied') {
                            setNativeNotificationAllowed(false);
                        }
                        else {
                            setNativeNotificationAllowed(true);
                            registerNativeNotifications();
                        }
                        });
                    }
                else {
                    setNativeNotificationAllowed(true);
                    registerNativeNotifications();
                }
            });
        }
    }


    const checkNotificationPromiseWeb = () => {
        try {
          Notification.requestPermission().then();
        } 
        catch(e) {
          return false;
        }
        return true;
    }

    // function to actually ask the permissions on web
    const hanldeNotificationPermissionWeb = () => {
        // Let's check if the browser supports notifications
        if (!('Notification' in window)) {
          console.log("This browser does not support notifications.");
        } 
        else {
          if(checkNotificationPromiseWeb()) {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted"){
                    getToken(numberNotifications.setNotificationToken, user?.uid);
                }
                else if(permission === "denied"){
                    setDenyNotification(true);
                }
            })
          } 
          else {
            Notification.requestPermission(function(permission) {
                if (permission === "granted"){
                    getToken(numberNotifications.setNotificationToken, user?.uid);
                }
                else if(permission === "denied"){
                    setDenyNotification(true);
                }
            });
          }
        }
    }

    const renderNotificationButtonWeb = () => {
        if (!('Notification' in window)) {
            return "";
        }
        else{
            if(Notification.permission !== "granted" && Notification.permission !== "denied" &&  above350px && messagingSupported){
                return(
                    <Chip className={classes.enableNotificationsChip} label="Enable" color="info" icon={<NotificationsIcon />}  size="small" onClick={hanldeNotificationPermissionWeb} />
                )
            }
            else if(Notification.permission === "denied" && above350px && denyNotification && messagingSupported){
                return(
                    <Chip className={classes.enableNotificationsChip} label="Off" color="info" icon={<NotificationsIcon />}  size="small" />
                )
            }
        }
    }

    const renderNotificationButtonNative = () => {
        if(Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android'){
            // return(
            //     <Chip className={classes.enableNotificationsChip} label="Enable" color="info" icon={<NotificationsIcon />}  size="small" onClick={hanldeNotificationPermissionNative} />
            // )
            if(!nativeNotificationAllowed && numberNotifications.notificationToken === '' ){
                return(
                    <Chip className={classes.enableNotificationsChip} label="Enable" color="info" icon={<NotificationsIcon />}  size="small" onClick={hanldeNotificationPermissionNative} />
                )
            }
            else if(nativeNotificationAllowed && numberNotifications.notificationToken !== ''){
                return(
                    ""
                )
            }
        }
        else{
            return ""
        }
    }

    const renderNotificationMessageWeb = () => {
        if (!('Notification' in window)) {
            return "";
        }
        else{
            if(Notification.permission !== "granted" && Notification.permission !== "denied" && messagingSupported && currentRoute === '/home'){
                return(
                    <div className={classes.enableNotificationBox} variant="outlined">
                        <Typography component="h2" variant="h6">Please Enable Notifications</Typography>
                        <p className={classes.enableMessage}>
                            For the Best My Sehaj Paath experience, please click the yellow Enable button in the top right corner then select "Allow".
                        </p>
                    </div> 
                )
            }
            else if(Notification.permission !== "granted" && Notification.permission === "denied" && messagingSupported && currentRoute === '/home'){
                <div className={classes.enableNotificationBox} variant="outlined">
                    <p className={classes.enableMessage}>
                        Notifications turned off.
                    </p>
                </div> 
            }
        }
    }


    const renderNotificationIcon = () => {
        if(numberNotifications.setNotification !== '' && numberNotifications.notificationToken !== ''){
            return(
                <IconButton aria-label="notifications" color="inherit" size="large" onClick={() => history.push('/notifications')}>
                    <Badge badgeContent={numberNotifications?.notification} color="info">
                        <NotificationsIcon color={numberNotifications.notificationToken !== '' ? "" : "error"}/>
                    </Badge>
                </IconButton>
            )
        }
        else{
            return(
                <IconButton aria-label="notifications" color="inherit" size="large" onClick={() => history.push('/notifications')}>
                    <Badge badgeContent={numberNotifications?.notification} color="info">
                        <NotificationsNoneIcon color={numberNotifications.notificationToken !== '' ? "" : "error"}/>
                    </Badge>
                </IconButton>
            )
        }
    }


    const handleSignoutModalClose = () => {
        setOpenSignoutModal(false);
    }  

    const handleSignOut = () => {
        auth.signOut();
        realmApp.logOut();
    }

    const handleBottomNavChange = (event, newValue) => {
        setCurrentRoute(newValue);
    }


    return (
        <div className={classes.root}>
            <div>
                <AppBar position="fixed">
                {/*Checks to see if the app is running natively on IOS and adds margin to the top Toolbar otherwise returns the normal toolbar*/}    
                {Capacitor.getPlatform() === 'ios' ? 
                    <StyledToolbarIOS>
                        <IconButton edge="start" onClick={handleMenu} className={classes.menuButton} color="inherit" aria-label="menu" size="large"> <MenuIcon /> </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            My Sehaj Paath
                        </Typography>
                        <div>
                            {renderNotificationButtonWeb()}
                            {renderNotificationButtonNative()}
                            {renderNotificationIcon()}
                        </div>
                    </StyledToolbarIOS> 
                    :
                     <StyledToolbar>
                        <IconButton edge="start" onClick={handleMenu} className={classes.menuButton} color="inherit" aria-label="menu" size="large"> <MenuIcon /> </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            My Sehaj Paath
                        </Typography>
                        <div>
                            {renderNotificationButtonWeb()}
                            {renderNotificationButtonNative()}
                            {renderNotificationIcon()}
                        </div>
                    </StyledToolbar> 
                }
                </AppBar>
                {renderNotificationMessageWeb()}
                {/* {renderNotificationMessageNative()} */}
            </div>
           
            <Container maxWidth="md" className={classes.mainContentContainer}>
                <Drawer anchor={'left'} open={menuOpen} onClose={(() => setMenuOpen(false))}>
                    <div className={classes.list}>
                        <List className={classes.listHeader}>
                            <ListItem>
                                <img src={NavIcon} alt="Icon" className={classes.navIcon} />
                            </ListItem>
                            
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                primary={
                                    <Typography component="h1" variant="h6" className={classes.inline} color="textSecondary">
                                        {user?.displayName}
                                    </Typography>
                                }
                                secondary={
                                    <Typography component="span" variant="body1" className={classes.inline} color="textSecondary">
                                        {user?.userName}
                                    </Typography>
                                }
                                />
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem button key={'Home'} onClick={() => history.push('/home')}>
                                <ListItemIcon> <HomeIcon/> </ListItemIcon>
                                <ListItemText primary={'Home'} />
                            </ListItem>
                            <ListItem button key={'Paaths'} onClick={() => history.push('/paaths')}>
                                <ListItemIcon> <BallotIcon/> </ListItemIcon>
                                <ListItemText primary={'Paaths'} />
                            </ListItem>
                            {/* <ListItem button key={'Read'} onClick={() => history.push('/read-paath-freely')}>
                                <ListItemIcon> <LocalLibraryIcon /> </ListItemIcon>
                                <ListItemText primary={'Read'} />
                            </ListItem> */}
                            <ListItem button key={'Reading Guidelines'} onClick={() => history.push('/reading-guidelines')}>
                                <ListItemIcon> <TextSnippetIcon /> </ListItemIcon>
                                <ListItemText primary={'Reading Guidelines'} />
                            </ListItem>
                            <Divider />
                            <ListItem button key={'Notifications'} onClick={() => history.push('/notifications')}>
                                <ListItemIcon> <NotificationsIcon /> </ListItemIcon>
                                <ListItemText primary={'Notifications'} secondary={numberNotifications?.notification ? `Unread Notifications: ${numberNotifications?.notification }` : ""}/>
                            </ListItem>
                            <ListItem button key={'Saved Groups'} onClick={() => history.push('/saved-groups')}>
                                <ListItemIcon> <GroupIcon /> </ListItemIcon>
                                <ListItemText primary={'Saved Groups'} />
                            </ListItem>
                            <ListItem button key={'Settings'} onClick={() => history.push('/settings')}>
                                <ListItemIcon> <SettingsIcon /> </ListItemIcon>
                                <ListItemText primary={'Settings'} />
                            </ListItem>
                            <Divider />
                            <ListItem button key={'About Us'} onClick={() => history.push('/about-us')}>
                                <ListItemIcon> <InfoIcon /> </ListItemIcon>
                                <ListItemText primary={'About Us'} />
                            </ListItem>
                            {/* We are only rendering the donate button and paath on the web app. This is hidden on native apps until in app purchase is implemented */}
                            {Capacitor.getPlatform() === 'web' ? 
                                <ListItem button key={'E-Golak'} onClick={() => history.push('/e-golak')}>
                                    <ListItemIcon> <SpaIcon /> </ListItemIcon>
                                    <ListItemText primary={'Donate'} />
                                </ListItem>
                                : ""
                            }
                            <ListItem button onClick={() => setOpenSignoutModal(true)} key={'Sign Out'}>
                                <ListItemIcon> <ExitToAppIcon/> </ListItemIcon>
                                <ListItemText primary={'Sign Out'} />
                            </ListItem>
                            <Divider />
                            <ListItem key={'Version Info'}>
                                <ListItemIcon> <ChangeHistoryIcon />  </ListItemIcon>
                                <ListItemText primary={<VersionInfo/>} />
                            </ListItem>
                        </List>
                   
                    </div>
                </Drawer>
                <div className={Capacitor.getPlatform() !== 'ios' ? classes.childrenContainer : classes.childrenContainerIOS}>                
                    {children}
                </div>                    
                <Dialog open={openSignoutModal} onClose={handleSignoutModalClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                    <DialogTitle>
                        {"Confirm Sign Out?"}
                    </DialogTitle>
                    <Divider/>
                    <DialogActions>
                        <Button onClick={handleSignoutModalClose} color="error">
                            Cancel
                        </Button>
                        <Button onClick={handleSignOut}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>

            <BottomNavigation fixed={true} value={currentRoute} onChange={handleBottomNavChange} showLabels className={classes.bottomNav}>
                <BottomNavigationAction label="Home" value="/home" icon={<HomeIcon />} />
                <BottomNavigationAction label="My Paaths" value="/paaths" icon={<BallotIcon />} />
                {/* <BottomNavigationAction label="Read" value="/read-paath-freely" icon={<LocalLibraryIcon />} /> */}
                {/* We are only rendering the donate button and paath on the web app. This is hidden on native apps until in app purchase is implemented */}
                {Capacitor.getPlatform() === 'web' ? 
                    <BottomNavigationAction label="Donate" value="/e-golak" icon={<SpaIcon />} /> 
                    :
                    ""
                } 
            </BottomNavigation>
        </div>
    );
}

export default Layout;