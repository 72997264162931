import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore, functions } from '../config/firebase-config';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UserProvider, { UserContext } from '../components/UserProvider';
import Stack from '@mui/material/Stack';





const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
    header:{
      textAlign: 'center',
      marginBottom: 20,
    },
    pageHeader: {
        display: 'flex',
        marginTop: 54,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: 100,
        backgroundColor: theme.palette.primary.light,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    forms:{
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.backgroundCard,
    },
    formControl: {
        minWidth: '100%',
        marginBottom: theme.spacing(3),
    },
    dateField: {
        minWidth: '100%',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    title: {
      marginTop: '80px',
    },
    paathSection: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginTop: theme.spacing(2),
    },
    paathOptions:{
        marginTop: theme.spacing(2),
    },
    autoComplete: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    userNameSearch: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    addGroupButton: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    selectedUserSearch: {
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    selectedUsers: {
        marginTop: theme.spacing(2),
    },
    selectedUsersList: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
      },
      ul: {
        backgroundColor: 'inherit',
        padding: 0,
      },
    bottomActionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    cancelButton: {
        color: theme.palette.error.main,
    }
}));



const steps = ['Create', 'Finish'];

const CreateGroupForm = ({ handleCancelCreate, setCreateGroupView, setRefetch }) => {

    const classes = useStyles();
    //const theme = useTheme();
    const history = useHistory();
    const {user, setUser} = useContext(UserContext);
    //const functions = getFunctions();
    const sendPushNotifications = functions.httpsCallable('sendPushNotifications');

    const [loggedInUserId, setLoggedInUserId] = useState("");
    const [value, setValue] = useState(0);
    const [groupName, setGroupName] = useState('');
    const [participants, setParticipants] = useState([]);
    const [userNameSearchOpen, setUserNameSearchOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [usersFound, setUsersFound] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([{displayName: user?.displayName, userId: user?.uid, id: user?.userName}]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [addingGroup, setAddingGroup] = useState(false);
    const [groupAdded, setGroupAdded] = useState(false);
    const [error, setError] = useState('');
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    useEffect(() => {

        let foundUser = {displayName: '', userName: '', id: ''};
        if(userName !== ''){
           
            firestore.collection("usernames").doc(userName).get().then((doc) => {
                if(doc.data() === undefined){
                    console.log('nothing');
                }
                else{
                    foundUser = doc.data();
                    foundUser.id = doc.id;
                    console.log(foundUser);
                    setOptions([foundUser]);
                }
            })
        }
        setLoggedInUserId(user?.uid);

    }, [loading, userName])

    
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1); 
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    
    const handleReset = () => {
        setActiveStep(0);
    };
  
    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    const handleChange = (event) => {
        if(event.target.name === 'group-name'){
            setGroupName(event.target.value);
        }
    }

    const createGroup = (event) => {
        event.preventDefault();
        if (groupName === "" || selectedUsers.length === 0) {
             alert("Please Complete Paath Title & Add Users.");
        } else {
            setAddingGroup(true);
            firestore.collection("savedGroups").add({
                createdBy: user.uid,
                groupName: groupName,
                users: selectedUsers,
            }).then((docRef) => {
                addGroupToUserProfile(docRef.id);
                setAddingGroup(false);
                setGroupAdded(true);
                setCreateGroupView(false);
                setRefetch(true);
            }).catch((error) => {
                setAddingGroup(false);
                console.error("Error writing document ", error);
                setError("Error adding paath: ", error);
            })
        }
    }

    // Add's paath to user's profile
    const addGroupToUserProfile = (id) => {
        const currentGroups = user.savedGroups;
        const userDocRef = firestore.collection("users").doc(user.uid);
        userDocRef.update({
            savedGroups: [...currentGroups, id]
        }).then(() => { 
           return userDocRef.get(); 
        }).then((doc) => {
            let uid = doc.id;
            let userData = doc.data();
            let updatedUser = {uid, ...userData};
            setUser(updatedUser);
        })
        .catch((error) => {
            console.error("Error updating document: ", error);
            setError("Error adding program: ", error);
        });
    }
 
    const handleAddUser = (user, index) => {
        let updatedUser = user;
        //updatedUser.inviteAccepted = "pending";
        setSelectedUsers([...selectedUsers, updatedUser]);
    }

    const handleRemoveUser = (index) => {
        const updatedUserList = selectedUsers.filter((_, i) => i !== index);
        setSelectedUsers(updatedUserList);
    }

    const renderStepForm = (activeStep) => {
        if(activeStep === 0){
            return(
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                    <TextField onChange={handleChange} value={groupName} variant="outlined" margin="normal" fullWidth id="group-name" label="Group Name" name="group-name" />
                    {/* Paath Type */}
                    <TextField label="Search Username to Add" variant="outlined" value={userName} className={classes.userNameSearch} onChange={(event) => setUserName(event.target.value)} 
                    InputProps={{ endAdornment: ( <React.Fragment> {loading ? <CircularProgress color="inherit" size={20} /> : null} </React.Fragment> ), }} />
                        <List dense className={classes.selectedUserSearch}>
                            <Divider />

                                {options ? options.map((user, index) => {

                                return(
                                    <ListItem button key={index} onClick={() => handleAddUser(user, index)}>
                                        <ListItemAvatar> <Avatar> </Avatar> </ListItemAvatar>
                                        <ListItemText primary={user.displayName} secondary={user.userName} />
                                        {/* <ListItemSecondaryAction> <IconButton edge="end" aria-label="delete"> <DeleteIcon /> </IconButton> </ListItemSecondaryAction> */}
                                    </ListItem>
                                    )
                                }) : ''}

                            <Divider />
                        </List>

                        <div className={classes.selectedUsers}>
                            <List className={classes.selectedUsersList} subheader={<li />}>
                                
                                <li className={classes.listSection}>
                                    <ul className={classes.ul}>
                                        <ListSubheader>Selected Users</ListSubheader>
                                        {selectedUsers.map((user, index) => (
                                            <ListItem key={user?.displayName}>
                                                <ListItemText primary={user?.displayName} secondary={user?.id}/>
                                                <ListItemSecondaryAction> 
                                                { loggedInUserId === user?.userId ? "Admin" : <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveUser(index)} size="large"> <DeleteIcon /> </IconButton> }
                                                </ListItemSecondaryAction> 
                                            </ListItem>
                                        ))}
                                    </ul>
                                </li>
                            
                            </List>
                        </div>
                    
                    </CardContent>
                </Card>
            )
        }
        else if(activeStep === 1){
            return(
                <Card className={classes.forms} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Group Details
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            Group Name
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {groupName ? groupName : "No Group Name Set"}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" component="p">
                            Memebers
                        </Typography>
                        <Typography variant="h6" color="textSecondary" component="p" gutterBottom>
                            {
                            selectedUsers.length === 0 ? "No Paathis Added" 
                            : 
                            selectedUsers.map((user, index) => (
                                <div> 
                                <Typography key={index} variant="h6" color="textSecondary" component="p" gutterBottom>
                                    {user?.displayName} 
                                </Typography>
                                <Divider/>
                                </div>
                            ))
                            }
                        </Typography>
                    </CardContent>     
                </Card>
            )
        }
    }


    return (
        
            
             <Box sx={{ width: '100%', paddingTop: 5 }}>

                 <Stepper activeStep={activeStep}>
                     {steps.map((label, index) => {
                     const stepProps = {};
                     const labelProps = {};
                     return (
                         <Step key={label} {...stepProps}>
                         <StepLabel {...labelProps}>{label}</StepLabel>
                         </Step>
                     );
                     })}
                 </Stepper>


                <React.Fragment>
                {/* Main From Rendered*/}    
                {renderStepForm(activeStep)}
                
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button className={classes.cancelButton} onClick={handleCancelCreate} sx={{ mr: 1 }} >
                            Cancel
                        </Button>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }} >
                            Back
                        </Button>

                        <Box sx={{ flex: '1 1 auto' }} />
                
                        {activeStep === steps.length - 1 ? 
                        
                        <LoadingButton onClick={createGroup} loading={addingGroup} loadingIndicator="Creating..." variant="outlined" >
                         Create Group
                        </LoadingButton>
                        : 
                        <Button onClick={handleNext}>
                            Next
                        </Button>
                        }
                    </Box>
                </React.Fragment>

             </Box>   
           
           
    );
}

export default CreateGroupForm;