import React, { useEffect, useState, useContext } from 'react';
import Layout from '../components/Layout';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { BrowserRouter as Router, useHistory, useLocation } from "react-router-dom";
import firebase from "firebase/app";
import { firestore } from '../config/firebase-config';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import UserProvider, { UserContext } from '../components/UserProvider';
import CreatePaathForm from '../components/CreatePaathForm';


const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
    },
    header:{
      textAlign: 'center',
      marginBottom: 20,
    },
    pageHeader: {
        display: 'flex',
        marginTop: 54,
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        height: 100,
        backgroundColor: theme.palette.primary.light,
        textAlign: 'left',
        padding: theme.spacing(2),
    },
    formControl: {
        minWidth: '100%',
      },
    dateField: {
        width: '100%',
    },
    title: {
      marginTop: '80px',
    },
    paathSection: {
        marginTop: theme.spacing(3),
    },
    card: {
        marginTop: theme.spacing(2),
    },
    autoComplete: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    userNameSearch: {
        minWidth: '100%',
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    addGroupButton: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    selectedUserSearch: {
        maxWidth: '100%',
        marginTop: theme.spacing(2),
    },
    selectedUsers: {
        marginTop: theme.spacing(2),
    },
    selectedUsersList: {
        width: '100%',
        maxWidth: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
    listSection: {
        backgroundColor: 'inherit',
      },
      ul: {
        backgroundColor: 'inherit',
        padding: 0,
      },
    bottomActionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    }
}));



const steps = ['Start', 'Paathis', 'Finished'];

const CreatePaath = () => {

    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const {user, setUser} = useContext(UserContext);


    useEffect(() => {

        window.scrollTo(0,0);

    }, [])



    return (
       
            <div className={classes.root}>
                <div className={classes.pageHeader}>
                    <Typography className={classes.title} variant="h5" component="h1"> Create Paath </Typography>
                </div>
                <Layout>
                    <CreatePaathForm/>
                </Layout>
            </div> 
    );
}

export default CreatePaath;