import React, { useState, useEffect, useContext } from "react";
import * as Realm from "realm-web";

const RealmAppContext = React.createContext();

export const useRealmApp = () => {
  const app = useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export const RealmAppProvider = ({ appId, children }) => {
   // TODO: Wrap the Realm.App object with React state.
  const [app, setApp] = useState(new Realm.App(appId));

  useEffect(() => {
    //console.log('Setting realm app');
    setApp(new Realm.App(appId));

  }, [appId]);
  
  // Wrap the Realm.App object's user state with React state
  const [realmUser, setRealmUser] = useState(app.currentUser);

  // Will be doing an anonymous login because Realm is not handling user auth.
  async function logIn(credentials) {
     // TODO: Call the logIn() method with the given credentials
    // If successful, app.realmUser is the user that just logged in
    await app.logIn(credentials);
    setRealmUser(app.currentUser);
  }

  async function logOut() {
    // Log out the currently active user
    await app.currentUser?.logOut();
     // TODO: Call the logOut() method on the current user. 
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.realmUser is null.
     // TODO: Call the setRealmUser() method on the app's current user.
     setRealmUser(app.currentUser);
  }

  const wrapped = { ...app, realmUser, logIn, logOut };
  
  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};
