import React, { useState, useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import { UserContext } from '../components/UserProvider';
import makeStyles from '@mui/styles/makeStyles';
import StylesProvider from '@mui/styles/StylesProvider';
import { useQuery, useLazyQuery } from '@apollo/client';
import { BrowserRouter as Router, useHistory, useLocation, useParams } from "react-router-dom";
import { firestore } from '../config/firebase-config';
import gql from 'graphql-tag';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import LinearProgress from '@mui/material/LinearProgress';
import { TurnedInNotRounded } from '@mui/icons-material';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PaathVerseDisplay from '../components/PaathVerseDisplay';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  paathContainer: {
    marginTop: '70px',
    marginBottom: '130px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.backgroundCard,
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    bottom: 70,
    //right: 0,
    padding: 5,
    width: '95%',
    maxWidth: '95%',
    borderRadius: '10px',
  },
  card: {
    minWidth: '275',
    marginBottom: '5px',
    backgroundColor: theme.palette.primary.backgroundCard,
  },
  title: {
    fontSize: 14,
  },
  text: {
    color: theme.palette.primary.darkText,
  },
  verseNumber: {
    textAlign: 'right',
  },
  pos: {
    marginBottom: 12,
  },
  saveButton: {
    marginLeft: 5,
  },
  pageButtonContainer: {
    marginLeft: 0,
  },
  pageTurnButton: {
    backgroundColor: theme.palette.primary.light,
    marginLeft: 5,
  },
  pageNumber: {
    fontSize: '18px',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginTop: 3,
  },
}));

const GET_PAATH = gql `
  query($pageNo: Int) {
    PaathPageSearchQuery(input: $pageNo) {
      _id
      count
      source{
        pageNo
      }
      navigation{
        previous
        next
      }
      page{
        lineNo
        verseId
        verse{
          unicode
        }
        translation{
          en{
            bdb
          }
        }
        transliteration{
          english
        }
      }
    }
  }
`

/*const GET_PAATH = gql `
  query Paath {
    readitem {
      _id
      count
      source{
        pageNo
      }
      page{
        lineNo
        verseId
        verse{
          unicode
        }
        translation{
          en{
            bdb
          }
        }
        transliteration{
          english
        }
      }
    }
  }
`*/


const ReadPaathFreely = () => {

    const classes = useStyles();
    const [paathData, setPaathData] = useState([]);
    const [paathDetails, setPaathDetails] = useState({});
    const [serverPage, setServerPage] = useState(null);
    const [progress, setProgress] = useState(10);
    const [paathDetailsLoading, setPaathDetailsLoading] = useState(false);
    const { paathId, page, verse } = useParams();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentVerse, setCurrentVerse] = useState(1);
    const [bookmarkPage, setBookmarkPage] = useState('');
    const [bookmarkVerse, setBookmarkVerse] = useState('');
    const [bookmarkLine, setBookmarkLine] = useState('');
    const [savingProgress, setSavingProgress] = useState(false);
    const [progressSaved, setProgressSaved] = useState(false);
    const [pageSearchOpen, setPageSearchOpen] = useState(false);
    const [pageSearchNumber, setPageSearchNumber] = useState('');
    const bookmarkRef = useRef(null);

    
    const { loading, error, data } = useQuery(GET_PAATH, {variables:{pageNo: currentPage}}); //the page number will come from query params
  

    useEffect(() => {

      //window.scrollTo(0,0);
      //bookmarkRef.current.scrollIntoView();   


      if(data){
        setPaathData(data.PaathPageSearchQuery[0].page);
        setServerPage(data.PaathPageSearchQuery[0].source.pageNo);
      }

      

    }, [data, currentPage])

    const handlePageSearchChange = () => {
      const newPageNumber = pageSearchNumber;
      if(newPageNumber > 1430){
        alert("Please enter a page number between 1 - 1430.");
      }else{
        setCurrentPage(newPageNumber);
        setPageSearchNumber('');
        setPageSearchOpen(false);
      }
    };

    const handlePageSearchClose = () => {
      setPageSearchOpen(false);
    }


    const handleChange = (event, value) => {
      setCurrentPage(value);
    };

    const handleNextPage = () => {
      const currentPg = parseInt(currentPage);
      if(currentPg === 1430){
        alert("This is the last ang of the Guru Granth Sahib Ji.");
      }
      else{
        setCurrentPage(currentPg + 1);
        window.scrollTo(0,0);
      }
    }

    const handlePrevPage = () => {
      const currentPg = parseInt(currentPage);
      if(currentPg === 1){
        setCurrentPage(1);
      }
      else{
        setCurrentPage(currentPg - 1);
      }
    }

    const startRead = () => {
      firestore.collection("paaths").doc(paathId).update({
        'progress.readingStatus': 'readingStarted',
     });
    }

    const pauseRead = () => {
      firestore.collection("paaths").doc(paathId).update({
        'progress.readingStatus': 'toStartRead',
     });
    }

    const saveProgress = () => {

      if(bookmarkVerse === "" && bookmarkLine === ""){
        alert("Please Bookmark The Verse you completed reading.");
      }
      else{
        //alert(bookmarkPage + '' + bookmarkVerse + '' + bookmarkLine);
        setSavingProgress(true);
        firestore.collection("paaths").doc(paathId).update({
          'progress.readingStatus': 'toStartRead',
          'progress.currentPage': bookmarkPage,
          'progress.verseNumberMarker': bookmarkVerse,
          'progress.lineNumberMarker': bookmarkLine
       });
       setSavingProgress(false);
       setProgressSaved(true);
      }
       
    }

    const bookmarkProgress = (page, verse, line) => {
      //alert(page + " " + verse + " " + line);
      setBookmarkPage(page);
      setBookmarkVerse(verse);
      setBookmarkLine(line);
    }

    const handleSnackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setProgressSaved(false);
    };


    const renderStartSaveButton = (readingStatus) => {

      if(readingStatus === "toStartRead"){
        return (
          <span>
             <Button onClick={startRead} color="primary" variant="contained">Start</Button>
             <Button onClick={saveProgress} className={classes.saveButton} color="secondary" variant="outlined" disabled>Save</Button>
          </span>
       
        );
      }
      else if(readingStatus === "readingStarted"){
        return (
          <span>
            <Button onClick={pauseRead} color="primary" variant="contained">Pause</Button>
            <Button onClick={saveProgress} className={classes.saveButton} color="secondary" variant="outlined">{savingProgress ? <CircularProgress size={24} color="secondary"/> : "Save"}</Button>
          </span>
        );
      }

    }

  

    return (
      <Layout>

      <div className={classes.root}>

        <div className={classes.paathContainer}> 

            <Dialog open={pageSearchOpen} onClose={handlePageSearchClose}>
              <DialogTitle>Page Search</DialogTitle>
              <DialogContent>
                <TextField onChange={(event) => setPageSearchNumber(event.target.value)} autoFocus margin="normal" id="page" label="Page No." type="number" fullWidth variant="standard" />
              </DialogContent>
              <DialogActions>
                <Button onClick={handlePageSearchClose}>Cancel</Button>
                <Button onClick={handlePageSearchChange}>Search</Button>
              </DialogActions>
            </Dialog>                
        
          {loading ? 
            <div> 
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} />
              <Skeleton variant="rectangular" animation="wave" style={{marginTop: '5px', marginBottom: '5px'}} width={'100%'} height={140} /> 
            </div> : "" 
            }

            <div>
              {!loading && paathData ? paathData?.map((paath, index) => {

                return(

                  <PaathVerseDisplay 
                    index={index} 
                    serverPage={serverPage} 
                    verseId={paath.verseId} 
                    lineNo={paath.lineNo} 
                    unicode={paath.verse.unicode} 
                    transliteration={paath.transliteration.english} 
                    translation={paath.translation.en.bdb} 
                    bookmarkProgress={bookmarkProgress}
                    previousBookmarkPage={page}
                    previousBookmarkVerse={verse}
                    readingStatus={paathDetails?.progress?.readingStatus}
                    bookmarkRef={bookmarkRef}
                  />

                )

              }) : ""  }


            </div>          

        </div>

        <Paper className={classes.paginationContainer} elevation={3}>

          <div>
              <Chip label="Reading"/>
          </div>


          <Fab size="small" onClick={() => setPageSearchOpen(true)}>{currentPage}</Fab>

          <div className={classes.pageButtonContainer}>
           
              <div> 
                <Button onClick={handlePrevPage} className={classes.pageTurnButton} size="small" variant="contained"><ArrowBackIosNewIcon fontSize="small"/></Button>
                <Button onClick={handleNextPage} className={classes.pageTurnButton} size="small" variant="contained"><ArrowForwardIosIcon fontSize="small"/></Button>
              </div>
            
          </div>

          </Paper>

          <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={progressSaved}
              autoHideDuration={6000}
              onClose={handleSnackBarClose}
              message={"Progress Saved!"}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />

      </div>
    </Layout>
    );
}

export default ReadPaathFreely;